<template>
<NavEmployer :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-12 d-lg-block ">
                        <ProgressSidebar :progress="user_info.companies[0]" :job="job.id" />
                    </div>

                    <div class="col-lg-10 col-md-10 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h3 class="text-center text-primary">All Passed Candidates on <span class="text-dark">{{ job.name }}</span></h3>
                                <div v-if="job.applicants.length">
                                    <h5 class="text-md-start text-center">Passed Applicants</h5>
                                    <div class="col mt-4 pt-2" id="tables">
                                    <div class="component-wrapper rounded shadow">
                                        <div class="p-4">
                                            <div class="row">
                                                    <div class="alert alert-success" v-if="success">
                                                        <p class="text-center">{{ success }}</p>
                                                    </div>
                                                    <div class="alert alert-danger" v-if="errors">
                                                        <p class="text-center">{{ errors }}</p>
                                                    </div>
                                                <div class="col-6 mt-4 pt-2" v-for="applicant in job.applicants" :key="applicant.id">
                                                    <CandidateProgress :applicant="applicant" :job="job" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="text-center" v-else>
                                    <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                    <h3 class="text-center">No Passed Applicants</h3>
                                    <p class="text-center">No applicant has Passed Yet</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
     <div v-else>
       <Loading />
   </div>
  <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import moment from 'moment'
import ProgressSidebar from '@/components/company/ProgressSidebar.vue'
import CandidateProgress from '@/components/company/CandidateProgress.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { NavEmployer,Footer,ProgressSidebar,Loading,CandidateProgress },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {},
          success: false,
          errors: false,
          isLoading: true,
          applicant: {},
          job: {
              applicants: []
          },
          user_info: {companies:[0]},
          token: localStorage.getItem('user_data'),
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
                let id = this.$route.params.id
                axios.get('/jobPassed/' + id).then(response => {
                    this.job = response.data
                })
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 2500);
        })
      },
      callFirstInterview(applicant){
          let job = this.$route.params.id
          axios.post('/application/callFirstInterview/' + job, applicant).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
       rejectApplicant(applicant){
          let job = this.$route.params.id
          axios.post('/application/reject/' + job, applicant).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      
  },
  created(){
      document.title = `Passed Candidates - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
  },
}
</script>

<style>

</style>