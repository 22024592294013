<template>

<div>
    <h4 class="text-center">Application Progress</h4>
    <div class="col mt-4 pt-2" id="tables">
        <div class="component-wrapper rounded shadow">
            <div class="p-4">
                <div class="table-responsive">
                    <div v-if="jobs.length">
                        <table class="table mb-0 table-center">
                            <thead>
                                <tr>
                                <th scope="col" class="border-bottom">Job Name</th>
                                <th scope="col" class="border-bottom">View Progress</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="job in jobs" :key="job.id">
                            
                                    <td>
                                        <router-link :to="{ name: 'JobDetails', params: {id: job.id}}" class="text-dark">
                                        {{ job.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                       <router-link :to="{ name: 'CandidateProgress', params: {id: job.id}}" class="text-primary">
                                        View Application Progress
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                    <div v-else >
                        <div >
                            <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                            <h5 class="text-center">You Have not Applied For Any Job Yet</h5>
                            <div class="text-center">
                                <router-link :to="{ name : 'Jobs' }"  type="button" class="btn btn-primary" >Hunt for Jobs</router-link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
</div>
  
</template>

<script>
export default {
    props:['jobs'],

}
</script>

<style>

</style>