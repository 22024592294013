<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="alert alert-success" v-if="success">
                    <p class="text-center">{{ success }}</p>
                </div>
                <div class="alert alert-danger" v-if="errors">
                    <p class="text-center">{{ errors }}</p>
                </div>
                <div class="table-responsive bg-white shadow" >
                    <table class="table table-center table-padding mb-0">
                        <thead>
                            <tr>
                                
                                <th class="border-bottom py-3" style="min-width: 200px;">Package</th>
                                <th class="border-bottom text-center py-3" style="min-width: 160px;">Price</th>
                                <th class="border-bottom py-3" style="min-width:20px "></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="shop-list" v-for="item in carts.cart_items" :key="item.id">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <img src="@/assets/img/svg/deliveries.svg" class="img-fluid avatar avatar-small rounded shadow" style="height:auto;" alt="">
                                        <h6 class="mb-0 ms-3 text-primary" >{{ item.package.package_category.name }}({{ item.package.name }}) </h6>
                                    </div>
                                </td>
                                <td class="text-center">Tsh: {{ Number(total = item.package.price).toLocaleString() }}</td>   
                                <td class="h6">
                                    <a @click="removePack(item.id)" class="btn text-danger">
                                        <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                    </a>
                                </td>                                     
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 ms-auto mt-4 pt-2">
                <div class="table-responsive bg-white rounded shadow">
                    <table class="table table-center table-padding mb-0">
                        <tbody>
                            <tr>
                                <td class="h6">Total</td>
                                <td class="text-center fw-bold">Tsh: {{ Number(total).toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr>
             <br>
             <h4 class="text-center text-primary">Complete Payment By Payment Methods Below</h4>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button @click="payMpesa" class="btn me-md-2"  type="button">
                    <img src="@/assets/img/payment/mpesa.png" alt="">
                </button>
                <button @click="payAirtel" class="btn " type="button">
                    <img src="@/assets/img/payment/airtel.png" alt="">
                </button>
                <button @click="payTigopes" class="btn" type="button">
                    <img src="@/assets/img/payment/tigopesa.png" alt="">
                </button>
                <button @click="payZantel" class="btn " type="button">
                    <img src="@/assets/img/payment/zantel.png" alt="">
                </button>
            </div>
        </div>
        
    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    props:['carts'],
    data(){
      return {
          success: false,
          errors: false,
         }
    },
    methods: {
        removePack(id){
             Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/company/cart/remove/'+ id)
                .then(response => {
                    this.carts.cart_items = this.carts.cart_items.filter(item => {
                      return item.id != id
                      })
                }).catch(() => {
                    this.$router.push({ name: 'CompanyCart'})
                })
                Swal.fire(
                'Deleted!',
                'Hobby has been Deleted.',
                'success'
                )
            }
            })
        }
    },

}
</script>

<style>

</style>