<template>
<NavEmployer :user="user" />
    <!-- <Profile :profile="user_info.companies[0]" :user="user"/> -->
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">

                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-center">Change Password :</h5>
                                <div class="alert alert-success" v-if="success">
                                    <p class="text-center">{{ success }}</p>
                                </div>
                                <div v-if="errors.old_password">
                                </div>
                                <div  v-else-if="errors.new_password">
                                </div>
                                <div  v-else-if="errors.confirm_password">
                                </div>
                                <div class="alert alert-danger" v-else-if="errors">
                                    <p class="text-center">{{ errors }}</p>
                                </div>
                                
                                    <form @submit.prevent="changePassword">
                                        <div class="row justify-content-center mt-4">
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">Old password :</label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input type="password" class="form-control ps-5" v-model="form.old_password">
                                                        <div v-if="errors.old_password" class="text-small text-danger">{{ errors.old_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">New password :</label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input type="password" class="form-control ps-5" v-model="form.new_password">
                                                        <div v-if="errors.new_password" class="text-small text-danger">{{ errors.new_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">Re-type New password :</label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input type="password" class="form-control ps-5" v-model="form.confirm_password">
                                                        <div v-if="errors.confirm_password" class="text-small text-danger">{{ errors.confirm_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="col-lg-8 mt-2 mb-0">
                                                <button type="submit" id="submit" class="btn btn-primary">Save password</button>
                                            </div>
                                        </div>
                                    </form>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
  <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Profile from '@/components/company/Profile.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import CompanyUsers from '@/components/company/CompanyUsers.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { Profile,NavEmployer,Footer,Sidebar,Loading,CompanyUsers },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {roles:[0]},
          success: false,
          errors: false,
          form: {
               old_password:'',new_password:'',confirm_password:'',
            },
          company_id: null,
          isLoading: true,
          user_info: { companies:[0], },
          token: localStorage.getItem('user_data'),
      }
  },
  methods: {
    getUser(){
    axios.get('/user').then(response => {
        this.currentUser = response.data
        this.user = this.currentUser.user
        if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        axios.get('/company/' + this.user.id).then(response => {
            this.user_info = response.data
        })
    }).catch(errors => {
        if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
    }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
    })
    }, 
    changePassword(){
        axios.post('/change/password', this.form).then(response => {
            this.$router.push({ name: 'CompanyMyProfile'})
             this.success = response.data.success
        }).catch((error) => {
            this.errors = error.response.data.errors
        })
    },
  },
  mounted(){
      document.title = `Change Password - Vipawaworks`
     axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
  }
}
</script>

<style>

</style>