import { computed, ref } from 'vue'

const jobCity= (id) => {
    const jobs = ref([])
    const city = ref([])
    const search = ref('')
    const error = ref(null)
    const errorCity = ref(null)

    const loadCity= async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/cities/jobs/' + id)
            if (!data.ok){
                throw Error('No Job Found')
            }
            jobs.value = await data.json()
        }
        catch (err){
            error.value = err.message
        }
        try {
            let dataCity = await fetch('https://api.vipawaworks.com/api/cities/show/' + id)
            if (!dataCity.ok){
                throw Error('No Job Found')
            }
            city.value = await dataCity.json()
            document.title = city.value.name +'- Vipawaworks'
        }
        catch (err){
            errorCity.value = err.message
        }
    }
    const matchingJobs = computed(() => {
        return jobs.value.filter(function(jobName){
            return jobName.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1 
        })
    })
    return { jobs, city, matchingJobs, search, error, errorCity, loadCity}
  }

  export default jobCity