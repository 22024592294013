<template>
    <Navbar />
    <div v-if="!isLoading">
        <section class="section">
            <div class="container">
                <div v-if="blog != null">
                    <div class="row">
                        <div class="col-lg-8 col-md-6">
                            <div class="card blog blog-detail border-0 shadow rounded">
                                <img :src="'https://adminora.vipawaworks.com/images/blogs/' + blog.image" class="img-fluid rounded-top" alt="">
                                <div class="card-body content">
                                    <h3><a href="javscript:void(0)" class="text-primary">{{ blog.name }}</a></h3>
                                    <span v-html="blog.content" align="justify"></span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="card border-0 sidebar sticky-bar rounded shadow">
                                <div class="card-body">
                                    <div class="widget mb-4 pb-2">
                                        <h5 class="widget-title">Recent Post</h5>
                                    <SideBlogs />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container alert alert-danger justify-content-center" v-else>
                    <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                    <h3 class="text-center">No Blog</h3>
                    <p class="text-center">We are Sorry this Blog is not Available at the moment, you can find more <router-link :to="{ name:'Job Forum'}" class="text-primary">here</router-link></p>
                </div>
                
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
import SideBlogs from '@/components/SideBlogs.vue'
export default {
  components: { Navbar,Footer,SideBlogs,Loading },
    props: ['id'],
     data(){
        return {
            previousPage: 'blog',
            errors: '',
            isLoading: true,
            blog: {}

      }
     },
       methods: {
      getSingleBlog(){
          let id = this.$route.params.id
        axios.get('blogs/details/' + id).then(response => {
            this.blog = response.data
        }).catch(errors => {
             this.errors = 'Something Went Wrong'
        }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                    document.title = this.blog.name +'- Vipawaworks'
                }.bind(this), 500);
            })
      },
    },
    updated(){
    this.getSingleBlog()
    },
     created(){
      this.getSingleBlog()
  },
  
}
</script>

<style>

</style>