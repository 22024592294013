<template>
    <div class="align-items-center">
        <div class="alert alert-success" v-if="success">
            <p class="text-center">{{ success }}</p>
        </div>
        <div class="alert alert-danger" v-if="errors">
            <p class="text-center">{{ errors }}</p>
        </div>
        <div class="row" v-if="packagesCategory.packages.length">
            <div class="col-md-4 col-12 mt-4 pt-2" v-for="pack in packagesCategory.packages" :key="pack.id">
                <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                    <div class="card-body py-5">
                        <h6 class="title fw-bold text-uppercase text-primary mb-4">{{ pack.name }}</h6>
                        <div class="d-flex justify-content-center mb-4">
                            <span class="h4 mb-0 mt-2">Tsh</span>
                            <span class="price h2 mb-0">{{ Number(pack.price).toLocaleString() }}</span>
                            <span class="h4 align-self-end mb-1">/mo</span>
                        </div>
                        <span v-html="pack.description" class="list-unstyled mb-0 ps-0"></span>
                        <a @click="subscribePackage({pack:pack.id})" class="btn btn-primary mt-4" v-if="pack.price != 0" >Subscribe</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center alert alert-danger">
            <h4>No Packages Found</h4>
        </div>
            
    </div>
</template>

<script>
export default {
    props:['packagesCategory','profile'],
    data(){
      return {
          currentUser: {},
          user: {},
          success: false,
          errors: false,
          user_info: { company: {}, roles: {}, companies:[0]},
          token: localStorage.getItem('user_data'),
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }
        }).catch(errors => {
        })
      },
      subscribePackage(pack){
           axios.post('/company/addToCart/' + this.profile.id, pack).then(response => {
              this.$router.push({ name: 'CompanyCart'})
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      }
      
  },
}

</script>

<style>

</style>