<template>
<Navbar />
<section class="bg-auth-home d-table w-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5">
                <div class="me-lg-5">   
                    <h2>Hire Top Talent in The East Africa</h2>
                    <h5>Join 1000+ companies hiring on Vipawaworks</h5>
                    <p>Access the largest CV database in the East Africa, post your jobs, and find employees in Tanzania, Kenya, Uganda, Rwanda, Burundi. </p>

                    <h4>Why Choose Vipawaworks?</h4>
                    <ul class="job-facts ">
                        <li class="list-inline-item text-muted" >
                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            More than 10 thousands CVs
                        </li><br>
                        <li class="list-inline-item text-muted" >
                            <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            AI-powered recruitment technology, saving you time and effort!
                        </li><br>
                        <li class="list-inline-item text-muted">
                            <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            7 Million visits per month, giving your jobs maximum visibility.
                        </li>
                        <li class="list-inline-item text-muted">
                            <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            Dedicated customer support from our 12 regional offices.
                        </li>
                        <li class="list-inline-item text-muted">
                            <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            We speak Swahili, English, Chinese.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-7">
                <div class="card shadow rounded border-0">
                    <div class="card-body">
                        <p class="text-md-start text-center">Step 1 of 2</p>
                        <h3 class="card-title text-center">Create a Free Employer Account</h3> 

                        <EmployerRegisterForm />
                        </div>
                </div>
            </div> 
        </div>
    </div>
</section>
<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import EmployerRegisterForm from '@/components/auth/EmployerRegisterForm.vue'
export default {
  components: { Navbar, Footer,EmployerRegisterForm },
created(){
    document.title = `Register Employer - Vipawaworks`
  },
}
</script>

<style>

</style>