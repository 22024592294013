import { computed, ref } from'vue'

const getFrontBlogs = () => {
    const blogs = ref([])
    const error = ref(null)

    const loadFrontBlogs = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/blogs/front')
            if (!data.ok){
                throw Error('no data available')
            }
            blogs.value = await data.json()
        }
        catch (err){
            error.value = err.message
        }
    }
    return { blogs, error, loadFrontBlogs }
  }

  export default getFrontBlogs
