<template>
<Navbar />
    <section class="section pb-0">
        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                    <div class="card shadow rounded border-0">
                        <div class="card-body py-5">
                            <h4 class="card-title">Set Job Alert</h4>
                            <div class="custom-form mt-3">
                                <form @submit.prevent="jobAlert" ref="clearForm" name="myForm">
                                        <p id="error-msg" class="mb-0"></p>
                                        <div id="simple-msg"></div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mb-3">
                                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                                    <div class="form-icon position-relative">
                                                        <input v-model="form.email" id="email" type="email" class="form-control " placeholder="Email :">
                                                        <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Job Category</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control "  v-model="form.category_id ">
                                                            <option value="0">All Categories</option>
                                                            <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Company Industry</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control " v-model="form.industry_id">
                                                            <option value="0">All Industries</option>
                                                            <option :value="industry.id" v-for="industry in industries" :key="industry.id">{{ industry.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Countries</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control "  v-model="form.country_id">
                                                            <option value="0">Any Country</option>
                                                            <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Employment Type</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control "  v-model="form.employment_type">
                                                            <option value="0">Any Type</option>
                                                            <option value="1">Consultant</option>
                                                            <option value="2">Contractor</option>
                                                            <option value="3">Employee</option>
                                                            <option value="4">Temporary Employee</option>
                                                            <option value="5">Volunteer</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="d-grid">
                                                    <button type="submit" id="submit" name="send" class="btn btn-primary">Set Alert</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                            <br>
                            <div class="text-end">
                                <router-link class="text-dark" :to="{ name: 'JobAlertUnsbuscribe'}">Unsubscribe Email</router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 order-1 order-md-2">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <img src="@/assets/img/png/alert.png"  height="500" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { Navbar,Footer,Loading,},
 data(){
      return {
        currentPage: 'Job Alert',
        previousPage: '',
        errors: [],
        success: false,
        form: {
            email: '',
            country_id: 0, 
            category_id: 0, 
            industry_id: 0,
            sex: 0,
            employment_status: 2,
            employment_type: 0
        },
        countries: {},
        categories: {},
        industries: {},
    }
  },
    methods: {
        jobAlert(){
            axios.post('/jobAlert', this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'Home'})
                this.success = 'Thank You, We Received Your Feedback'
                this.errors = false
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
        getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      }, 
      getIndustries(){
        axios.get('/industries').then(response => {
            this.industries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = errors.response.data.errors
        })
      },
    },
    created(){
        document.title = `Set Job Alert - Vipawaworks`
        this.getCountries()
        this.getCategories()
        this.getIndustries()
    }
}
</script>

<style>

</style>