<template>  
<NavSeeker :user="user" />
  <div v-if="!isLoading">
    <section class="section mt-60">
        <div class="container mt-lg-3">
            <div class="row justify-content-center">
                
                <div v-if="user_info" class="col-lg-12 col-md-12  col-12">
                    <div v-if="user_info.profile[0]">
                        <div class="border-bottom text-center pb-4">
                            <h4>{{ user_info.fname }} {{ user_info.sname }} {{ user_info.lname }}</h4>
                            <p class="text-muted mb-0" v-if="user_info.roles[0]">{{ user_info.roles[0].name }}</p>
                        </div>
                        
                        <div class="border-bottom pb-4">
                            <PersonalDetails  :user_info="user_info" :jobs="jobs" :subscriber="subscriber" />
                        </div>
                    </div>
                    <div v-else class="row justify-content-center">
                        <div class="col-lg-8 col-12">
                            <br>
                            <Welcome />
                        </div>
                    </div>
                    
                </div>
                <div v-else class="col-lg-8 col-12">
                    <br>
                   <Welcome />
                </div>
            </div>
        </div>
    </section>
  </div>
   <div v-else>
       <Loading />
   </div>
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import SideMenu from '@/components/SideMenu.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import PersonalDetails from '@/components/user/PersonalDetails.vue'
import Loading from '@/views/custom/Loading.vue'
import Welcome from '@/components/user/Welcome.vue'
export default {
  components: { SideMenu, NavSeeker, Footer, PersonalDetails,Loading, Welcome },
  data(){
      return {
          drawer: true,
          currentUser: {},
          user: {roles: [0]},
          jobs: {},
          user_info: { profile:[0] },
          token: localStorage.getItem('user_data'),
          subscriber: {},
          isLoading: true,
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/profile/' + this.user.id).then(response => {
                this.user_info = response.data
            })
            axios.get('/candidate/application').then(response => {
                this.jobs = response.data
                }).catch(errors => {
                    this.errors = 'Something went wrong'
            })
        }).catch(errors => {
            if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
  },
  created(){
      document.title = `Dashboard - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>
.item + .item:before {
  content: ", ";
}

</style>