<template>  
<FreelancerNav :user="user"/>
  <div v-if="!isLoading">
    <section class="section mt-60">
        <div class="container mt-lg-3">
            <div class="row justify-content-center">
                <div v-if="user_info" class="col-lg-8 col-12">
                    <div v-if="user_info.profile[0]">
                        <div class="border-bottom pb-4 text-center">
                            <h5>{{ user_info.fname }} {{ user_info.sname }} {{ user_info.lname }}</h5>
                            <p class="text-muted mb-0" v-if="user_info.roles[0]">{{ user_info.roles[0].name }}</p>
                        </div>
                        
                        <div class="border-bottom pb-4">
                            <div class="row">
                                <PersonalDetails  :user_info="user_info" :offers="offers" />
                            </div>
                        </div>
                    </div>
                   <div v-else class="row justify-content-center">
                        <div class="col-lg-8 col-12">
                            <br>
                            <Welcome />
                        </div>
                    </div>
                    
                </div>
                <div v-else class="col-lg-8 col-12">
                    <br>
                   <Welcome />
                </div>
            </div>
        </div>
    </section>
  </div>
   <div v-else>
       <Loading />
   </div>
   <Footer />
</template>

<script>
import Profile from '@/components/freelancer/Profile.vue'
import Footer from '@/components/Footer.vue'
import PersonalDetails from '@/components/freelancer/PersonalDetails.vue'
import Loading from '@/views/custom/Loading.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'
import Welcome from '@/components/freelancer/Welcome.vue'
export default {
  components: {  Profile, Footer, PersonalDetails,Loading,FreelancerNav,Welcome },
  data(){
      return {
          drawer: true,
          currentUser: {},
          offers:{},
          user: {roles: []},
          user_info: { profile:[0] },
          token: localStorage.getItem('user_data'),
          isLoading: true,
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
            axios.get('/profile/' + this.user.id).then(response => {
                this.user_info = response.data
            })
            axios.get('/freelancer/requests').then(response => {
                this.offers = response.data
                }).catch(errors => {
                    this.errors = 'Something went wrong'
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
  },
  created(){
      document.title = `Freelancer Dashboard - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>
.item + .item:before {
  content: ", ";
}

</style>