import { ref } from 'vue'

const getSingleCompany = (id) => {
    const company = ref(null)
    const jobs = ref(null)
    const errorCompany = ref(null)
    const errorJobs = ref(null)

    const loadCompany = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/company/details/' + id)
            if (!data.ok){
                throw Error('The Company does not exist')
            }
            company.value = await data.json()
            document.title = company.value.name +'- Vipawaworks'
        }
        catch (err){
            error.value = err.message
        }
        try {
            let dataCompany = await fetch('https://api.vipawaworks.com/api/jobs/company/' + company.value.id)
            if (!dataCompany.ok){
                throw Error('The Company does not exist')
            }
            jobs.value = await dataCompany.json()
        }
        catch (err){
            errorJobs.value = err.message
        }
    }
    return { company, jobs, errorCompany, errorJobs, loadCompany }
  }

  export default getSingleCompany