<template>
<Navbar />
    <section class="section">
        <br>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h2 class="text-center text-primary">How To Get Started as Employer</h2>
                    <div class="card shadow border-0 rounded">
                        <div class="card-body ">
                            <h5 class="card-title text-primary">Introduction</h5>
                            <p class="text-muted">
                                When you just access <router-link :to="{ name:'Home'}" class="text-primary">Vipawa Recruitment</router-link>,  the home page looks as in picture below. You have two options one on top of website far to the right, click on get started.
                                Or click in a front page Job Seeker card on register 

                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker1.png" class="rounded work-image" height="300" alt="">
                            </div>
                                <br>
                            <h5 class="card-title text-primary">Get Started Page</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Get Started</strong> button on top right conner. You will be naviagated to <router-link :to="{ name:'GetStarted'}" class="text-primary">Get Started</router-link>, 
                                then you can choose <strong>Job Seeker</strong> option out of all other. Note each has different fate in this website.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker2.png" class="rounded work-image" height="400" alt="">
                            </div>
            
                            <h5 class="card-title text-primary">Job Seeker Register Page</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Get Started</strong> in get Started page or job Seeker Register in Home page. You will be naviagated to <router-link :to="{ name:'Register'}" class="text-primary">Job Seeker Register</router-link> page,
                                Here you fill All the infomation about you such as three names, email and password without forgeting to accept terms and condition
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker3.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                               Infomation should be filled as the shown in above picture. All fields in this page are mandatory, if any field is not filled/checked, we will reject it instantly as shown in picture below. You can also hide/unhide password so that to be sure. Remember to keep them safe
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker4.png" class="rounded work-image" height="400" alt="">
                            </div>
                                <br>
                            
                            <h5 class="card-title text-primary">Add Recently Experience</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Registed</strong> personal details. You will be redirected to <strong>Experience</strong> page,
                                Here you fill All the Infomation about your recently Company. We insist that all infomation must be true and valid, If you have no experience click on I have no experience.
                                You will be change to Freshers so that it maybe easier for you.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker5.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                               All feild are mandatory to get started, We will reject if mandatory fields are not filled.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker6.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <br>
                            <h5 class="card-title text-primary">Add What kind Of job you are looking For</h5>
                            <p class="text-muted">
                                Right after completing <strong>Recently experience </strong>. You will be redirected to <strong>Job Target</strong> page,
                                Here you fill your target Job details ao that employers can know what are you are focusing for.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker7.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                               All feild are mandatory to get started, We will reject if mandatory fields are not filled.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker8.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <br>
                            <h5 class="card-title text-primary">Add Your Highiest Education</h5>
                            <p class="text-muted">
                                Right after telling us about your <strong>Target Job</strong>. You will be redirected to <strong>Education</strong> page,
                                Here you fill All the Infomation about your recently/highest education level, so that employers will look for you.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker9.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                               All feild are mandatory to get started, We will reject if mandatory fields are not filled.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker10.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <br>
                            <h5 class="card-title text-primary">Add Your Personal Details</h5>
                            <p class="text-muted">
                                Right after telling us about your <strong>Education Job</strong>. You will be redirected to <strong>Personal Details</strong> page,
                                Here we need to know about you, just some few and simple infomation.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker11.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                               All feild are mandatory to get started, We will reject if mandatory fields are not filled.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker12.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <br>
                            <h5 class="card-title text-primary">Congraturations Page</h5>
                            <p class="text-muted">
                                Right after telling us about your <strong>Personal Details</strong>, Now You have completed neccessary infomation for your Resume(CV).
                                In Vipawa Recruitment Resume is very important for job seeker
                                Now you have two options, one to <strong>Search for Jobs</strong> another is to <strong>Complete my CV.</strong>.
                                 We recommend to fill up your resume first since other applicants will have better chances than you because they have filled most infomation on their Cvs.
                                 But if you choose search for job we have another <router-link to="" class="text-primary">Guide For Job Applications</router-link>
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker13.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <h5 class="card-title text-primary">Resume/CV Page</h5>
                            <p class="text-muted">
                                Once you choose <strong>Complete my CV.</strong>, Now You have completed neccessary infomation for your Resume(CV).
                                Your Resume will look like how it is seen in picture below. Fill all neccessary infomation until your completeion percentage is atleast 80%.
                                this will give you advantage when employer searches for candidate, and against competition from other candidates.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/seeker14.png" class="rounded work-image" height="400" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>			

        </div>
    </section>
<Footer />
</template>

<script>
import Navbar from '@/components/help/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { Navbar,Footer },

}
</script>

<style>

</style>