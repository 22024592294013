<template>
    <div>
        <h3 class="text-center text-primary" >Shopping Cart <span v-if="carts.cart_items">({{ carts.cart_items.length }})</span></h3>
        <section class="section">
            <div class="container">
                <div v-if="carts.cart_items.length">
                    <CartItem :carts="carts" /> 
                </div>
                <div v-else class="text-center alert alert-danger">
                    <h4>No Package In Cart</h4>
                </div>
            </div>
        </section>

    </div>
</template>

<script>

import CartItem from './CartItem.vue'
export default {
  components: { CartItem },
    props:['profile'],
    data(){
      return {
          carts:{
              cart_items: {}
          },
          subTotal:0,
          total: 0,
          success: false,
          errors: false,
          item:{quantity:0}
      }
    },
    methods: {
        companyCart(){
            axios.get('/company/cart/index/' + this.profile.id).then(response => {
                this.carts = response.data
            }).catch(errors => {
            })
        },
        removePack(id){
             Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/company/cart/remove/'+ id)
                .then(response => {
                    this.carts.cart_items = this.carts.cart_items.filter(item => {
                      return item.id != id
                      })
                }).catch(() => {
                    this.$router.push({ name: 'CompanyCart'})
                })
                Swal.fire(
                'Deleted!',
                'Hobby has been Deleted.',
                'success'
                )
            }
            })
        }
    },
    created(){
        if (this.profile) {
            this.companyCart()
        }
    }

}
</script>

<style>
input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>