<template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h4 class="text-center">Change Password :</h4>
                                <div class="alert alert-success" v-if="success">
                                    <p class="text-center">{{ success }}</p>
                                </div>
                                <div v-if="errors.old_password">
                                </div>
                                <div  v-else-if="errors.new_password">
                                </div>
                                <div  v-else-if="errors.confirm_password">
                                </div>
                                <div class="alert alert-danger" v-else-if="errors">
                                    <p class="text-center">{{ errors }}</p>
                                </div>
                                
                                    <form @submit.prevent="changePassword">
                                        <div class="row justify-content-center mt-4">
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">Old password :</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="password" class="form-control ps-5" v-model="form.old_password">
                                                        <div v-if="errors.old_password" class="text-small text-danger">{{ errors.old_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">New password :</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="password" class="form-control ps-5" v-model="form.new_password">
                                                        <div v-if="errors.new_password" class="text-small text-danger">{{ errors.new_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">Re-type New password :</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="password" class="form-control ps-5" v-model="form.confirm_password">
                                                        <div v-if="errors.confirm_password" class="text-small text-danger">{{ errors.confirm_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                           <div class="text-center">
                                                <button class="btn btn-primary" disabled v-if="submitting">
                                                    <span class="label">{{ value }}</span>
                                                </button>
                                                <input type="submit" id="submit" class="btn btn-primary" value="Save Changes" v-else>
                                            </div>
                                        </div>
                                    </form>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
   	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { Footer,NavSeeker,Loading },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {roles:[0]},
          success: false,
          errors: false,
          errorsOldPassword: false,
          form: {
               old_password:'',new_password:'',confirm_password:'',
            },
          company_id: null,
          isLoading: true,
          user_info: { companies:[0], },
          token: localStorage.getItem('user_data'),
          submitting: false,  
      }
  },
  methods: {
    getUser(){
    axios.get('/user').then(response => {
        this.currentUser = response.data
        this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
            if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
    }).catch(errors => {
         if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
    }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 2500);
    })
    }, 
    changePassword(){
        this.submitting = true
        this.value = 'Please Wait...'
        axios.post('/change/password', this.form).then(response => {
            this.$router.push({ name: 'Profile'})
             this.success = response.data.success
        }).catch((error) => {
            this.errors = error.response.data.errors
            this.submitting = false
        })
    },
  },
  mounted(){
      document.title = `Change Password - Vipawaworks`
     axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
  }
}
</script>

<style>

</style>