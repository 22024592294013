<template>
    <div class="alert alert-success" v-if="success">
        <p class="text-center">{{ success }}</p>
    </div>
    <div class="alert alert-danger" v-if="errors[0]">
        <p class="text-center">{{ errors}}</p>
    </div>
    <form class="mt-4" @submit.prevent="requestService">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Amount Offer<span class="text-danger">*</span></label>
                    <input type="number" v-model="form.amount" class="form-control">
                    <div v-if="errors.amount" class="text-small text-danger">{{ errors.amount[0] }}</div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="mb-3">
                    <label class="form-label">Currency <span class="text-danger">*</span></label>
                    <select class="form-select form-control" v-model="form.currency" aria-label="Default select example">
                        <option value="1">Tsh</option>
                        <option value="2">USD</option>
                    </select>
                    <div v-if="errors.currency" class="text-small text-danger">{{ errors.currency[0] }}</div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="mb-3">
                    <label class="form-label">Duration <span class="text-danger">*</span></label>
                    <select class="form-select form-control" v-model="form.duration" aria-label="Default select example">
                        <option value="1">Per Day</option>
                        <option value="2">Weekly</option>
                        <option value="3">Monthly</option>
                        <option value="4">Until Service is Over</option>
                    </select>
                    <div v-if="errors.duration" class="text-small text-danger">{{ errors.duration[0] }}</div>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Short Details</label>
                <textarea v-model="form.details" rows="4" class="form-control" placeholder="Tell me in short What service you need from me"></textarea>
                <div v-if="errors.details" class="text-small text-danger">{{ errors.details[0] }}</div>
            </div> 
            <div class="mt-4 pt-2">
                <div class="d-grid">
                    <button type="submit" class="btn btn-primary">Request Service</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props:['company','id'],
     data(props){
        return {
            form: {
                id: props.id,
                amount: '',
                currency: 1,
                duration: 1,
                details: '',
            },
            success: false,
            errors: false,
      }
    },
    methods: {
        requestService(){
            axios.post('/freelancing/request/service/' + this.company.id, this.form).then(response => {
                this.success = response.data.success
            }).catch(errors => {
            this.errors = errors.response.data.errors
        })
        },
  },

}
</script>

<style>

</style>