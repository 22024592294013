<template>
    <Navbar />
	<div class="home" v-if="!isLoading">
		<div class="position-center">
			<section class="section section-pb-0">
				<div class="banner trans" data-overlay="6">
					<div class="bg-overlay"> </div>
					<div class="container">
						<div class="banner-caption">
							<div class="container-fluid">
								<div class="col-md-12 col-sm-12 banner-text">
									<div class="full-search-2 eclip-search italian-search hero-search-radius">
										<div class="hero-search-content">
											<div class="card bg-transparent" st>
												<h1 class="h1 text-center" style="font-size: 40px;font-family: &quot;Droid Serif&quot;">Online Recruitment and Employment Solution</h1>
											</div>
											<div class="row justify-content-around hidden-mobile" v-if="!token">
												<div class="col-3">
													<div class="card" style="width: 19rem; height: 10rem; background-color:#799757">
														<div class="card-body">
															<h5 class="text-center text-white">JOB SEEKER</h5>
															<br>
															<div class="row justify-content-around">
																<div class="col-6">
																	<router-link :to="{ name : 'Login' }" type="button" class="btn btn-primary auth_button">LOGIN</router-link>
																</div>
																<div class="col-6">
																	<router-link :to="{ name : 'Register' }" type="button" class="btn btn-primary auth_button">REGISTER</router-link>
																</div>
															</div>

														</div>
													</div>
												</div>
												<div class="col-3">
													<div class="card" style="width: 19rem; height: 10rem; background-color:#799757">
														<div class="card-body" >
															<h5 class="text-center text-white">EMPLOYER</h5>
															<br>
															<div class="row justify-content-around">
																<div class="col-6">
																	<router-link :to="{ name : 'Login' }" type="button" class="btn btn-primary auth_button">LOGIN</router-link>
																</div>
																<div class="col-6">
																	<router-link :to="{ name : 'EmployerRegister' }" type="button" class="btn btn-primary auth_button">REGISTER</router-link>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div><br>
											<div class="card bg-transparent" id="clients">
												<form>
													<div class="row justify-content-center">
														<div class="col-md-5">
															<div class="form-group">
																<input type="text" v-model="search" class="form-control" placeholder="Start typing to filter...">
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<select class="form-control custom-select" v-model="category">
																	<option value="">Category</option>
																	<option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
																</select>
															</div>
														</div>
														<div class="col-md-2">
															<div class="form-group">
																<select class="form-control custom-select" v-model="location">
																	<option value="">Location</option>
																	<option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
																</select>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<br>
					</div>
				</div>
				<div >
					<Clients />
				</div>
				<div class="container" v-if="matchingJobs.length"><br><br><br>
					<h2 class="text-center text-primary">FEATURED JOBS</h2>
					<JobList :matchingJobs="matchingJobs" />
					<div class="text-center">
						<pagination v-model="jobs.current_page" :records="jobs.total" :per-page="jobs.per_page" @paginate="getJobs"/>
					</div>
				</div>
				<div class="justify-content-center" v-else>
					<img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
					<h3 class="text-center">No Job Found</h3>
					<p class="text-center">There are no available Jobs now. There is possibilities there  are some more <router-link :to="{ name:'Jobs'}" class="text-primary">here</router-link></p>
				</div>
				<div class="clearfix"></div>
				<LocationSearch />
				<div class="clearfix"></div>
				<br/><br/>
				<Companies />
				<div class="clearfix"></div>
				<FrontBlog />
			</section>
		</div>
	</div>
	<div class="home" v-else>
		<Loading />
	</div>
<!--  <a href="https://wa.me/+255757888110?text='Greetings,' "  class="whatsapp-button hvr-hang" target="_blank" style="position: fixed;  right: 15px; bottom: 47px; z-index:11; ">-->
<!--    <img style="max-height: 65px" src="https://drive.google.com/file/d/1NOZNhwChytrbhL3QbwT4lfhC4u5jeFL6/view?usp=sharing" alt="">-->
<!--  </a>-->

  <div class="floating-whatsapp" @click="openWhatsApp">
    <img src="@/assets/img/whatsapp.jpg" alt="WhatsApp" />
  </div>

    	<Footer />
</template>

<script>
// @ is an alias to /src
import { computed, ref } from 'vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import JobList from '@/components/JobList.vue'
import Slider from '@/components/Slider.vue'
import Clients from '@/components/Clients.vue'
import LocationSearch from '@/components/LocationSearch.vue'
import FrontBlog from '@/components/FrontBlog.vue'
import Candidates from '@/components/Candidates.vue'
import Reviews from '@/components/Reviews.vue'
import Loading from '@/views/custom/Loading.vue'
import Companies from '@/components/Companies.vue'
import FeedbbackButton from '@/components/FeedbackButton.vue'

export default {
  name: 'Home',
  emits: ["paginate"],
  components: {
   Navbar, Footer, Slider, Clients,JobList, LocationSearch, FrontBlog,Candidates,Reviews,Loading,Companies,FeedbbackButton
  },
data(){
      return {
        currentPage: 'Jobs',
        previousPage: '',
        search: '',
        location: '',
        countries: '',
        categories: '',
        category: '',
        isLoading: true,
        jobs: {current_page: 1},
        allJobs: {},
        data: {},
        errors: false,
		classes: ['d-none']
      }
  },
  methods: {
    openWhatsApp() {
      const phoneNumber = '+255717443616'; // Replace with your WhatsApp number
      const message = 'Hello, You need some assistance!'; // Optional
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    },


	  hoverOver: function() {
    	this.classes = ['animated', 'bounceIn']
    },
    hoverOut: function() {
    	this.classes = ['d-none']
    },
	  hoverOver1: function() {
    	this.classes = ['animated', 'bounceIn']
    },
    hoverOut1: function() {
    	this.classes = ['d-none']
    },
	  hoverOver2: function() {
    	this.classes = ['animated', 'bounceIn']
    },
    hoverOut2: function() {
    	this.classes = ['d-none']
    },
	  hoverOver3: function() {
    	this.classes = ['animated', 'bounceIn']
    },
    hoverOut3: function() {
    	this.classes = ['d-none']
    },
	  hoverOver4: function() {
    	this.classes = ['animated', 'bounceIn']
    },
    hoverOut4: function() {
    	this.classes = ['d-none']
    },
    getJobs(page){
      if (typeof page === 'undefined') {
        page = 1
      }
	  this.isLoading =  true
      axios.get('/jobs/featured?page=' + page).then(response => {
          this.jobs = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 200);
        })
    },
    getFilteredJobs(){
      axios.get('/allJobs').then(response => {
          this.allJobs = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      })
    },
    getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data;
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
	  getToken(){
		return this.token = localStorage.getItem('user_data')
		}
  },
    computed: {
    matchingJobs: function(){
      var self = this
      if(this.category && this.location && this.search) {
          return this.allJobs.filter(function(jobFilter){
            return (jobFilter.category_id == self.category) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
        })
      }if(this.category && this.location) {
          return this.allJobs.filter(function(jobFilter){
            return (jobFilter.category_id == self.category) && ( jobFilter.country_id == self.location)
        })
      }if (this.search) {
          return this.allJobs.filter(function(jobFilter){
          return jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1
         })
      }if(this.category) {
          return this.allJobs.filter(function(jobFilter){
            return jobFilter.category_id == self.category
        })
      }if(this.location) {
          return this.allJobs.filter(function(jobFilter){
            return jobFilter.country_id == self.location
        })
      }else{
        return this.jobs.data
      }

    }
  },
 mounted () {
	document.title = `Home - Vipawaworks`
  this.getJobs()
  this.getFilteredJobs()
  this.getCategories()
  this.getCountries()
  this.getToken()
},

}

// var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
// (function () {
// 	var s1 = document.createElement("script"),
// 			s0 = document.getElementsByTagName("script")[0];
// 	s1.async = true;
// 	s1.src = 'https://embed.tawk.to/62e4eb6c54f06e12d88c09c5/1g973f852';
// 	s1.charset = 'UTF-8';
// 	s1.setAttribute('crossorigin', '*');
// 	s0.parentNode.insertBefore(s1, s0);
// })();
</script>
<style scoped>
.section-pb-0{
	padding-bottom: 0px !important;
}
.clients{
	padding-top: 10%;
}
.banner {
  background-image: url(../assets/img/slider.jpg);
}
.banner.trans {
	min-height: 450px;
}
.banner {
	background-size: cover;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	min-height: 620px;
	align-items: center;
	position: relative;
	background-position: center;
	padding: 80px 0;
}

.banner h1 {
	color: #ffffff;
	text-shadow: none;
	font-weight: 600;
	line-height: 1.3;
	letter-spacing: 3px;
}

.card{
	border: none
}
@media (max-width: 1000px) {
        .hidden-mobile {
          display: none;
        }
      }
.auth_button {
    min-width: 7.65rem;
    max-width: 7.65rem;
}
.position-center{
	position: relative;
}

.side-buttons{
	position: absolute;
    right: 0px;
    top: 40%;
	position: fixed;
}
.btn-right{
	padding-top: 5px;
}
#sideBtn {
    transition: all 0.2s;
}
 .floating-whatsapp {
   position: fixed;
   bottom: 20px;
   right: 20px;
   width: 60px;
   height: 60px;
   background-color: #ffffff;
   border-radius: 50%;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   z-index: 1000;
 }

.floating-whatsapp img {
  width: 35px;
  height: 35px;
}
</style>
