<template>
    <form ref="clearForm" @submit.prevent="editPersonal">
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Position in Company</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.position" >
                        <div v-if="errors.position" class="text-small text-danger">{{ errors.position[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Job Category</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.category_id" class="form-control" >
                            <option value="">Select Job Category</option>
                            <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                        </select>
                        <div v-if="errors.category_id" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Starting Date</label>
                    <div class="form-icon position-relative">
                        <input type="date" class="form-control" v-model="form.started_at" >
                        <div v-if="errors.started_at" class="text-small text-danger">{{ errors.started_at[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Still Working</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.working" class="form-control" >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6" v-if="form.working == 0">
                <div class="mb-3">
                    <label>Ending Date</label>
                    <div class="form-icon position-relative">
                        <input type="date" class="form-control"  v-model="form.ended_at" >
                        <div v-if="errors.ended_at" class="text-small text-danger">{{ errors.ended_at[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Country</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.country" class="form-control" >
                            <option value="">Select Your Country</option>
                            <option :value="country.name" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                        </select>
                        <div v-if="errors.country" class="text-small text-danger">{{ errors.country[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>City</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.city" >
                        <div v-if="errors.city" class="text-small text-danger">{{ errors.city[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Company Name</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.company_name" >
                        <div v-if="errors.company_name" class="text-small text-danger">{{ errors.company_name[0] }}</div>
                    </div>
                </div> 
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Company Size</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.size_id" class="form-control" >
                            <option value="">Select Company</option>
                            <option value="">Select Company Size</option>
                            <option value="1">1-9 Employees</option>
                            <option value="2">10-49 Employees</option>
                            <option value="3">50-99 Employees</option>
                            <option value="4">100-499 Employees</option>
                            <option value="5">500 Employees or More</option>
                        </select>
                        <div v-if="errors.size_id" class="text-small text-danger">{{ errors.size_id[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Company industry</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.industry_id" class="form-control" >
                            <option value="">Select Company industry</option>
                            <option :value="industry.id" v-for="industry in industries" :key="industry.id">{{ industry.name }}</option>
                        </select>
                        <div v-if="errors.industry_id" class="text-small text-danger">{{ errors.industry_id[0] }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <button class="btn btn-primary" disabled v-if="submitting">
                    <span class="label">{{ value }}</span>
                </button>
                <button class="btn btn-primary" v-else>
                    Next
                </button>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props:['user'],
  data(props){
      return {
        form: {
          started_at: '', ended_at: '', working:0, country: '', city : '', company_name: '',size_id: '',
          industry_id: '',category_id: '',position: '',
        },
        submitting: false,
        categories:{},
        countries:{},
        industries:{},
        token: localStorage.getItem('user_data'),
        errors: [],
        userId:props.user,
      }
  },
   methods:{
    editPersonal(){
        this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/experience/createOrUpdate/' + this.user , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'StepTarget', query: { redirect: this.$route.query.redirect }})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getIndustries(){
            axios.get('/industries').then(response => {
                this.industries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getStepExperience(){
            axios.get('/experience/step/' + this.userId).then(response => {
                this.form = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      
  },
  created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getCountries()
      this.getIndustries()
      this.getCategories()
        this.getStepExperience()
  }
}
</script>

<style>

</style>