<template>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyDashboard' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Dashboard</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyJobs' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Jobs</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyCandidates' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">CV Search</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyFreelancers' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
           <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Freelancers</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyProfile' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Company Profile</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyUsers' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Users</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyMyProfile' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">My Profile</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <router-link :to="{ name : 'CompanyPricing' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Pricing</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 " v-if="carts && carts.length">
        <router-link :to="{ name : 'CompanyCart' }"  class="btn accounts rounded d-block shadow text-center py-3 border">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Cart <span class="text-success">({{ carts.length }})</span></h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2 ">
        <a @click="logout" class="btn accounts rounded d-block shadow text-center py-3 border ">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Logout</h6>
        </a>
    </div>
</div>
</template>

<script>

export default {
    props: ['carts'],
     methods: {
         logout() {
             axios.post('/logout').then(response => {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
             }).catch(errors => {
             })
         },
        
     },
}
</script>

<style scoped>
</style>