<template>
<Navbar />
    <section class="section mt-60">
        <div class="container mt-lg-3">
            <div v-if="!isLoading" class="card border-0 rounded shadow">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-md-3 col-12 mt-4 pt-2">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <div class="card-body py-5">
                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Free</h6>
                                    <div class="justify-content-center" >
					                    <img src="@/assets/img/svg/company-owner.svg" height="100" class=" rounded mx-auto d-block">
                                    </div>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="h4 mb-0 mt-2">Employer</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Post Jobs
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Access Resumes
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Interview Candidates
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Search Resume
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Request Freelancers
                                        </li>
                                    </ul>
                                    <router-link :to="{ name : 'EmployerRegister' }" class="btn btn-primary mt-4">Get Started</router-link>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-3 col-12 mt-4 pt-2">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <div class="card-body py-5">
                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Free</h6>
                                    <div class="justify-content-center" >
					                    <img src="@/assets/img/svg/freelancer.svg" height="100" class=" rounded mx-auto d-block">
                                    </div>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="h4 mb-0 mt-2">Freelancers</span>
                                    </div>

                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2"><svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Build up Your Account
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2"><svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Hired as Freelancer
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2"><svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Get Ratings
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Free From Scams
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Get Verification Barge
                                        </li>
                                    </ul>
                                    <router-link :to="{ name : 'FreelancerRegister' }" class="btn btn-primary mt-4">Get Started</router-link>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-3 col-12 mt-4 pt-2">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <div class="card-body py-5">
                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Free</h6>
                                    <div class="justify-content-center" >
					                    <img src="@/assets/img/svg/job-seeker.svg" height="100" class=" rounded mx-auto d-block">
                                    </div>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="h4 mb-0 mt-2">Job Seeker</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Bulid Your Resume
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Access & Apply All Jobs
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Email Notifications
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Set Job Alerts
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Your CV Accessible
                                        </li>
                                    </ul>
                                    <router-link :to="{ name : 'Register' }" class="btn btn-primary mt-4">Try It Now</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-12 mt-4 pt-2">
                            <div class="card pricing-rates business-rate shadow bg-light rounded text-center border-0">
                                <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">New</span></div>
                                <div class="card-body py-5">
                                    <h6 class="title fw-bold text-uppercase text-primary mb-4">Free</h6>
                                    <div class="justify-content-center" >
					                    <img src="@/assets/img/svg/fresher.svg" height="100" class=" rounded mx-auto d-block">
                                    </div>
                                    <div class="d-flex justify-content-center mb-4">
                                        <span class="h4 mb-0 mt-2">College Recruit</span>
                                    </div>
                                    
                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Bulid Your Resume
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                                Experienced Free
                                            </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Access & Apply All Jobs
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Email Notification
                                        </li>
                                        <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2">
                                            <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg></span>
                                            Set Job Alert
                                        </li>
                                    </ul>
                                    <router-link :to="{ name : 'FresherRegister' }" class="btn btn-primary mt-4">Try It Now</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
            <Loading />
            </div>
                        
        </div> 
    </section>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from './custom/Loading.vue'
export default {
  components: {  Navbar, Footer,Loading },
    data(){
      return {
          drawer: true,
          currentUser: {},
          user_info: {companies:[0]},
          user: {
              roles: [], 
          },
          isLoading: true,
      }
  },
  methods:{
    isLoad() {
      setTimeout(function () {
        this.isLoading =  false
    }.bind(this), 500);
   }
  },
  created(){
      document.title = `Get Started - Vipawaworks`
      this.isLoad()
  }
}
</script>

<style>

</style>