<template>
    <div class="row align-items-center">
        <hr>
        <h5 class="text-center text-primary">My Active Packages</h5>
        <div v-if="packagesCategory.length">
            <div class="col-12 mt-4 pt-2">
                <div class="card shop-list border-0 shadow position-relative">
                    <ul class="label list-unstyled mb-0">
                        <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Active</a></li>
                    </ul>
                    <div class="row align-items-center g-0">
                        <div class="col-lg-4 col-md-6">
                            <div class="shop-image position-relative overflow-hidden">
                                <router-link to="" class="overlay-work">
                                    <img src="@/assets/img/svg/deliveries.svg" height="150" class="rounded mx-auto d-block" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6">
                            <div class="card-body content p-4">
                                <router-link to="" class="text-dark product-name h5">Job Posting</router-link>
                                <div class="d-lg-flex align-items-center mt-2 mb-3">
                                    <h6 class="small fst-italic mb-0 me-3 text-success">Free 
                                    </h6>
                                </div>
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        2 Featured Jobs <span class="text-danger">(0 Remained)</span>
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        8 Normal Jobs <span class="text-success">(3 Remained)</span>
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Branding
                                    </a>
                                    </li>
                                    
                                </ul>
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <router-link :to="{name : 'CompanyPakage' }" type="button" class="btn btn-pills btn-primary ">Package Upgrade</router-link>
                                    </div>
                                    <div class="col-6">
                                        <router-link to="" type="button" class="btn btn-pills btn-success ">Renew Package</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div v-else>
             <div class="col-12 mt-4 pt-2">
                <div class="card shop-list border-0 shadow position-relative">
                    <ul class="label list-unstyled mb-0">
                        <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Active</a></li>
                    </ul>
                    <div class="row align-items-center g-0">
                        <div class="col-lg-4 col-md-6">
                            <div class="shop-image position-relative overflow-hidden">
                                <router-link to="" class="overlay-work">
                                    <img src="@/assets/img/svg/deliveries.svg" height="150" class="rounded mx-auto d-block" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6">
                            <div class="card-body content p-4">
                                <router-link to="" class="text-dark product-name h5">Job Posting</router-link>
                                <div class="d-lg-flex align-items-center mt-2 mb-3">
                                    <h6 class="small fst-italic mb-0 me-3 text-success">Free 
                                        <!-- <del class="text-danger ms-2">$25.00</del>  -->
                                    </h6>
                                </div>
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        2 Featured Jobs <span class="text-danger">(0 Remained)</span>
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        8 Normal Jobs <span class="text-success">(3 Remained)</span>
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Branding
                                    </a>
                                    </li>
                                    
                                </ul>
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <router-link :to="{name : 'CompanyPakage', params:{ id:1 }}" type="button" class="btn btn-pills btn-primary ">Package Upgrade</router-link>
                                    </div>
                                    <div class="col-6">
                                        <router-link to="" type="button" class="btn btn-pills btn-success ">Renew Package</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="col-12 mt-4 pt-2">
                <div class="card shop-list border-0 shadow position-relative">
                    <ul class="label list-unstyled mb-0">
                        <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-danger">Expired</a></li>
                    </ul>
                    <div class="row align-items-center g-0">
                        <div class="col-lg-4 col-md-6">
                            <div class="shop-image position-relative overflow-hidden">
                                <router-link to="" class="overlay-work">
                                    <img src="@/assets/img/svg/deliveries.svg" height="150" class="rounded mx-auto d-block" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6">
                            <div class="card-body content p-4">
                                <router-link to="" class="text-dark product-name h5">Company Advertisement</router-link>
                                <div class="d-lg-flex align-items-center mt-2 mb-3">
                                    <h6 class="small fst-italic mb-0 me-3 text-success">Tsh50000 
                                        <del class="text-danger ms-2">Tsh80000</del> 
                                    </h6>
                                </div>
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Landing/Home page Branding 
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Jobs Page Branding 
                                    </a>
                                    </li>
                                    
                                </ul>
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <router-link :to="{name : 'CompanyPakage', params:{ id:2 }}" type="button" class="btn btn-pills btn-primary ">Package Upgrade</router-link>
                                    </div>
                                    <div class="col-6">
                                        <router-link to="" type="button" class="btn btn-pills btn-success ">Renew Package</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

            <div class="col-12 mt-4 pt-2">
                <div class="card shop-list border-0 shadow position-relative">
                    <ul class="label list-unstyled mb-0">
                        <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-success">Active</a></li>
                    </ul>
                    <div class="row align-items-center g-0">
                        <div class="col-lg-4 col-md-6">
                            <div class="shop-image position-relative overflow-hidden">
                                <router-link to="" class="overlay-work">
                                    <img src="@/assets/img/svg/deliveries.svg" height="150" class="rounded mx-auto d-block" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6">
                            <div class="card-body content p-4">
                                <router-link to="" class="text-dark product-name h5">CV Search</router-link>
                                <div class="d-lg-flex align-items-center mt-2 mb-3">
                                    <h6 class="small fst-italic mb-0 me-3 text-success">Tsh50000 
                                        <del class="text-danger ms-2">Tsh80000</del> 
                                    </h6>
                                </div>
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Intelligent Search
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Downloading 2 CV <span class="text-danger">(0 Remained)</span>
                                    </a>
                                    </li>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        View Unlimited Job Seeker Profile
                                        </a>
                                    </li>
                                </ul>
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <router-link :to="{name : 'CompanyPakage', params:{ id:3 }}" type="button" class="btn btn-pills btn-primary ">Package Upgrade</router-link>
                                    </div>
                                    <div class="col-6">
                                        <router-link to="" type="button" class="btn btn-pills btn-success ">Renew Package</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

            <div class="col-12 mt-4 pt-2">
                <div class="card shop-list border-0 shadow position-relative">
                    <ul class="label list-unstyled mb-0">
                        <li><a href="javascript:void(0)" class="badge badge-link rounded-pill bg-danger">Expired</a></li>
                    </ul>
                    <div class="row align-items-center g-0">
                        <div class="col-lg-4 col-md-6">
                            <div class="shop-image position-relative overflow-hidden">
                                <router-link to="" class="overlay-work">
                                    <img src="@/assets/img/svg/deliveries.svg" height="150" class="rounded mx-auto d-block" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6">
                            
                            <div class="card-body content p-4">
                                <router-link to="" class="text-dark product-name h5">Freelancer Services</router-link>
                                <div class="d-lg-flex align-items-center mt-2 mb-3">
                                    <h6 class="small fst-italic mb-0 me-3 text-success">Tsh500000 
                                        <del class="text-danger ms-2">Tsh650000</del> 
                                    </h6>
                                </div>
                                <ul class="list-unstyled mb-0">
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        20 Freelancers
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        First class freelancers
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Contact informations
                                    </a>
                                    </li>
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Access freelancers 1 Months
                                    </a>
                                    </li>
                                    
                                </ul>
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <router-link :to="{name : 'CompanyPakage', params:{ id:4 }}" type="button" class="btn btn-pills btn-primary ">Package Upgrade</router-link>
                                    </div>
                                    <div class="col-6">
                                        <router-link to="" type="button" class="btn btn-pills btn-success ">Renew Package</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
           
    </div>
</template>

<script>
export default {
    props:['profile'],
    data(){
      return {
          token: localStorage.getItem('user_data'),
          packagesCategory: {},
      }
  },
    methods:{
        getPackages(){
          axios.get('/packages/active/' + this.profile.id).then(response => {
            this.packagesCategory = response.data
        }).catch(errors => {
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      }
    },
    created(){
        if (this.profile) {
            this.getPackages()
        }
    }

}
</script>

<style>

</style>