<template>
    <NavEmployer :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-12 d-lg-block ">
                        <ProgressSidebar :progress="user_info.companies[0]" :job="job"/>
                    </div>
                    <div class="col-lg-10 col-md-10 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <div class="col mt-4 pt-2" id="tables">
                                <div class="component-wrapper rounded shadow">
                                        <form ref="clearForm" @submit.prevent="firstInterview">
                                            <div class="row mt-4">
                                                <h4 class="text-center">First Interview {{ applicant.fname }} {{ applicant.sname }} {{ applicant.lname }}</h4>
                                                <input type="hidden" v-model="form.job_id">
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Skills</label>
                                                        <div class="form-icon position-relative">
                                                            <select class="form-control"  v-model="form.skill">
                                                                <option value="0">Extreemly Poor</option>
                                                                <option value="1">Very Poor</option>
                                                                <option value="2">Poor</option>
                                                                <option value="3">Below Average</option>
                                                                <option value="4">Average</option>
                                                                <option value="5">Above Average</option>
                                                                <option value="6">Good</option>
                                                                <option value="7">Very Good</option>
                                                                <option value="8">Excellent</option>
                                                                <option value="9">Outstanding</option>
                                                            </select>
                                                            <div v-if="errors.skills" class="text-small text-danger">{{ errors.skills[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Attitude</label>
                                                        <div class="form-icon position-relative">
                                                            <select class="form-control"  v-model="form.attitude">
                                                                <option value="0">Extreemly Worse</option>
                                                                <option value="1">Very Bad</option>
                                                                <option value="2">Bad</option>
                                                                <option value="3">Below Average</option>
                                                                <option value="4">Average</option>
                                                                <option value="5">Above Average</option>
                                                                <option value="6">Good</option>
                                                                <option value="7">Very Good</option>
                                                                <option value="8">Excellent</option>
                                                                <option value="9">Outstanding</option>
                                                            </select>
                                                            <div v-if="errors.attitude" class="text-small text-danger">{{ errors.attitude[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Knowledge</label>
                                                        <div class="form-icon position-relative">
                                                            <select class="form-control"  v-model="form.knowledge">
                                                                <option value="0">Extreemly Poor</option>
                                                                <option value="1">Very Poor</option>
                                                                <option value="2">Poor</option>
                                                                <option value="3">Below Average</option>
                                                                <option value="4">Average</option>
                                                                <option value="5">Above Average</option>
                                                                <option value="6">Knowledgable</option>
                                                                <option value="7">Very Knowledgable</option>
                                                                <option value="8">Extreemly Knowledgable</option>
                                                                <option value="9">Outstanding</option>
                                                            </select>
                                                            <div v-if="errors.knowledge" class="text-small text-danger">{{ errors.knowledge[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Availability</label>
                                                        <div class="form-icon position-relative">
                                                            <select class="form-control"  v-model="form.availability">
                                                                <option value="1">Immediately</option>
                                                                <option value="2">One Week or Less</option>
                                                                <option value="3">Two Weeks or Less</option>
                                                                <option value="4">One Month or Less</option>
                                                                <option value="5">Two Months or Less</option>
                                                                <option value="6">Three Months</option>
                                                                <option value="7">Six Months</option>
                                                                <option value="8">One Year</option>
                                                            </select>
                                                            <div v-if="errors.knowledge" class="text-small text-danger">{{ errors.knowledge[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">General Rating</label>
                                                        <div class="form-icon position-relative">
                                                            <select class="form-control"  v-model="form.rating">
                                                                <option value="1">1 Star</option>
                                                                <option value="2">2 Stars</option>
                                                                <option value="3">3 Stars</option>
                                                                <option value="4">4 Stars</option>
                                                                <option value="5">5 Stars</option>
                                                            </select>
                                                            <div v-if="errors.rating" class="text-small text-danger">{{ errors.rating[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Addittion Knowledge & Skills</label>
                                                        <div class="form-icon position-relative">
                                                            <textarea v-model="form.addition_knowledge" class="form-control" ></textarea>
                                                            <div v-if="errors.addition_knowledge" class="text-small text-danger">{{ errors.addition_knowledge[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Salary(Monthly)</label>
                                                        <div class="form-icon position-relative">
                                                            <input type="text" class="form-control" v-model="form.salary" min="0">
                                                            <div v-if="errors.salary" class="text-small text-danger">{{ errors.salary[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Currency</label>
                                                        <div class="form-icon position-relative">
                                                            <select class="form-control"  v-model="form.currency">
                                                                <option value="1">TSH</option>
                                                                <option value="2">USD</option>
                                                            </select>
                                                            <div v-if="errors.currency" class="text-small text-danger">{{ errors.currency[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="mb-3">
                                                        <label class="form-label">Age</label>
                                                        <div class="form-icon position-relative">
                                                                <input type="number" class="form-control" v-model="form.age" min="0">
                                                            <div v-if="errors.age" class="text-small text-danger">{{ errors.age[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">General Comment: to Employer</label>
                                                        <div class="form-icon position-relative">
                                                            <textarea class="form-control"  v-model="form.employer_comment"></textarea>
                                                            <div v-if="errors.employer_comment" class="text-small text-danger">{{ errors.employer_comment[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">General Comment: to Candidate</label>
                                                        <div class="form-icon position-relative">
                                                            <textarea class="form-control"  v-model="form.candidate_comment"></textarea>
                                                            <div v-if="errors.candidate_comment" class="text-small text-danger">{{ errors.candidate_comment[0] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input type="submit" id="submit" class="btn btn-primary" value="Save Changes">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
     <div v-else>
       <Loading />
   </div>
  <Footer />
</template>

<script>
import moment from 'moment'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import ProgressSidebar from '@/components/company/ProgressSidebar.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    props:['job'],
    components: { NavEmployer,Footer,ProgressSidebar,Loading, },
    data(props){
      return {
        drawer: true,
        currentUser: {},
        userProfile: {},
        user: {},
        success: false,
        errors: false,
        applicant: {},
        isLoading: true,
        form: {
            availability: 1, skill: 1, attitude: 1, knowledge: 1, addition_knowledge: '', salary: '', currency: 1, age: '', rating: 1,
            employer_comment: '', candidate_comment: '', job_id: props.job,
        },
        job_id:props.job,
        user_info: {companies:[0]},
        token: localStorage.getItem('user_data'),
      }
  },
  methods: {
      getUser(props){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
       
      },
      getApplicant(){
        let id = this.$route.params.id
        axios.get('/profile/' + id).then(response => {
            this.applicant = response.data
            }).catch(errors => {
        })
      },
      firstInterview(){
          let id = this.$route.params.id
          axios.post('/application/firstInterview/' + id + '/' + this.job, this.form).then(response => {
              this.success = response.data.success
              this.$router.push({ name: 'CompanyApplicationShortlisted', params:{ id:this.job}})
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
       checkInterview(applicant){
          let id = this.$route.params.id
          let job = this.$route.params.id
          axios.get('/application/checkInterview/' + id + '/' + this.job).then(response => {
              this.form = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
  },
  created(){
      document.title = `First Interview - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
      this.getApplicant()
      this.checkInterview()
  },
}
</script>

<style>

</style>