<template>
<div class="alert alert-danger" v-if="error">
    <h6 class="text-center">{{ error }}</h6>
</div>
    <form ref="clearForm" @submit.prevent="editPersonal">
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Company Name</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control " v-model="form.name"  >
                        <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Short Name</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control " v-model="form.short_name" >
                        <div v-if="errors.short_name" class="text-small text-danger">{{ errors.short_name[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Company Email</label>
                    <div class="form-icon position-relative">
                        <input type="email" class="form-control " v-model="form.email" >
                        <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                        <div id="passwordHelpBlock" class="form-text">making sure you use company Email, we will need verify that</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Company Phone</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control " v-model="form.phone" >
                        <div v-if="errors.phone" class="text-small text-danger">{{ errors.phone[0] }}</div>
                        <div id="passwordHelpBlock" class="form-text">we insist use of company emails, not personal emails.</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Year Founded</label>
                    <div class="form-icon position-relative">
                        <input type="year" class="form-control " v-model="form.founded_at" >
                        <div v-if="errors.founded_at" class="text-small text-danger">{{ errors.founded_at[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Choose Company Size</label>
                    <div class="form-icon position-relative">
                        <select class="form-control"  v-model="form.size">
                            <option value="">Choose Company Size</option>
                            <option value="1-9 Employees">1-9 Employees</option>
                            <option value="10-49 Employees">10-49 Employees</option>
                            <option value="50-99 Employees">50-99 Employees</option>
                            <option value="100-499 Employees">100-499 Employees</option>
                            <option value="500 or More Employees">500 or More Employees</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Vision</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control " v-model="form.vision" >
                        <div v-if="errors.vision" class="text-small text-danger">{{ errors.vision[0] }}</div>

                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Mission</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control " v-model="form.mission" >
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label>Company Description</label>
                    <div class="form-icon position-relative">
                        <textarea class="form-control "  cols="30"  v-model="form.description"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <button class="btn btn-primary" disabled v-if="submitting">
                    <span class="label">{{ value }}</span>
                </button>
                <button type="submit" class="btn btn-primary" v-else>
                    Create account
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { ref } from 'vue'
export default {
    props:['user'],
    data(props){
        return {
            values: [],
            form: {
               email: '',phone: '', name: '', short_name: '',founded_at : '',size: '', vision: '', description: '',
            },
            error:null,
           errors:[],
           userId:props.user,
           submitting: false,
        }
    },
    methods:{
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/company/stepAdd/' + this.userId, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CompanyDashboard'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
    }
}
</script>

<style>

</style>
