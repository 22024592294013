<template>
<Navbar />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h3 class="text-center">Interview Progress <span class="text-primary">{{ applicant.fname }} {{ applicant.sname }} {{ applicant.lname }}</span> </h3>
                                <div class="alert alert-success" v-if="success">
                                    <h6 class="text-center">{{ success }}</h6>
                                </div>
                                <div class="alert alert-danger" v-if="errors[0]">
                                    <h6 class="text-center">{{ errors }}</h6>
                                </div>
                                <div class="border-bottom pb-4" v-if="first_interview.attitude">
                                    <div class="row">
                                        <div class="col-md-6 mt-4">
                                            <div class="mt-4">

                                                <div class="d-flex align-items-center">
                                                    <div class="flex-1" >
                                                        <h5 class="text-center">First Interview Results</h5>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Skills:</a> 
                                                                <span v-if="first_interview.skill == 9">Outstanding</span>
                                                                <span v-if="first_interview.skill == 8">Excellent</span>
                                                                <span v-if="first_interview.skill == 7">Very Good</span>
                                                                <span v-if="first_interview.skill == 6">Good</span>
                                                                <span v-if="first_interview.skill == 5">Above Average</span>
                                                                <span v-if="first_interview.skill == 4">Average</span>
                                                                <span v-if="first_interview.skill == 3">Below Average</span>
                                                                <span v-if="first_interview.skill == 2">Poor</span>
                                                                <span v-if="first_interview.skill == 1">Very Poor</span>
                                                                <span v-if="first_interview.skill == 0">Extreemly Poor</span>
                                                        </p> 
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Attitude:</a> 
                                                            <span v-if="first_interview.attitude == 9">Outstanding</span>
                                                                <span v-if="first_interview.attitude == 8">Excellent</span>
                                                                <span v-if="first_interview.attitude == 7">Very Good</span>
                                                                <span v-if="first_interview.attitude == 6">Good</span>
                                                                <span v-if="first_interview.attitude == 5">Above Average</span>
                                                                <span v-if="first_interview.attitude == 4">Average</span>
                                                                <span v-if="first_interview.attitude == 3">Below Average</span>
                                                                <span v-if="first_interview.attitude == 2">Bad</span>
                                                                <span v-if="first_interview.attitude == 1">Very Bad</span>
                                                                <span v-if="first_interview.attitude == 0">Extreemly Worse</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Knowledge:</a> 
                                                                <span v-if="first_interview.knowledge == 9">Outstanding</span>
                                                                <span v-if="first_interview.knowledge == 8">Extreemly Knowledgable</span>
                                                                <span v-if="first_interview.knowledge == 7">Very Knowledgable</span>
                                                                <span v-if="first_interview.knowledge == 6">Knowledgable</span>
                                                                <span v-if="first_interview.knowledge == 5">Above Average</span>
                                                                <span v-if="first_interview.knowledge == 4">Average</span>
                                                                <span v-if="first_interview.knowledge == 3">Below Average</span>
                                                                <span v-if="first_interview.knowledge == 2">Poor</span>
                                                                <span v-if="first_interview.knowledge == 1">Very Poor</span>
                                                                <span v-if="first_interview.knowledge == 0">Extreemly Poor</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Availability:</a> 
                                                                <span v-if="first_interview.availability == 8">One Year</span>
                                                                <span v-if="first_interview.availability == 7">Six Months</span>
                                                                <span v-if="first_interview.availability == 6">Three Months</span>
                                                                <span v-if="first_interview.availability == 5">Two Months or Less</span>
                                                                <span v-if="first_interview.availability == 4">One Month or Less</span>
                                                                <span v-if="first_interview.availability == 3">Two Weeks or Less</span>
                                                                <span v-if="first_interview.availability == 2">One Week or Less</span>
                                                                <span v-if="first_interview.availability == 1">Immediately</span>
                                                        </p>
                                                            
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Additional Knowledge:</a> 
                                                            {{ first_interview.addition_knowledge }}
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Salary:</a> 
                                                            {{ Number(first_interview.salary ).toLocaleString() }}
                                                            <span v-if="first_interview.currency == 1">TSH</span>
                                                                <span v-if="first_interview.currency == 2">USD</span>
                                                        </p>
                                                       <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Rating:</a> 
                                                           <star-rating v-model:rating="first_interview.rating" :increment="0.1" :read-only="true" :animate="true"></star-rating>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">General Comment:</a> 
                                                                {{ first_interview.employer_comment }}
                                                        </p>
                                                    </div>
                                                    
                                                </div>
        
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-4 pt-2 pt-sm-0">
                                            <div class="mt-4" v-if="second_interview.attitude">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-1">
                                                        <h5 class="text-center">Second Interview Results</h5>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Skills:</a> 
                                                                <span v-if="second_interview.skill == 9">Outstanding</span>
                                                                <span v-if="second_interview.skill == 8">Excellent</span>
                                                                <span v-if="second_interview.skill == 7">Very Good</span>
                                                                <span v-if="second_interview.skill == 6">Good</span>
                                                                <span v-if="second_interview.skill == 5">Above Average</span>
                                                                <span v-if="second_interview.skill == 4">Average</span>
                                                                <span v-if="second_interview.skill == 3">Below Average</span>
                                                                <span v-if="second_interview.skill == 2">Poor</span>
                                                                <span v-if="second_interview.skill == 1">Very Poor</span>
                                                                <span v-if="second_interview.skill == 0">Extreemly Poor</span>
                                                        </p> 
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Attitude:</a> 
                                                                <span v-if="second_interview.attitude == 9">Outstanding</span>
                                                                <span v-if="second_interview.attitude == 8">Excellent</span>
                                                                <span v-if="second_interview.attitude == 7">Very Good</span>
                                                                <span v-if="second_interview.attitude == 6">Good</span>
                                                                <span v-if="second_interview.attitude == 5">Above Average</span>
                                                                <span v-if="second_interview.attitude == 4">Average</span>
                                                                <span v-if="second_interview.attitude == 3">Below Average</span>
                                                                <span v-if="second_interview.attitude == 2">Bad</span>
                                                                <span v-if="second_interview.attitude == 1">Very Bad</span>
                                                                <span v-if="second_interview.attitude == 0">Extreemly Worse</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Knowledge:</a> 
                                                                <span v-if="second_interview.knowledge == 9">Outstanding</span>
                                                                <span v-if="second_interview.knowledge == 8">Extreemly Knowledgable</span>
                                                                <span v-if="second_interview.knowledge == 7">Very Knowledgable</span>
                                                                <span v-if="second_interview.knowledge == 6">Knowledgable</span>
                                                                <span v-if="second_interview.knowledge == 5">Above Average</span>
                                                                <span v-if="second_interview.knowledge == 4">Average</span>
                                                                <span v-if="second_interview.knowledge == 3">Below Average</span>
                                                                <span v-if="second_interview.knowledge == 2">Poor</span>
                                                                <span v-if="second_interview.knowledge == 1">Very Poor</span>
                                                                <span v-if="second_interview.knowledge == 0">Extreemly Poor</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Availability:</a> 
                                                                <span v-if="second_interview.availability == 8">One Year</span>
                                                                <span v-if="second_interview.availability == 7">Six Months</span>
                                                                <span v-if="second_interview.availability == 6">Three Months</span>
                                                                <span v-if="second_interview.availability == 5">Two Months or Less</span>
                                                                <span v-if="second_interview.availability == 4">One Month or Less</span>
                                                                <span v-if="second_interview.availability == 3">Two Weeks or Less</span>
                                                                <span v-if="second_interview.availability == 2">One Week or Less</span>
                                                                <span v-if="second_interview.availability == 1">Immediately</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Additional Knowledge:</a> 
                                                            {{ second_interview.addition_knowledge }}
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Salary:</a> 
                                                            {{ Number(second_interview.salary ).toLocaleString() }}
                                                            <span v-if="second_interview.currency == 1">TSH</span>
                                                                <span v-if="second_interview.currency == 2">USD</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Rating:</a> 
                                                           <star-rating v-model:rating="second_interview.rating" :increment="0.1" :read-only="true" :animate="true"></star-rating>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">General Comment:</a> 
                                                                {{ second_interview.employer_comment }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-4 alert alert-danger" v-else>
                                                <h5 class="text-center">This Candidate has not been Second Interviewed</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4 alert alert-danger" v-else>
                                    <h4 class="text-center">This Candidate has not been Interviewed</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
     <div v-else>
       <Loading />
   </div>
<Footer />
</template>

<script>
import moment from 'moment'
import StarRating from 'vue-star-rating'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    props:['job'],
  components: { Navbar,Footer,Loading, StarRating},
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {},
          first_interview: {},
          second_interview: {},
          success: false,
          errors: false,
          applicant: {},
          shortlist_first: false,
            shortlist_second: false,
          form:{
                applicant: this.$route.params.id,
               	interview_date: '',
                interview_time:'',
                interview_location:'',
                interview_instructions:'',
          },
          isLoading: true,
          progress: {},
          user_info: {companies:[0]},
          token: localStorage.getItem('user_data'),
      }
  },
  methods: {
      getApplicant(){
        let id = this.$route.params.id
        axios.get('/profile/' + id).then(response => {
            this.applicant = response.data
            }).catch(errors => {
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
       checkInterview(){
        let id = this.$route.params.id
          axios.get('/application/checkInterview/' + id + '/' + this.job).then(response => {
              this.first_interview = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      checkSecondInterview(){
        let id = this.$route.params.id
          axios.get('/application/checkSecondInterview/' + id + '/' + this.job).then(response => {
              this.second_interview = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      checkProgress(){
        let id = this.$route.params.id
          axios.get('/candidate/application/checkInterview/' + id + '/' + this.job).then(response => {
              this.progress = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      revokeRejectionApplicant(applicant){
          let job = this.$route.params.job
          axios.post('/application/revokeRejection/' + job, applicant).then(response => {
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
        })
      },
  },
  created(){
      document.title = `Candidate Details - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getApplicant()
      this.checkProgress()
      this.checkInterview()
      this.checkSecondInterview()
  },
}
</script>

<style>

</style>