  <template>
  <NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Personal Information</h5>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Company Mobile Phone</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.phone"  >
                                                    <div v-if="errors.phone" class="text-small text-danger">{{ errors.phone[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Company Fax Number</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.fax" >
                                                    <div v-if="errors.fax" class="text-small text-danger">{{ errors.fax[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Company Email Address</label>
                                                <div class="form-icon position-relative">
                                                    <input type="email" class="form-control" v-model="form.email" >
                                                    <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Website Address</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.web_address" >
                                                    <div v-if="errors.web_address" class="text-small text-danger">{{ errors.web_address[0] }}</div>
                                                    <div id="passwordHelpBlock" class="form-text">Please compy and paste link from url, should be like https://vipawaworks.com</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Local Address</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.local_address1" >
                                                    <div v-if="errors.local_address1" class="text-small text-danger">{{ errors.local_address1[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Local Address 2(optional)</label>
                                                <div class="form-icon position-relative">
                                                <input type="text" class="form-control" v-model="form.local_address2" >
                                                    <div v-if="errors.local_address2" class="text-small text-danger">{{ errors.local_address2[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Country</label>
                                                <div class="form-icon position-relative">
                                                 <select class="form-control" v-model="form.country_id" @change="getCities()">
                                                        <option value="">Select Country</option>
                                                        <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                    </select>
                                                    <div v-if="errors.country_id" class="text-small text-danger">{{ errors.country_id[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">City</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.city_id">
                                                        <option value="">Select City</option>
                                                        <option :value="city.id" v-for="city in cities" :key="city.id">{{ city.name }}</option>
                                                    </select>
                                                    <div v-if="errors.city_id" class="text-small text-danger">{{ errors.city_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Postal Code</label>
                                                <div class="form-icon position-relative">
                                                <input type="text" class="form-control" v-model="form.postal_code" >
                                                    <div v-if="errors.postal_code" class="text-small text-danger">{{ errors.postal_code[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
    </div>
     <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { NavEmployer,Footer,Loading, },
    data(){
        return {
            values: [],
            countries: {},
            cities: {},
            form: {
              web_address: '', phone: '', fax: '',email: '',local_address1: '',local_address2: '', city_id: '', country_id : '', postal_code: '',
            },
            user: {}, 
            user_info: { companies:[0]},
            isLoading: true,
            submitting: false, 
            token: localStorage.getItem('user_data'),
            errors: []
        }
    },
    created(){
        document.title = `Edit Contacts Details - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
       this.getUser()
        let id = this.$route.params.id
        axios.get('/company/show/' + id).then(response => {
            this.form = response.data
        }).catch((error) => {
            this.errors = error.response.data.errors
        })
        this.getCountries()
    },
    methods: {
        
        getUser(){
             axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })        
        },
        getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = errors.response.data.errors
        })
      }, 
      getCities(){
        axios.get('/cities/' + this.form.country_id).then(response => {
            this.cities = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
        editPersonal(){
            let id = this.$route.params.id
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/company/editContact/' + id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CompanyProfile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
       
    }
    

}
</script>

<style>

</style>