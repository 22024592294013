<template>
    <div class="sidebar sticky-bar p-4 rounded shadow">
        <div class="widget">
            <h5 class="widget-title">Activities :</h5>
            <div class="row mt-4">
                <div class="col text-center">
                    <span class="text-primary">
                        <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                    </span>
                    <h5 class="mb-0" v-if="profile[0]">{{ profile[0] }}</h5>
                    <h5 class="mb-0" v-else>0</h5>
                    <p class="text-muted mb-0">Job Posted</p>
                </div>
            </div>
        </div>
        <div class="widget">
            <Nav :carts="carts.cart_items" /> 
        </div>
    </div>
</template>

<script>

import Nav from '@/components/company/Nav.vue'
export default {
     components: { Nav },
     props:['progress'],
     data(){
      return {
          profile: {},
          carts:{
              cart_items: []
          }
      }
  },
      methods: {
        companyProgress(){
            axios.get('/company/progress/' + this.progress.id).then(response => {
                this.profile = response.data
            }).catch(errors => {
            })
        },
         companyCart(){
            axios.get('/company/cart/count/' + this.progress.id).then(response => {
                this.carts = response.data
            }).catch(errors => {
            })
        }
     },
     mounted(){
      this.companyProgress()
      this.companyCart()
  }
}
</script>

<style>

</style>