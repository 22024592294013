<template>
<div>
    <div class="d-flex flex-row-reverse" v-if="editable == 1">
            <router-link class="btn text-primary end-0" :to="{ name : 'Profile' }">
               <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
            </router-link>
         </div>
       <h6>
            <span class="fw-bold">Email:</span> {{ user_info.email }}
        </h6>
        <h6>
            <span class="fw-bold">Phone:</span> {{ user_info.profile[0].mobile_phone }}
        </h6>
        <h6>
           <span class="fw-bold">Website:</span> {{ user_info.profile[0].website }} 
        </h6>
    </div>
</template>

<script>

export default {
    props : ['user_info','editable'],
}
</script>

<style>

</style>