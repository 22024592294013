<template>
    <div>
        <div v-for="edu in user_info.education" :key="edu.id">
            <div class="d-flex flex-row-reverse" v-if="editable == 1">
                <a @click="deleteEducation(edu.id)" class="btn btn-pills text-primary">
                    <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                </a>
                <router-link :to="{ name: 'EditEducation', params:{ id:edu.id }}" class="btn btn-pills text-primary">
                    <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                </router-link>
                
            </div>
            <h5 class="margin-bottom-0">
                <strong>{{ edu.level.name }}, {{ edu.faculty }}</strong>
            </h5>
            <h6 class="margin-bottom-0">
                at <span>{{ edu.name }}</span>
            </h6>
            <h6 class="margin-bottom-0">
            <span class="text-muted">{{ edu.city }}, {{ edu.country }}</span> 
            </h6>
            <h6>
            <span class="text-muted">{{  moment(new Date(edu.started_at)).format('DD/MM/YYYY') }} to <span v-if="edu.studying == 1">To Present</span> <span v-else>{{  moment(new Date(edu.ended_at)).format('DD/MM/YYYY') }}</span></span> 
            <span class="text-primary" v-if="edu.marks"> Graduated marks: {{ edu.marks }}</span>
            </h6>
        </div>
    </div>
   
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
    props : ['user_info','editable'],
     methods: {
        deleteEducation(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/education/destroy/'+ id)
                .then(response => {
                    this.user_info.education = this.user_info.education.filter(education => {
                      return education.id != id
                      })
                }).catch(() => {
                    this.$router.push({ name: 'Carrier'})
                })
                Swal.fire(
                'Deleted!',
                'Education has been Deleted.',
                'success'
                )
            }
            })
        }
    },
created(){
    this.moment = moment
}
}
</script>

<style>

</style>