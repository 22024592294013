<template>
<NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-12"  v-if="user_info.companies[0]">
                        <div class="rounded shadow p-4"  v-if="user_info.companies[0].active == 1">
                            <div v-if="candidates.saved_resume.length">
                                <div class="row">
                                    <h3 class="text-center text-primary">All Saved Resumes</h3>
                                    <div class="col-md-6 col mt-4 pt-2" v-for="candidate in candidates.saved_resume" :key="candidate.id">
                                        <div class="card shop-list border-0 shadow position-relative">
                                            <ul class="label list-unstyled mb-0" v-if="candidate.verified == 1">
                                                <li>
                                                    <a href="javascript:void(0)" class="badge badge-link rounded-pill text-primary">
                                                       <svg height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="row align-items-center g-0">
                                                <div class="col-lg-3 col-md-9">
                                                    <div class="shop-image position-relative overflow-hidden">
                                                        <router-link :to="{ name:'CandidateResume', params:{ id:candidate.id} }">
                                                            <div v-if="candidate.avatar">
                                                                <img v-bind:src="'https://api.vipawaworks.com/' + candidate.avatar" class="avatar float-md-left avatar-large img-fluid" alt="">
                                                            </div>
                                                            <div v-else>
                                                                <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar float-md-left avatar-large img-fluid" alt="">
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9">
                                                    <div class="d-flex flex-row-reverse">
                                                        <button class="btn text-primary end-0" @click="deleteResume(candidate.id)">
                                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                                        </button>
                                                    </div>
                                                    <div class="card-body content p-4">
                                                        <router-link :to="{ name:'CandidateResume', params:{ id:candidate.id} }" class="text-dark product-name h4">{{ candidate.fname }} {{ candidate.lname}}</router-link>
                                                        <a href="javascript:void(0)" class="badge badge-link rounded-pill text-primary" v-if="candidate.verified == 1">
                                                                <svg height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                                            </a>
                                                        <div class="d-lg-flex align-items-center mt-2 mb-3">
                                                            <h6 class="text-primary small fst-italic mb-0 me-3">{{ candidate.roles[0].name }}</h6>
                                                            <h6 class="text-muted small fst-italic mb-0 me-3" v-if="candidate.specialists[0]">{{ candidate.specialists[0].name && candidate.specialists[0].name.length < 20 ? candidate.specialists[0].name : candidate.specialists[0].name.substring(0,17)+".."  }}</h6>
                                                            <h6 class="text-muted small fst-italic mb-0 me-3" v-else>No Specialist</h6>
                                                        </div>
                                                        <p class="para-desc text-muted mb-1" v-if="candidate.profile[0]">
                                                            <span v-if="candidate.profile[0].city">{{ candidate.profile[0].city.name }}, </span>
                                                            <span>{{ candidate.profile[0].country.emoji }} {{ candidate.profile[0].country.name }}</span>
                                                        </p>
                                                        <p class="para-desc text-muted mb-1" v-else>No Profile Infomation</p>
                                                        <router-link :to="{ name:'CandidateResume', params:{ id:candidate.id} }" class="btn btn-primary"><i class="uil uil-envelope"></i> Preview CV</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="justify-content-center">
                                    <img src="@/assets/img/svg/empty.svg" height="200" class="rounded mx-auto d-block">
                                    <h4 class="text-center">No Resume Saved</h4>
                                    <p class="text-center">It looks like You donot have any Resume Saved</p>
                                </div>
                            </div>
                            
                        </div>
                        <div class="text-center" v-else>
                            <img src="@/assets/img/svg/campling.svg" height="200" class="rounded mx-auto d-block">
                            <h2>Your Company Is Domant</h2>
                            <p>Since we need to make sure that the entered details are correct and do not violate our terms and Conditions. At meantime you cannot enjoy this Service.</p>
                            <p>We apologies for inconvinient, since there have been alot of untrusted people in internet, we may contact you inorder to verify your Company during working hour.</p>
                            <p>If this takes more time please <router-link  :to="{ name : 'Contacts' }">Contact us</router-link></p>
                        </div>
                    </div>
                    <div v-else class="col-lg-8 col-12">
                        <div class="justify-content-center">
                            <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Company Not Registed</h4>
                            <p class="text-center">Get Started by Filling Up Your <router-link class="text-dark" :to="{ name : 'AddCompanyProfile' }">Company Info</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
    </div>
  <Footer />
</template>

<script>
import Swal from 'sweetalert2'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import CompanyDetails from '@/components/company/CompanyDetails.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { NavEmployer, Footer, Sidebar, CompanyDetails,Loading, },
  data(){
      return {
        drawer: true,
        currentUser: {},
        user: {},
        form:{  
            rating:'',gender:'', roles:'', minEdu:'', maxEdu:'', minSalary:'', maxSalary:'',minAge:'', maxAge:'',country_id:'', city_id:'',specialist:''
            },
        showFormValues: false,
        education: {},
        candidates: {},
        countries: {},
        cities: {},
        allCandidates:{},
        filteredCandidates: {},
        filter: false,
        user_info: { company: {}, roles: {}, companies:[0]},
        token: localStorage.getItem('user_data'),
        isLoading: true,
        errors: [],
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
            if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
      getCandidates(){
          axios.get('/candidates/view/viewSavedResume').then(response => {
                this.candidates = response.data
        }).catch(errors => {
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
      getEducation(){
        axios.get('/educationLevel').then(response => {
            this.education = response.data
        }).catch(errors => {
            this.CountryErrors = errors.response.data.errors
        })
      },
      getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      }, 
      getCities(){
        axios.get('/cities/' + this.form.country_id).then(response => {
            this.cities = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      deleteResume(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/application/deleteResume/'+ id)
                .then(response => {
                    this.candidates.saved_resume = this.candidates.saved_resume.filter(candidate => {
                      return candidate.id != id
                      })
                }).catch(() => {
                    this.$router.push({ name: 'Skills'})
                })
                Swal.fire(
                'Deleted!',
                'Skill has been Deleted.',
                'success'
                )
            }
            })
        }
  },
  created(){
      document.title = `Saved Resumes - VipawaWorks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.getCandidates()
      this.getCountries()
      this.getEducation()  
  }
}
</script>

<style>
.item + .item:before {
  content: ", ";
}

</style>