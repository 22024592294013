<template>
<Navbar />
    <section class="bg-auth-home d-table w-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7">
                    <div class="card shadow rounded border-0">
                        <div class="card-body">
                            <h3 class="card-title text-center">Create a Free Account</h3>  
                            <RegisterForm />
                        </div>
                    </div>
                </div> 
                <div class="col-lg-5 col-md-5">
                    <div class="me-lg-5">   
                        <img :src="require('@/assets/img/site/register.png')" class="img-fluid d-block mx-auto" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import RegisterForm from '@/components/auth/RegisterForm.vue'
export default {
  components: { Navbar, Footer,RegisterForm },
created(){
    document.title = `Job Seeker Register - Vipawaworks`
}
}
</script>

<style>

</style>