<template>
<div class="alert alert-danger" v-if="error">
    <h6 class="text-center">{{ error }}</h6>
</div>
    <form class="login-form mt-4" @submit.prevent="registerUser">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label >First name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <input type="text" v-model="form.first_name" class="form-control ps-5" >
                        <div v-if="errors.first_name" class="text-small text-danger">{{ errors.first_name[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3"> 
                    <label>Middle name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        <input type="text" v-model="form.second_name" class="form-control ps-5" >
                        <div v-if="errors.second_name" class="text-small text-danger">{{ errors.second_name[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3"> 
                    <label>Surname <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        <input type="text" v-model="form.last_name" class="form-control ps-5">
                        <div v-if="errors.last_name" class="text-small text-danger">{{ errors.last_name[0] }}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="mb-3">
                    <label>Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons">
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        <input type="email" v-model="form.email" class="form-control ps-5">
                        <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="mb-3">
                    <label>Password <span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                        <input :type="passwordFieldType" v-model="form.password" class="form-control ">
                        <a @click="switchVisibility" class="input-group-text">
                            <div v-if="passwordIcon == true">
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                            </div>
                            <div v-else>
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                            </div>
                        </a> 
                    </div> 
                    <div v-if="errors.password" class="text-small text-danger">{{ errors.password[0] }}</div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label>Confirm Password <span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                        <input :type="confirmFieldType" v-model="form.password_confirmation" class="form-control ">
                        <a @click="switchConfirmVisibility" class="input-group-text">
                            <div v-if="confirmIcon == true">
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                            </div>
                            <div v-else>
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                            </div>
                        </a> 
                    </div>
                    <div v-if="errors.password_confirmation" class="text-small text-danger">{{ errors.password_confirmation[0] }}</div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="mb-3">
                    <div class="form-check">
                        <input v-model="form.terms" class="form-check-input" type="checkbox" value="" id="flexCheckDefault" >
                        <label for="flexCheckDefault">I Accept <router-link :to="{ name : 'Terms' }" class="text-primary" target="_blank">Terms And Condition</router-link></label>
                        <div v-if="errors.terms" class="text-small text-danger">{{ errors.terms[0] }}</div> 
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="d-grid">
                    <button class="btn btn-primary" disabled v-if="submitting">
                        <span class="label">{{ value }}</span>
                    </button>
                    <button class="btn btn-primary" v-else>Register</button>
                </div>
            </div>
        </div>
    </form>
    
    <div class="row justify-content-between">
        <div class="col-6">
            <p class="mb-0 mt-3"><small class="text-dark me-2">Already have an account?</small> <router-link :to="{ name : 'Login' }" class="text-dark fw-bold">Sign in</router-link></p>
        </div>
        <div class="col-6">
            <p class="mb-0 mt-3"><small class="text-dark me-2">Employer? </small> <router-link :to="{ name : 'EmployerRegister' }" class="text-dark fw-bold">Click here</router-link></p>
        </div>
    </div>
            
    
</template>

<script>
import { ref } from 'vue'
export default {
    data(){
        return {
            values: [],
            form: {
               email: '',first_name: '', second_name: '', last_name: '',password : '',terms: '', roles: 2, password_confirmation: '', device_name: 'browser',
            },
            error:null,
            passwordFieldType: "password",
            confirmFieldType: "password",
            passwordIcon: true,
            confirmIcon: true,
            submitting: false,
            errors:[]
        }
    },
    methods:{
        registerUser(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/register/new', this.form).then(response => {
                localStorage.setItem('user_data', response.data.token)
                this.$router.push({ name: 'FreelancerStepSpecialist'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
            this.passwordIcon = this.passwordIcon === true ? false : true
        },
        switchConfirmVisibility() {
            this.confirmFieldType = this.confirmFieldType === "password" ? "text" : "password"
            this.confirmIcon = this.confirmIcon === true ? false : true
        }
    }    
}
</script>

<style>

</style>