import { computed, ref } from 'vue'

const jobCategory = (id) => {
    const jobs = ref([])
    const category = ref([])
    const search = ref('')
    const error = ref(null)
    const errorCategory = ref(null)

    const loadCategory = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/categories/jobs/' + id)
            if (!data.ok){
                throw Error('No Job Found')
            }
            jobs.value = await data.json()
        }
        catch (err){
            error.value = err.message
        }
        try {
            let dataCategory = await fetch('https://api.vipawaworks.com/api/categories/' + id)
            if (!dataCategory.ok){
                throw Error('No Job Found')
            }
            category.value = await dataCategory.json()
            document.title = category.value.name +'- Vipawaworks'
        }
        catch (err){
            errorCategory.value = err.message
        }
    }
    const matchingJobs = computed(() => {
        return jobs.value.filter(function(jobName){
            return jobName.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1 
        })
    })
    return { jobs, category, matchingJobs, search, error, errorCategory, loadCategory }
  }

  export default jobCategory