<template>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">

                    <div class="heading white-heading">
<!--                        Feedback / Reviews-->
                    </div>
                    <div class="carousel-inner">
                        <div v-for="(feedback, index) in allFeedbacks" :key="index" class="carousel-item text-center" :class="{'active' :index === 0}">
                            <quote>&rdquo;</quote>
                            <h4 class="slider_text ">{{feedback.message}}</h4>
                            <p class="slider_caption">{{feedback.name}}</p>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        components: {
            Carousel,
            Slide
        },
        data(){
            return {
                allFeedbacks: [],
            }
        },
        methods: {
            getFeedbacks(){
                axios.get('https://api.vipawaworks.com/api/all_feedbacks').then(response => {
                    console.log(response.data)
                    this.allFeedbacks = response.data
                }).catch(errors => {
                    this.CountryErrors = error.response.data.errors
                })
            },
        },
        computed: {
        },
        mounted () {
            this.getFeedbacks()
        },

    }
</script>
<style scoped>

.card{
	border: none
}
.page-next-level .page-next li::after {
	content: "";
	font-size: '';
	color: '';
	font-family: '';
}

.slider_text {
    font-size: 22px;
    font-weight: bold;
    font-family: 'Roboto';
    line-height: 1.7;
    color: #111;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 750px;
}
.slider_caption {
    font-size: 14px;
    color: #111;
    opacity: .5;
    font-family: 'Roboto';
    font-weight: bold;
}
    /*.carousel-control-next-icon{*/
    /*    background: black;*/
    /*}*/
    /*.carousel-control-prev-icon{*/
    /*    background: black;*/
    /*}*/

.heading {
    text-align: center;
    color: #454343;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    z-index: 999;
}
quote {
    font-family: 'Arial';
    font-weight: bold;
    font-size: 50px;
    color: #ec2027;
    margin-bottom: 0;
}
</style>
