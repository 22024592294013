<template>
  <div>
        <div class="d-flex flex-row-reverse"  v-if="editable == 1">
            <router-link class="btn text-primary end-0" :to="{ name : 'CandidateJob' }">
               <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
            </router-link>
        </div>
        <div v-for="job in user_info.candidate_jobs" :key="job.id">
            <h6>
                <span class="fw-bold">Job Title:</span> {{ job.name }}
            </h6>
            <h6>
                <span class="fw-bold">Job Category:</span> {{ job.category.name }}
            </h6>
        </div>
        <div v-for="country in user_info.preference_countries" :key="country.id">
            <h6>
                <span class="fw-bold">Preference Country:</span> {{ country.name }}
            </h6>
        </div>
        <div v-for="industry in user_info.preference_industries" :key="industry.id">
            <h6>
                <span class="fw-bold">Preference Industry:</span> {{ industry.name }}
            </h6>
        </div>
        <div v-for="type in employmentTypes" :key="type.id">
            <h6>
                <span class="fw-bold">Employment Type:</span>
                <span v-if="type.type_id == 1">Consultant</span>
                <span v-if="type.type_id == 2">Contractor</span>
                <span v-if="type.type_id == 3">Employee</span>
                <span v-if="type.type_id == 4">Temporary Employee</span>
                <span v-if="type.type_id == 5">Volunteer</span>
            </h6>
        </div>
        <div>
            <h6 v-for="sal in salary" :key="sal.id">
                <span class="fw-bold">Target monthly salary:</span>
                <span v-if="sal.current_currency == 1">USD:</span>
                <span v-if="sal.current_currency == 2">TSH:</span>
                {{ Number(sal.current).toLocaleString()  }}
            </h6>
        </div>
       
    </div>
</template>

<script>
export default {
    props:['user_info','salary','employmentTypes','editable'],

}
</script>

<style>

</style>