<template>
<NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">

                    <div class="col-lg-12 col-12" v-if="user_info.companies[0]">
                        <div class="card border-0 rounded shadow" v-if="user_info.companies[0].active == 1">
                            <div class="card-body">
                                <h3 class="text-center">All Company Jobs</h3>
                                <div class="col mt-4 pt-2" id="tables">
                                <div class="component-wrapper">
                                    <div class="p-4">
                                        <div class="d-flex flex-row">
                                            <button class="btn btn-primary" v-on:click="filter = !filter">
                                                <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                            </button>
                                        </div>
                                        <div class="d-flex flex-row-reverse">
                                            <router-link class="btn btn-primary " :to="{ name : 'AddJob' }">Post Job</router-link>
                                        </div>
                                        <div class="row" v-if="filter">
                                            <br>
                                            <div class="col-12">
                                                <div class="border-bottom">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <input type="text" v-model="search"  class="form-control banner-search"  name="keyword" id="joblist" placeholder="Search for Job Title" autocomplete="off" aria-label="Title">
                                                    </div>
                                                    <div class="col-md-3 ">
                                                        <div class="mb-0">
                                                            <select class="form-control custom-select" v-model="category">
                                                                <option value="">All Categories</option>
                                                                <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 ">
                                                        <div class="mb-0">
                                                            <select class="form-control custom-select" v-model="location">
                                                                <option value="">Everywhere</option>
                                                                <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-4 ">
                                                        <div class="mb-0">
                                                            <select class="form-control custom-select" v-model="target">
                                                                <option value="">Both Experienced & Freshers</option>
                                                                <option value="2">Experienced Only</option>
                                                                <option value="3">Freshers Only</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <div class="mb-0">
                                                            <select class="form-control custom-select" v-model="featured">
                                                                <option value="">Featured & Non Featured</option>
                                                                <option value="1">Featured</option>
                                                                <option value="0">Non Featured</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <div class="mb-0">
                                                            <select class="form-control custom-select" v-model="published">
                                                                <option value="">Published & Draft</option>
                                                                <option value="1">Published</option>
                                                                <option value="0">Draft</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                </div>
                                            </div>
                                        </div>
                                        
                                            <div v-if="matchingJobs.length">
                                                <table class="table mb-0 table-center">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col" class="border-bottom">Name</th>
                                                        <th scope="col" class="border-bottom">Created At</th>
                                                        <th scope="col" class="border-bottom">Deadline</th>
                                                        <th scope="col" class="border-bottom">Applications</th>
                                                        <th scope="col" class="border-bottom">Viwers</th>
                                                        <th scope="col" class="border-bottom">Target</th>
                                                        <th scope="col" class="border-bottom">Featured</th>
                                                        <th scope="col" class="border-bottom">Status</th>
                                                        <th scope="col" class="border-bottom">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="job in matchingJobs" :key="job.id">
                                                            <td>{{ job.name && job.name.length < 24 ? job.name : job.name.substring(0,24)+".."   }}</td>
                                                            <td>{{  moment(new Date(job.created_at)).format('DD/MM/YYYY') }}</td>
                                                            <td>{{  moment(new Date(job.deadline)).format('DD/MM/YYYY') }}</td>
                                                            <td class="text-center">
                                                                <span v-if="job.method == 1">
                                                                    <router-link :to="{ name: 'CompanyApplicationProgress', params:{ id:job.id}}">View Progress({{ job.applicants_count }})</router-link>
                                                                </span>
                                                            <span v-else>
                                                                No Internal Applications
                                                            </span>
                                                            </td>
                                                            <td class="text-center">{{  job.viewers_count }}</td>
                                                            <td> 
                                                                <span v-if="job.target == 1">Both</span>
                                                                <span v-else-if="job.target == 2">Job Seeker</span>
                                                                <span v-else-if="job.target == 3">Fresher</span>
                                                                <span v-else>Both</span>
                                                            </td>
                                                            <td> 
                                                                <span v-if="job.featured == 1">Yes</span>
                                                                <span v-else>No</span>
                                                            </td>
                                                            <td>
                                                                <span v-if="job.status == 1">Published</span>
                                                                <span v-else>Draft</span>
                                                            </td>
                                                            <td class="text-center">
                                                                <router-link :to="{ name: 'EditJob', params:{ id:job.id }}" class="text-warning">
                                                                    <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
                                                                </router-link>
                                                                <button @click="deleteJob(job.id)" class="btn text-danger">
                                                                    <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                                </button>
                                                                <span></span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="text-center">
                                                    <br>
                                                    <pagination v-model="jobs.current_page" :records="jobs.total" :per-page="jobs.per_page" @paginate="getJobs"/>
                                                </div> 
                                            </div>
                                            <div v-else >
                                                <div class="justify-content-center">
                                                    <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                                    <h4 class="text-center">No Jobs Found</h4>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center"  v-else>
                            <img src="@/assets/img/svg/campling.svg" height="200" class="rounded mx-auto d-block">
                            <h2>Your Company Is Domant</h2>
                            <p>Since we need to make sure that the entered details are correct and do not violate our terms and Conditions. At meantime you cannot enjoy this Service.</p>
                            <br>
                            <p>We apologies for inconvinient, since there have been alot of untrusted people in internet, we may contact you inorder to verify your Company during working hour.</p>
                            <p>If this takes more time please <router-link  :to="{ name : 'Contacts' }">Contact us</router-link></p>
                        </div>
                    </div>
                    <div v-else class="col-lg-8 col-12">
                        <div class="justify-content-center">
                            <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Company Not Registed</h4>
                            <p class="text-center">Get Started by Filling Up Your <router-link class="text-dark" :to="{ name : 'AddCompanyProfile' }">Company Info</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
<Footer />
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { NavEmployer,Footer,Sidebar,Loading },
  emits: ["paginate"],
    data(){
      return {
            drawer: true,
            currentUser: {},
            userProfile: {},
            user: {},
            jobs: {},
            profile:{},
            search: '',
            location: '',
            countries: '',
            categories: '',
            category: '',
            target: '',
            featured: '',
            published: '',
            filter: false,
            isLoading: true,
            jobs: {current_page: 1},
            allJobs: {},
            data: {},
            user_info: { companies:[0]},
            isLoading: true,
            token: localStorage.getItem('user_data'),
      }
  },
  computed: {
    matchingJobs: function(){
      var self = this
        if (this.jobs.data) {
            if(this.category && this.location && this.target && this.featured && this.published && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && (jobFilter.target == self.target) && (jobFilter.featured == self.featured) && (jobFilter.published == self.published) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location && this.target && this.published && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && (jobFilter.target == self.target) && (jobFilter.published == self.published) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location && this.target && this.featured && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && (jobFilter.target == self.target) && (jobFilter.featured == self.featured) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location && this.featured && this.published && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && (jobFilter.featured == self.featured) && (jobFilter.published == self.published) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location && this.featured  && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && (jobFilter.featured == self.featured) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location && this.published && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && (jobFilter.published == self.published) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location && this.target && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && (jobFilter.target == self.target) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location && this.search) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
                })
            }if(this.category && this.location) {
                return this.allJobs.filter(function(jobFilter){
                    return (jobFilter.category_id == self.category) && ( jobFilter.country_id == self.location)
                })
            }if (this.search) {
                return this.allJobs.filter(function(jobFilter){
                return jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1 
                })
            }if(this.category) {
                return this.allJobs.filter(function(jobFilter){
                    return jobFilter.category_id == self.category
                })
            }if(this.location) {
                return this.allJobs.filter(function(jobFilter){
                    return jobFilter.country_id == self.location
                })
            }if(this.target) {
                return this.allJobs.filter(function(jobFilter){
                    return jobFilter.target == self.target
                })
            }if(this.published) {
                return this.allJobs.filter(function(jobFilter){
                    return jobFilter.status == self.published
                })
            }if(this.featured) {
                return this.allJobs.filter(function(jobFilter){
                    return jobFilter.featured == self.featured
                })
            }else{
                return this.jobs.data
            }
      }else{
          return matchingJobs = null
      }
    }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
      },
      getJobs(page){
        if (typeof page === 'undefined') {
            page = 1
        }
        this.isLoading =  true
        axios.get('/companyFilterJobs?page=' + page).then(response => {
            this.jobs = response.data
        }).catch(errors => {
            this.errors = errors.response.data.errors
        }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1500);
            })
        },
        getAllJobs(){
            axios.get('/companyAllJobs').then(response => {
                this.allJobs = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      deleteJob(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/jobs/destroyJob/'+ id)
                .then(response => {
                    this.jobs.data = this.jobs.data.filter(job => {
                      return job.id != id
                    })
                }).catch(() => {
                    this.$router.push({ name: 'CompanyJobs'})
                })
                Swal.fire(
                'Deleted!',
                'Job has been Deleted.',
                'success'
                )
            }
            })
        },
      
  },
  mounted(){
      document.title = `All Jobs - Vipawaworks`
        this.moment = moment
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        if (this.user_info) { 
        this.getJobs() 
        this.getAllJobs() 
        this.getCategories()
        this.getCountries() 
        }
  },
}
</script>

<style>

</style>