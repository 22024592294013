<template>
    
    <div class="banner-area three">
        <div class="banner-shape-three">
            <img src="@/assets/img/slider.jpg" alt="Shape">
        </div>
        <div class="container-fluid">
            <div class="banner-content two three">
                <div class="d-table">
                    <div class="d-table-cell">
                        <p>Helping you to find any type of job</p>
                        <h1>Online Recruitment and  <span>Employment Solution</span></h1>
                        <div class="banner-form-area">
                            <form>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>
                                                <i class="bx bx-search"></i>
                                            </label>
                                            <input type="text" class="form-control" placeholder="Search Your Job">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group two">
                                            <label>
                                                <i class="bx bx-location-plus"></i>
                                            </label>
                                            <input type="text" class="form-control" placeholder="Location">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-bottom-btn">
            <a href="#job">
                <i class="bx bx-mouse-alt"></i>
            </a>
        </div>
    </div>
</template>

<style scoped>
h1{
    font-family: "Droid Serif"
}
body {
	font-family: "Poppins", sans-serif;
	color: #5f5656;
	font-size: 15px;
}
.banner-area.three {
	background-color: #156394;
}
.banner-area {
	background-color: #f8e8e9;
	position: relative;
}
.banner-area .container-fluid {
	padding-left: 120px;
}
.banner-area .container-fluid {
	padding-left: 50px;
	padding-right: 0;
}
.banner-content.three {
	height: 1075px;
	max-width: 800px;
	padding-top: 95px;
}
.banner-content.two {
	height: 980px;
}
.banner-content {
	height: 1070px;
}
.d-table {
	width: 100%;
	height: 100%;
}
.d-table {
	display: table !important;
}
.d-table-cell {
	vertical-align: middle;
}
.banner-content.three h1 {
	color: #fff;
}
.banner-content.two h1 {
	font-size: 65px;
	max-width: 750px;
}
.banner-content.three h1 {
	color: #fff;
}
.banner-content.two h1 {
	font-size: 65px;
	max-width: 750px;
}
.banner-content.three h1 span {
	color: #8ec73a;
}
.banner-content h1 span {
	color: #8ec73a;
}
.banner-content .banner-form-area {
	padding-right: 0;
	margin-bottom: 35px;
}
.banner-content .banner-form-area {
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 205px;
	border-radius: 5px;
	background-color: #fff;
	position: relative;
	max-width: 925px;
	margin-bottom: 40px;
}
.banner-content .banner-key ul {
	margin: 0;
	padding: 0;
}
.banner-content .banner-key ul li {
	list-style-type: none;
	display: inline-block;
}
.banner-content.three .banner-key ul li span {
	padding: 0;
	color: #fff;
	background-color: transparent;
}
.banner-area .container-fluid .row {
	margin-left: 0;
	margin-right: 0;
}
.banner-area .container-fluid .row .col-lg-4 {
	padding-left: 0;
	padding-right: 0;
}
.banner-content .banner-form-area .form-group {
	margin-bottom: 0;
	position: relative;
}
.banner-content .banner-form-area .form-group label {
	margin-bottom: 0;
	position: absolute;
	left: 0;
	top: 13px;
	color: #5f5656;
	font-size: 20px;
}
.banner-content .banner-form-area .form-group .form-control {
	height: 50px;
	border: 0;
	font-size: 15px;
	padding-left: 30px;
	border-right: 1px solid #5f5656;
	border-radius: 0;
}
.banner-content.three .register-area {
	background-color: transparent;
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 140px;
}
.register-area {
	background-color: #dffedb;
	padding-top: 50px;
	padding-bottom: 20px;
}
.register-area .container {
	position: relative;
}
.banner-content.three .register-area .container .row {
	padding-right: 0;
}
.register-area .container .row {
	padding-right: 390px;
}
.banner-area .container-fluid .row {
	margin-left: 0;
	margin-right: 0;
}
.banner-area .container-fluid .row .col-lg-4 {
	padding-left: 0;
	padding-right: 0;
}
.banner-content.three .register-area .register-item {
	border-left: 1px dashed #babab9;
}
.register-area .register-item {
	margin-bottom: 30px;
	padding-left: 30px;
	border-left: 1px dashed #7068a3;
	position: relative;
}
.banner-content.three .register-area .register-item p {
	color: #babab9;
	font-size: 15px;
}
.banner-content.three p {
	color: #fff;
}
.register-area .register-item p {
	margin-bottom: 0;
}
.banner-content.two p {
	margin-bottom: 10px;
}
.banner-content p {
	margin-bottom: 40px;
	font-weight: 500;
	font-size: 20px;
	color: #555555;
}
p {
	line-height: 1.8;
}
.banner-area.three .banner-bottom-btn {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -30px;
	max-width: 50px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
.banner-area.three .banner-bottom-btn a {
	color: #fff;
	display: inline-block;
	font-size: 30px;
	position: relative;
	top: 24px;
	-webkit-animation: banner-bottom-btn-ani 5s infinite linear;
	animation: banner-bottom-btn-ani 5s infinite linear;
}
.banner-area.three .banner-shape-three img {
	max-width: 915px;
}
.banner-area.three .banner-shape-three img {
	position: absolute;
	bottom: 0;
	right: 40px;
	max-width: 749px;
}
img {
	max-width: 100%;
}
</style>