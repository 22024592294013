<template>
<NavEmployer :user="user" />
  <!-- <Profile :profile="user_info.companies[0]" :user="user"  /> -->
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">

                    <div class="col-lg-12 col-12">
                        <div class="rounded shadow p-4">
                            <h3 class="text-center text-primary">Upgrade {{ packagesCategory.name}} Packages</h3>
                            <AllPackages :packagesCategory="packagesCategory" :profile="user_info.companies[0]" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
    </div>
  <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Profile from '@/components/company/Profile.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import CompanyDetails from '@/components/company/CompanyDetails.vue'
import Loading from '@/views/custom/Loading.vue'
import AllPackages from '@/components/company/AllPackages.vue'
export default {
  components: { NavEmployer,Footer, Profile, Sidebar, CompanyDetails,Loading,AllPackages, },
  data(){
      return {
          drawer: true,
          currentUser: {},
          user: {},
          packagesCatogory: {},
          user_info: { company: {}, roles: {}, companies:[0]},
          token: localStorage.getItem('user_data'),
           isLoading: true,
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
      },
      getPackages(){
          let id = this.$route.params.id
          axios.get('/packages/category/' + id).then(response => {
            this.packagesCategory = response.data
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
  },
  created(){
      document.title = `Upgrade Package - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser() 
      this.getPackages() 
      this.isCreated = true   
  }
}
</script>

<style>
.item + .item:before {
  content: ", ";
}

</style>