<template>
  <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="rounded shadow p-4">
                            <h4 class="text-center mb-0">Add A Target Salary: </h4>
                            <div class="row projects-wrapper">
                                <form ref="clearForm" @submit.prevent="targetJob">
                                    <div class="row mt-4">

                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Target Month Salary</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.current">
                                                    <div v-if="errors.current" class="text-small text-danger">{{ errors.current[0] }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Currency</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.current_currency">
                                                        <option value="1" >USD</option>
                                                        <option value="2" >TSH</option>
                                                    </select>
                                                    <div v-if="errors.current_currency" class="text-small text-danger">{{ errors.current_currency[0] }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Last Month Salary</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.previous">
                                                    <div v-if="errors.previous" class="text-small text-danger">{{ errors.previous[0] }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Currency</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.previous_currency">
                                                        <option value="1" >USD</option>
                                                        <option value="2" >TSH</option>
                                                    </select>
                                                    <div v-if="errors.previous_currency" class="text-small text-danger">{{ errors.previous_currency[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
   </div>
    	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
  components: { Footer,NavSeeker,Loading },
    data(){
        return {
            drawer: true,
            currentUser: {},
            userProfile: {},
            user: {roles: [0]},
            form: {
                previous:'', previous_currency:1, current: '', current_currency:1,
            },
            categories: '',
            user_info: [],
            errors: [],
            token: localStorage.getItem('user_data'),
            isLoading: true,
            submitting: false,
            isMounted: false
        }
    },
    methods: {
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
                if (this.user.banned == 1) {
                    this.$router.push({ name: 'Banned'})
                }
                if (this.user.email_verified_at == null) {
                    this.$router.push({ name: 'VerifyEmail'})
                }
                if (this.user.roles.length == 0) {
                    this.$router.push({ name: 'Roles'})
                }if (this.user.roles[0].id == 1) {
                    this.$router.push({ name: 'CompanyDashboard'})
                }if (this.user.roles[0].id == 4) {
                    this.$router.push({ name: 'CompanyDashboard'})
                }if (this.user.roles[0].id == 2 ) {
                    this.$router.push({ name: 'FreelancerDashboard'})
                }
            }).catch(errors => {
                if (errors.response.status === 401) {
                    localStorage.removeItem('user_data')
                    this.$router.push({ name: 'Login'})
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
        getCategories(){
            axios.get('/categories').then(response => {
                this.categories = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        targetJob(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/targetSalary/add/' + this.user.id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CandidateJob'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
    },
  created(){
      document.title = `Add Salary - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.getCategories() 
      this.isCreated = true   
  }
}
</script>

<style>

</style>