<template>
    <div>
        <h2 class="text-center text-primary">Manage My Jobs Alerts</h2>
        <div v-if="subscriber == 0" class="row justify-content-center">
            <div class="col-md-6">
                    <h4 class="text-center text-primary">Do not Miss Your Preference Jobs</h4>
                    <div class="col mt-4 pt-2" id="tables">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4">
                                <div class="table-responsive bg-white text-center" >
                                    <img src="@/assets/img/png/alert.png"  height="200" alt="">
                                    <h6 class="text-center">Set Job Alert To be Notified As Soon as Job matches Your Preference Is Posted</h6>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="col-md-6">
                    <h4 class="card-title text-primary">Set Job Alert</h4>
                    <div class="custom-form mt-3">
                        <div class="alert alert-success" v-if="success">
                            <h6 class="text-center">{{ success }}</h6>
                        </div>
                        <div class="alert alert-danger" v-if="errors[0]">
                            <h6 class="text-center">{{ errors }}</h6>
                        </div>
                        <form @submit.prevent="jobAlert" ref="clearForm" name="myForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Job Category</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.category_id ">
                                                <option value="0">All Categories</option>
                                                <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                                            </select>
                                            <div v-if="errors.category_id" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Company Industry</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.industry_id">
                                                <option value="0">All Industries</option>
                                                <option :value="industry.id" v-for="industry in industries" :key="industry.id">{{ industry.name }}</option>
                                            </select>
                                            <div v-if="errors.industry_id" class="text-small text-danger">{{ errors.industry_id[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Countries</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.country_id">
                                                <option value="0">Any Country</option>
                                                <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                            </select>
                                            <div v-if="errors.country_id" class="text-small text-danger">{{ errors.country_id[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Employment Type</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.employment_type">
                                                <option value="0">Any Type</option>
                                                <option value="1">Consultant</option>
                                                <option value="2">Contractor</option>
                                                <option value="3">Employee</option>
                                                <option value="4">Temporary Employee</option>
                                                <option value="5">Volunteer</option>
                                            </select>
                                            <div v-if="errors.employment_type" class="text-small text-danger">{{ errors.employment_type[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                           
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-grid">
                                        <button type="submit" id="submit" name="send" class="btn btn-primary">Set Alert</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
            </div>
        </div>
        <div v-else class="row justify-content-center">
            <div class="col-md-6">
                <div v-if="editable">
                    <h4 class="card-title text-primary">Edit Job Alert</h4>
                    <div class="custom-form mt-3">
                        <div class="alert alert-success" v-if="success">
                            <h6 class="text-center">{{ success }}</h6>
                        </div>
                        <div class="alert alert-danger" v-if="errors[0]">
                            <h6 class="text-center">{{ errors }}</h6>
                        </div>
                        <form @submit.prevent="jobAlert" ref="clearForm" name="myForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Job Category</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.category_id ">
                                                <option value="0">All Categories</option>
                                                <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                                            </select>
                                            <div v-if="errors.category_id" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Company Industry</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.industry_id">
                                                <option value="0">All Industries</option>
                                                <option :value="industry.id" v-for="industry in industries" :key="industry.id">{{ industry.name }}</option>
                                            </select>
                                            <div v-if="errors.industry_id" class="text-small text-danger">{{ errors.industry_id[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Countries</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.country_id">
                                                <option value="0">Any Country</option>
                                                <option :value="count.id" v-for="count in countries" :key="count.id">{{ count.name }}</option>
                                            </select>
                                            <div v-if="errors.country_id" class="text-small text-danger">{{ errors.country_id[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Employment Type</label>
                                        <div class="form-icon position-relative">
                                            <select class="form-control "  v-model="form.employment_type">
                                                <option value="0">Any Type</option>
                                                <option value="1">Consultant</option>
                                                <option value="2">Contractor</option>
                                                <option value="3">Employee</option>
                                                <option value="4">Temporary Employee</option>
                                                <option value="5">Volunteer</option>
                                            </select>
                                            <div v-if="errors.employment_type" class="text-small text-danger">{{ errors.employment_type[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-grid">
                                        <button type="submit" id="submit" name="send" class="btn btn-primary">Edit Alert</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div v-else>
                    <h4 class="text-center text-primary">My Job Prefference</h4>
                    <div class="col mt-4 pt-2" id="tables">
                        <div class="component-wrapper rounded shadow">
                            <div class="table-responsive bg-white shadow rounded">
                                <table class="table mb-0 table-center">
                                    <tbody>
                                        <tr>
                                        <td><strong>Country:</strong></td>
                                            <td>
                                                <span v-if="subscription.country_id == 0">
                                                    Everywhere In the World
                                                </span>
                                                <span v-else-if="subscription.country">
                                                    {{ subscription.country.name }}
                                                </span>
                                            </td> 
                                        </tr>
                                        <tr>
                                            <td><strong>Category:</strong> </td>
                                            <td>
                                                <span v-if="subscription.category_id == 0">
                                                    Every Category 
                                                </span>
                                                <span v-else-if="subscription.category">
                                                    {{ subscription.category.name }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                        <td><strong>Industry:</strong></td>
                                            <td>
                                                <span v-if="subscription.industry_id == 0">
                                                    Every Industry
                                                </span>
                                                <span v-else-if="subscription.industry">
                                                    {{ subscription.industry.name }}
                                                </span>
                                            </td> 
                                        </tr>
                                        <tr>
                                        <td><strong>Gender Preference:</strong></td>
                                            <td>
                                                <span v-if="subscription.sex == 0">
                                                    Both Male and Female
                                                </span>
                                                <span v-else-if="subscription.sex == 1">
                                                    Only Male
                                                </span>
                                                <span v-else-if="subscription.sex == 2">
                                                    Only Female
                                                </span>
                                            </td> 
                                        </tr>
                                        <tr>
                                        <td><strong>Employment Type:</strong></td>
                                            <td>
                                                <span v-if="subscription.employment_type == 0">
                                                    Any Type
                                                </span>
                                                <span v-else-if="subscription.employment_type == 1">
                                                    Consultant
                                                </span>
                                                <span v-else-if="subscription.employment_type == 2">
                                                    Contractor
                                                </span>
                                                <span v-else-if="subscription.employment_type == 3">
                                                    Employee
                                                </span>
                                                <span v-else-if="subscription.employment_type == 4">
                                                    Temporary Employee
                                                </span>
                                                <span v-else-if="subscription.employment_type == 5">
                                                    Volunteer
                                                </span>
                                            </td> 
                                        </tr>
                                        <tr>
                                        <td><strong>Employment Status:</strong></td>
                                            <td>
                                                <span v-if="subscription.employment_status == 1">
                                                    Part Time
                                                </span>
                                                <span v-else>
                                                    Full Time
                                                </span>
                                            </td> 
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="text-center">
                                    <button class="btn btn-primary text-center" v-on:click="editable = !editable">Edit Job Preference</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h4 class="text-center text-primary">Unsubscribe Jobs Alerts</h4>
                <div class="col mt-4 pt-2" id="tables">
                    <div class="component-wrapper rounded shadow">
                        <div class="p-4">
                            <div class="table-responsive bg-white text-center" >
                                <img src="@/assets/img/png/alert.png"  height="200" alt="">
                                <h6 class="text-center">Remove All Alerts that I recieve through Emails</h6>
                                <button class="btn btn-primary" @click="unSubscribe">Unsubscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
</template>

<script>
export default {
    props: ['user_info'],
    data(){
        return {
            subscriber:{},
            subscription: {
                country:{},
                category:{},
                industry:{}
            },
             form: {
                country_id: 0, 
                category_id: 0, 
                industry_id: 0,
                sex: 0,
                employment_status: 2,
                employment_type: 0
            },
            countries: {},
            categories: {},
            industries: {},
            country:{},
            category:{},
            industry:{},
            editable: false,
            errors: [],
            success: false
        }
    },
    methods:{
        checkSubscription(){
            axios.get('/jobAlert/checkJobSubscriber').then(response => {
                this.subscriber = response.data.subscriber
                this.form = response.data
                }).catch(errors => {
                    this.errors = 'Something went wrong'
            })
        },
        checkSubscriptionDetails(){
            axios.get('jobAlert/getSubscriberDetails').then(response => {
                this.subscription = response.data
                this.form = response.data
                }).catch(errors => {
                    this.errors = 'Something went wrong'
            })
        },
        jobAlert(){
            axios.post('/myjobAlert', this.form).then(response => {
                this.$refs.clearForm.reset()
                this.success = response.data.success
                this.errors = false
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
         unSubscribe(){
            axios.delete('/deleteJobAlert').then(response => {
                this.subscriber = 0
                this.errors = false
            }).catch((error) => {
                this.errors = error.response.data.error
            })
        },
        getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
        }, 
        getIndustries(){
            axios.get('/industries').then(response => {
                this.industries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getCategories(){
            axios.get('/categories').then(response => {
                this.categories = response.data
            }).catch(errors => {
                this.CountryErrors = errors.response.data.errors
            })
        },
      },
    created(){
        this.getCountries()
        this.getCategories()
        this.getIndustries()
        this.checkSubscription()
        this.checkSubscriptionDetails()
    }
}
</script>

<style>

</style>