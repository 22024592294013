<template>
<Navbar />
    <section class="section">
        <div class="container">
            <div class="row align-items-center" id="counter">
                <div class="col-md-6">
                    <img src="@/assets/img/others/about.jpg"  class="img-fluid rounded shadow" alt="">
                </div>
            </div>
        </div>
    </section>
    <section class="bg-light">

    </section>
    <Footer />
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  name: 'Jobs',
  emits: ["paginate"],
  components: {
    Loading,Navbar,Footer
  },
  created(){
      document.title = `About Us - Vipawaworks`
  },
  }
</script>
<style scoped>
.nav-pills .nav-link {
    color: #495057 !important;
}
.nav-pills .nav-link.active {
	color: #fff !important;
}
</style>
