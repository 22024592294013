<template>
    <form ref="clearForm" @submit.prevent="editPersonal">
        <div class="row mt-4">
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Education Level</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.level_id" class="form-control" >
                            <option value="">Select Education Level</option>
                            <option :value="edu.id" v-for="edu in education" :key="edu.id">{{ edu.name }}</option>
                        </select>
                        <div v-if="errors.level_id" class="text-small text-danger">{{ errors.level_id[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Facult Name</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.faculty" >
                        <div v-if="errors.faculty" class="text-small text-danger">{{ errors.faculty[0] }}</div>
                        
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Starting Date</label>
                    <div class="form-icon position-relative">
                        <input type="date" class="form-control" v-model="form.started_at" >
                        <div class="form-text">Tip: Choose the first day of month if you cant remmeber the exactly date</div>
                        <div v-if="errors.started_at" class="text-small text-danger">{{ errors.started_at[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Still Studing</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.studying" class="form-control" >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6" v-if="form.studying == 0">
                <div class="mb-3">
                    <label class="form-label">Graduation Date</label>
                    <div class="form-icon position-relative">
                        <input type="date" class="form-control" v-model="form.ended_at" >
                        <div class="form-text">Tip: Choose the first day of month if you cant remmeber the exactly date</div>
                        <div v-if="errors.ended_at" class="text-small text-danger">{{ errors.ended_at[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Country</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.country" class="form-control" >
                            <option value="">Select Institute Country</option>
                            <option :value="country.name" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                        </select>
                        <div v-if="errors.country" class="text-small text-danger">{{ errors.country[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">City</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.city" >
                        <div v-if="errors.city" class="text-small text-danger">{{ errors.city[0] }}</div>
                        
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Institute Name</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.name" >
                        <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                    </div>
                </div> 
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Division/GPA Scored (with points)</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.marks" >
                        <div v-if="errors.marks" class="text-small text-danger">{{ errors.marks[0] }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-4">
                <router-link :to="{ name: 'StepTarget', query: { redirect: $route.query.redirect  }}" class="btn btn-secondary">Previous</router-link>
            </div>
            <div class="col-4">
               <button class="btn btn-primary" disabled v-if="submitting">
                    <span class="label">{{ value }}</span>
                </button>
                <button class="btn btn-primary" v-else>
                    Next
                </button>
            </div>
        </div>
    </form>
</template>

<script>
export default {
props:['user'],
data(props){
      return {
        form: {
            started_at: '', ended_at: '', country: '', city : '', name: '',level_id: '',marks: '',description: '', studying:0
               
            },
        categories:{},
        countries:{},
        industries:{},
        token: localStorage.getItem('user_data'),
        errors: [],
        isLoading: true,
        submitting: false,
        userId:props.user,
      }
  },
   methods:{
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/education/createOrUpdate/' + this.userId, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'StepPersonalInfo', query: { redirect: this.$route.query.redirect }})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = errors.response.data.errors
            })
        },
        getEducation(){
        axios.get('/educationLevel').then(response => {
            this.education = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getStepEducation(){
            axios.get('/education/step/' + this.userId).then(response => {
                this.form = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
  },
  created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getCountries()
      this.getEducation()
      this.getStepEducation()
    }
}
</script>

<style>

</style>