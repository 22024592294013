<template>
  <form ref="clearForm" @submit.prevent="editPersonal">
        <div class="row mt-4">
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Gender</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.sex" id="" class="form-control ps-5">
                            <option value=" ">Select Your Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                        <div v-if="errors.sex" class="text-small text-danger">{{ errors.sex[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Martial Status</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.martial_status" id="" class="form-control ps-5">
                            <option value="">Select Your Status</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Unspecified">Unspecified</option>
                        </select>
                        <div v-if="errors.martial_status" class="text-small text-danger">{{ errors.martial_status[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Date Of Birth</label>
                    <div class="form-icon position-relative">
                        <input v-model="form.birth_date" type="date" class="form-control ps-5" >
                        <div v-if="errors.birth_date" class="text-small text-danger">{{ errors.birth_date[0] }}</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Nationality</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.nationality" class="form-control ps-5" @change="getCities()">
                            <option value="">Select Your Nationality</option>
                            <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                        </select>
                        <div v-if="errors.nationality" class="text-small text-danger">{{ errors.nationality[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">City</label>
                    <div class="form-icon position-relative">
                        <select class="form-control"  v-model="form.city_id">
                            <option value="">Select City</option>
                            <option :value="city.id" v-for="city in cities" :key="city.id">{{ city.name }}</option>
                        </select>
                        <div v-if="errors.city_id" class="text-small text-danger">{{ errors.city_id[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Visa</label>
                    <div class="form-icon position-relative">
                        <select v-model="form.visa" class="form-control ps-5" >
                            <option value="No Visa">No Visa</option>
                            <option value="Residency Visa(Non Transferble)">Residency Visa(Non Transferble)</option>
                            <option value="Residency Visa(Transferble)">Residency Visa(Transferble)</option>
                            <option value="Student Visa">Student Visa</option>
                            <option value="Transit Visa">Transit Visa</option>
                            <option value="Visit Visa">Visit Visa</option>
                        </select>
                    </div>
                    <div v-if="errors.visa" class="text-small text-danger">{{ errors.visa[0] }}</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Mobile No. :</label>
                    <div class="input-group position-relative">
                        <input v-model="form.mobile_phone" id="number" type="number" class="form-control ps-5">
                        <div v-if="errors.mobile_phone" class="text-small text-danger">{{ errors.mobile_phone[0] }}</div>
                    </div>
                </div>
            </div>
            <p class="text-muted">Why? "Employers like to reach out directly via phone."</p>

        </div>
        <div class="row justify-content-between">
            <div class="col-4">
           <router-link :to="{ name: 'FreelancerStepSpecialist'}" class="btn btn-secondary">Previous</router-link>
            </div>
            <div class="col-4">
                <input type="submit" id="submit" class="btn btn-primary" value="Complete">
            </div>
        </div>
    </form>
</template>

<script>
export default {
props:['user'],
  data(props){
      return {
        form: {
               sex:'',birth_date: '',nationality: '', city_id: '', visa: 'No Visa', martial_status: '',mobile_phone:'',
            },
        categories:{},
        countries:{},
        cities:{},
        industries:{},
        token: localStorage.getItem('user_data'),
        errors: [],
        userId:props.user,
      }
  },
   methods:{
      getPersonalInfo(){
          axios.get('/profile/step/' + this.userId).then(response => {
              this.form = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
        editPersonal(){
            axios.post('/profile/stepProfile', this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'FreelancerDashboard'})
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getCities(){
            axios.get('/cities/' + this.form.nationality).then(response => {
                this.cities = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getIndustries(){
        axios.get('/industries').then(response => {
            this.industries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      
  },
  created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getCountries()
      this.getIndustries()
      this.getCategories()
      this.getPersonalInfo()
    }
}
</script>

<style>

</style>