<template>
<NavEmployer :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-12 col-12" v-if="user_info.companies[0]">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                               <h3 class="text-center text-primary">All Users</h3>
                                <p class="text-center">This page is All Users that have access to this company</p>
                                <div class="alert alert-success" v-if="success">
                                    <p class="text-center">{{ success }}</p>
                                </div>
                                <div class="d-flex flex-row-reverse" v-if="user.roles[0].id == 1">
                                    <router-link class="btn btn-primary end-0" :to="{ name : 'AddCompanyUser' }">Add User</router-link>
                                </div>
                                <div >
                                    <CompanyUsers :currentUser="user" :user_info="user_info.companies[0]" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-lg-8 col-12">
                        <div class="alert alert-danger justify-content-center">
                            <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Company Not Registed</h4>
                            <p class="text-center">Get Started by Filling Up Your <router-link class="text-dark" :to="{ name : 'AddCompanyProfile' }">Company Info</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
  <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import CompanyUsers from '@/components/company/CompanyUsers.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { NavEmployer,Footer,Sidebar,Loading,CompanyUsers },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {roles:[0]},
          success: false,
          errors: false,
          applicant: {},
          job: {
              applicants: []
          },
          company_id: null,
          isLoading: true,
          user_info: { companies:[0], },
          token: localStorage.getItem('user_data'),
      }
  },
  methods: {
    getUser(){
    axios.get('/user').then(response => {
        this.currentUser = response.data
        this.user = this.currentUser.user
        if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        axios.get('/company/' + this.user.id).then(response => {
            this.user_info = response.data
        })
    }).catch(errors => {
         if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
    }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 2500);
    })
    }, 
  },
  mounted(){
     axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
      document.title = `Company Users - Vipawaworks`
  }
}
</script>

<style>

</style>