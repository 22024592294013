<template>
<NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div v-if="user_info && user_info.profile[0]" class="col-lg-12 col-12">
                        <div class="card border-0 rounded shadow-lg">
                            <div class="card-body">
                                <div class="row justify-content-center d-sm-flex">
                                    <div class="col-md-2">
                                        <div v-if="user_info.avatar">
                                            <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                        <div v-else>
                                            <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="alert alert-success" v-if="success">
                                    <h6 class="text-center">{{ success }}</h6>
                                </div>
                                <div class="alert alert-danger" v-if="errors[0]">
                                    <h6 class="text-center">{{ errors }}</h6>
                                </div>
                                <div class="row justify-content-evenly">
                                    <div class="col-md-2 mt-3 ">
                                        <button @click="$router.go(-1)" class=" btn btn-primary mt-2 border-0 bg-light" type="button" data-bs-toggle="collapse" >Return Back</button>
                                    </div>
                                    <div class="col-md-2 mt-3 ">
                                        <button class="btn btn-primary mt-2 border-0 bg-light" @click="saveCV" type="button" data-bs-toggle="collapse" >Save CV</button>
                                    </div>
                                    <div class="col-md-2 mt-3 ">
                                        <button class="btn btn-primary mt-2 border-0 bg-light" @click="downloadWithCSS" type="button" data-bs-toggle="collapse" >Download CV</button>
                                    </div>
                                </div>
                                <hr>
                                <div ref="content">
                                    <br>
                                     <div class="row justify-content-center">
                                         <div class="col-md-10">
                                             <div class="row justify-content-center d-sm-flex">
                                                <div v-if="user_info.avatar" class="col-md-4">
                                                    <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar  avatar-large shadow me-md-4" alt="">
                                                </div>
                                                <div v-else class="col-md-4">
                                                    <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-large shadow me-md-4" alt="">
                                                </div>
                                                <div class="col-md-8">
                                                    <h3 class="margin-bottom-0">{{ user_info.fname }} {{ user_info.sname }}  {{ user_info.lname }}</h3>
                                                    <div v-for="experience in user_info.experiences.slice(0, 1)" :key="experience.id">
                                                        <h6  class="margin-bottom-0">{{ experience.position }}</h6>
                                                        <h5 class="text-muted margin-bottom-0">{{ experience.company_name }}</h5>
                                                    </div>
                                                    <p class="text-muted margin-bottom-0"><span v-if="user_info.profile[0].city">{{ user_info.profile[0].city.name }},</span> {{ user_info.profile[0].country.name}}</p>
                                                    <div v-for="edu in user_info.education" :key="edu.id">
                                                        <p class="text-muted margin-bottom-0">{{ edu.faculty }}, {{ edu.name }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="shadow-sm p-3 bg-body rounded">
                                                <div class="row">
                                                    <div class="col-md-4"><br>
                                                        <h5>Basic Information</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <PersonalInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                                <br>
                                            </div>
                                            <div class="shadow-sm p-3 bg-body rounded">
                                                <div class="row" v-if="user_info.profile[0].private == 0">
                                                    <div class="col-md-4"><br>
                                                        <h5>Contact Information</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <BasicInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                                <div class="row" v-else>
                                                    <div class="col-md-4"><br>
                                                        <h5>Contact Information</h5>
                                                    </div>
                                                    <div class="col-md-8 alert alert-danger">
                                                        <h5 class="text-center">This Account is Private</h5>
                                                        <p class="text-center">This candidate Has limited people who can contact him directly, via mobile phone or email. But that wont stop you from reaching him out</p>
                                                        <div class="text-center" v-if="user">
                                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">Contact Candidate</button>
                                                        </div>
                                                        <p class="text-center text-dark">Tip:He/She may be a very compitent candidate</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="shadow-sm p-3 bg-body rounded">
                                                <div class="row">
                                                    <div class="col-md-4"><br>
                                                        <h5>Preferred Job</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <TargetJob :user_info="user_info" :salary="salary" :employmentTypes="employmentTypes" :editable="0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.education.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>Education Background</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <EducationInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.experiences.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4"><br>
                                                            <h5>Work Experience</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <ExperienceInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.specialists.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Specialists</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <SpecialistInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.skills.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Skills</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <SkillsInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.languages.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Languages</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <LanguagesInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.trainings.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Trainings</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <TrainingsInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.certificates.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                             <h5>Certifications</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <CertificatesInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.memberships.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Membership</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                             <MembershipInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.hobbies.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Hobbies</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <HobbiesInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.referees.length">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>Referees</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <RefereesInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-lg-12 col-12">
                        <div class="alert alert-danger justify-content-center">
                            <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Empty Resume</h4>
                            <p class="text-center">This Candidate has not filled up resume yet</p>
                            <div class="row justify-content-evenly">
                                <div class="col-md-2 mt-3 ">
                                    <button @click="$router.go(-1)" class=" btn btn-primary mt-2 border-0 bg-light" type="button" data-bs-toggle="collapse" >Return Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- modal for sending mail  -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="Close">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-center" id="exampleModalLabel">Email {{ user_info.fname }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="mailIncharge">
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">Message</label>
                                <textarea class="form-control" id="message-text" v-model="form.message"></textarea>
                                <div v-if="errors.message" class="text-small text-danger">{{ errors.message[0] }}</div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Send Email</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        <!-- end modal for sending mail  -->  
    </div>
    <div v-else>
       <Loading />
    </div>
  <Footer />
</template>

<script>
import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import domtoimage from 'dom-to-image';
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import ProgressSidebar from '@/components/company/ProgressSidebar.vue'
import PersonalInfo from '@/components/user/resume/PersonalInfo.vue'
import BasicInfo from '@/components/user/resume/BasicInfo.vue'
import EducationInfo from '@/components/user/resume/EducationInfo.vue'
import ExperienceInfo from '@/components/user/resume/ExperienceInfo.vue'
import SkillsInfo from '@/components/user/resume/SkillsInfo.vue'
import LanguagesInfo from '@/components/user/resume/LanguagesInfo.vue'
import SpecialistInfo from '@/components/user/resume/SpecialistInfo.vue'
import MembershipInfo from '@/components/user/resume/MembershipInfo.vue'
import TrainingsInfo from '@/components/user/resume/TrainingInfo.vue'
import CertificatesInfo from '@/components/user/resume/CertificatesInfo.vue'
import RefereesInfo from '@/components/user/resume/RefereesInfo.vue'
import HobbiesInfo from '@/components/user/resume/HobbiesInfo.vue'
import Loading from '@/views/custom/Loading.vue'
import TargetJob from '@/components/user/resume/TargetJob.vue';
export default {
  components: { 
      NavEmployer,Footer,ProgressSidebar, PersonalInfo, BasicInfo,EducationInfo,ExperienceInfo, SkillsInfo,
      LanguagesInfo,SpecialistInfo,MembershipInfo,TrainingsInfo,CertificatesInfo,RefereesInfo,HobbiesInfo,Loading,TargetJob
    },
    data(){
      return {
          drawer: true,
          currentUser: {},
          user: {},
          profile: {companies:[0]},
          form:{
            message:''
          },
          user_info: {
            profile: [0],preference_countries: [],preference_industries: [],candidate_jobs: [], education: [], experiences: [], skills: [], languages: [], specialists : [], memberships: [], trainings: []
            , certificates: [], hobbies: [], referees: []
            },
            salary: [],
            employmentTypes:[],
            job: '',
            isLoading: true,
          token: localStorage.getItem('user_data'),
          image: null,
          path: 'http://127.0.0.1:8000/',
          success: false,
          errors: false,
          imagePreview: null
      }
  },
    methods: {
        getProfile(){
            let id = this.$route.params.id
                axios.get('/profile/' + id).then(response => {
                    this.user_info = response.data
                    document.title =  this.user_info.fname + ' Resume - Vipawaworks'
                axios.get('/targetSalary/' + id).then(response => {
                    this.salary = response.data
                })
                axios.get('/employmentTypes/' + id).then(response => {
                    this.employmentTypes = response.data
                })
            }).catch(errors => {
            })
        },
        mailIncharge(){
          let id = this.$route.params.id
          axios.post('/application/mailCandidate/' + id, this.form).then(response => {
              this.$refs.Close.click()
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
            })
        },
        getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
                axios.get('/company/' + this.user.id).then(response => {
                    this.profile = response.data
                })
            }).catch(errors => {
                if (errors.response.status === 401) {
                    localStorage.removeItem('user_data')
                    this.$router.push({ name: 'Login'})
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
         downloadWithCSS() {
        domtoimage
        .toPng(this.$refs.content)
        .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        const doc = new jsPDF({
            orientation: "landscape",
            format: [900, 1400]
        });
        const imgProps= doc.getImageProperties(img);
            const pageHeight = 900
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            var heightLeft = pdfHeight
            var position = 0
            doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight

            while(heightLeft >= 0){
                position = heightLeft - pdfHeight;
                doc.addPage()
                doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight
            }

        const date = new Date();
        const filename =
            "resume" +
            date.getFullYear() +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            ("0" + date.getDate()).slice(-2) +
            ("0" + date.getHours()).slice(-2) +
            ("0" + date.getMinutes()).slice(-2) +
            ("0" + date.getSeconds()).slice(-2) +
            ".pdf";
        doc.save(filename);
        })
        .catch(function(error) {
        console.error("oops, something went wrong!", error);
        });
    },
    saveCV(){
        let id = this.$route.params.id
        axios.post('/application/saveResume/' + id).then(response => {
            this.success = response.data.success
            this.errors = false
        }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
        })
    },
  },
  created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.getProfile()    
  }
}
</script>

<style scoped>
.margin-bottom-0{
    margin-bottom: 0rem !important;
}

</style>