<template>
    <form ref="clearForm" @submit.prevent="editPersonal">
        <div class="row mt-4 justify-content-center">
            <div class="col-md-10 ">
                <div class="mb-3">
                    <label class="form-label">Specialist Name</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control ps-5" v-model="form.name" >
                        <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row justify-content-center">
            <div class="col-sm-6">
                <input type="submit" id="submit" class="btn btn-primary" value="Save Changes">
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props:['user'],
    data(props){
        return {
            form: {
               name: '',
            },
            token: localStorage.getItem('user_data'),
            errors: [],
            userId:props.user,
        }
    },
methods:{
    getSpecialistInfo(){
          axios.get('/specialists/step/show/' + this.userId).then(response => {
              this.form = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
    editPersonal(){
        axios.post('/specialists/step/' + this.userId, this.form).then(response => {
            this.$refs.clearForm.reset()
            this.$router.push({ name: 'FreelancerStepProfile'})
        }).catch((error) => {
            this.errors = error.response.data.errors
        })
    }
},
created(){
    this.getSpecialistInfo()
}
}
</script>

<style>

</style>