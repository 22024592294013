<template>
<Navbar />
    <section class="section mt-60">
        <div class="container mt-lg-3">
            <div v-if="!isLoading" class="card border-0 rounded shadow">
                <div class="card-body">
                    <ChooseRole :profile="user_info.companies[0]" :user="user" />
                </div>
            </div>
            <div v-else>
            <loading />
            </div>
                        
        </div> 
    </section>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import ChooseRole from '@/components/auth/ChooseRole.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
  components: {  Navbar, Footer,ChooseRole, Loading },
    data(){
      return {
          drawer: true,
          currentUser: {},
          user_info: {companies:[0]},
          user: {
              roles: [], 
          },
          isLoading: true,
          token: localStorage.getItem('user_data')
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
            if (this.user.roles[0].id == 1 || this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
        }).catch(errors => {
            if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
  },
  created(){
      document.title = `Choose Role - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()      
  }
}
</script>

<style>

</style>