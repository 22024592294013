<template>
<Navbar />    
    <section class="section pb-0">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="card border-0 text-center features feature-clean">
                        <div class="icons text-primary text-center mx-auto">
                            <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="content mt-3">
                            <h5 class="fw-bold">Phone</h5>
                            <p class="text-muted">Service Avalable in Working Hours</p>
                            <a href="tel:+255717443616" class="text-primary">+255 (717) 443616</a>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card border-0 text-center features feature-clean">
                        <div class="icons text-primary text-center mx-auto">
                            <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
                        </div>
                        <div class="content mt-3">
                            <h5 class="fw-bold">Email</h5>
                            <p class="text-muted">Get Instantly reply in Working hours</p>
                            <a href="mailto:info@vipawaworks.com" class="text-primary">info@vipawaworks.com</a>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card border-0 text-center features feature-clean">
                        <div class="icons text-primary text-center mx-auto">
                            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
                        </div><iframe aria-hidden="true" tabindex="-1" style="z-index: -1; position: absolute; width: 100%; height: 100%; top: 0px; left: 0px; border: medium none;" frameborder="0"></iframe>
                        <div class="content mt-3">
                            <h5 class="fw-bold">Location</h5>
                            <p class="text-muted">Chabruma Street, Ali Hassan Mwinyi Road, JANGID Plaza, 3rd Floor, Room no. 303, P.O. Box 32080 <br>Dar es Salaam, Tanzania</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                    <div class="card shadow rounded border-0">
                        <div class="alert alert-success" v-if="success">
                                <h6 class="text-center">{{ success }}</h6>
                            </div>
                        <div class="card-body py-5">
                            <h4 class="card-title">Get In Touch !</h4>
                            <div class="custom-form mt-3">
                                <form @submit.prevent="contactUs" ref="clearForm" name="myForm">
                                    <p id="error-msg" class="mb-0"></p>
                                    <div id="simple-msg"></div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                    <input v-model="form.name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                                                    <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                    <input v-model="form.email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                                                    <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
    
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label class="form-label">Subject</label>
                                                <div class="form-icon position-relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book fea icon-sm icons"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg>
                                                    <input v-model="form.subject" id="subject" class="form-control ps-5" placeholder="subject :">
                                                    <div v-if="errors.subject" class="text-small text-danger">{{ errors.subject[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label class="form-label">Comments <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle fea icon-sm icons clearfix"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                                                    <textarea v-model="form.message" id="comments" rows="4" class="form-control ps-5" placeholder="Message :"></textarea>
                                                    <div v-if="errors.message" class="text-small text-danger">{{ errors.message[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="d-grid">
                                                <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-6 order-1 order-md-2">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Chabruma%20Street,%20Ali%20Hassan%20Mwinyi%20Road,%20vipajijobs,%20Dar%20es%20Salaam,%20Tanzania&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    <a href="https://soap2day-to.com">soap2day</a>
                                    <br>
                                    <a href="https://www.embedgooglemap.net">google map on website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    <Footer />
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  name: 'Jobs',
  emits: ["paginate"],
  components: {
    Navbar,Footer,Loading,
  },
  data(){
      return {
        currentPage: 'Contact Us',
        previousPage: '',
        errors: [],
        success: false,
        form: {
            name: '',
            email: '',
            subject: '',
            message: ''
        }

      }
  },
  methods: {
        contactUs(){
            axios.post('/contact', this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'Home'})
                this.success = 'Thank You For Contacting Us, We will get In touch with you soon'
                this.errors = false
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        }
    },
    created(){
        document.title = `Contact - Vipawaworks`
    }
  }
</script>
<style scoped>
.nav-pills .nav-link {
    color: #495057 !important;
}
.nav-pills .nav-link.active {
	color: #fff !important;
}
.mapouter{
    position:relative;
    text-align:right;
    height:500px;
    width:600px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:500px;
    width:600px;
}
</style>
