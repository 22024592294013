<template>
  <Navbar />
  <div class="home" v-if="!isLoading">
    <section class="bg-auth-home d-table w-100">
          <div class="container">
            <h2 class="card-title text-center">Welcome to Vipawaworks, {{ user.fname }}</h2> 
              <p class="text-center">lets go Ahead and build up Your CV, It will only take 5 Minutes</p> 
              <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6">
                      <div class="card shadow rounded border-0">
                          <div class="card-body">
                            <p class="text-md-start text-center">Step 2 of 5</p>
                              <h5 class="text-md-start text-center">What is your current (or last) position?</h5>
                              <div v-if="user">
                                <ExperienceForm :user="user.id" />
                              </div>
                              
                          </div>
                          <a class="btn text-primary" @click="switchFresher">I have no Experience/Still Studying</a>
                      </div>
                  </div> 
                  <div class="col-lg-7 col-md-6">
                      <div class="me-lg-5">   
                          <img :src="require('@/assets/img/site/stageone.png')" class="img-fluid d-block mx-auto" alt="">
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
  <div class="home" v-else>
		<Loading />
	</div>
    	<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
import ExperienceForm from '@/components/user/steps/ExperienceForm.vue'
export default {
    components: {
   Navbar, Footer,Loading,ExperienceForm
  },
  data(){
      return {
        user: {roles: [0]},
        token: localStorage.getItem('user_data'),
        errors: [],
        isLoading: true,
      }
  },
   methods:{
     getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
      switchFresher(){
             axios.post('/role/changeToFresher').then(response => {
              this.success = response.data.success
              this.$router.push({ name: 'StepTarget', query: { redirect: this.$route.query.redirect }})
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
     },
  },
  created(){
      document.title = `Register Experience - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getUser()  

    }

}
</script>

<style>

</style>