<template>
  <section class="bg-profile d-table w-100 bg-primary">
      <div class="container" >
          <div class="row">
              <div class="col-lg-12">
                  <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
                      <div class="card-body">
                          <div class="row align-items-center" v-if="profile">
                                <div v-if="profile.logo" class="col-lg-2 col-md-3 text-md-start text-center">
                                <img v-bind:src="'https://api.vipawaworks.com/' + profile.logo" class="avatar float-md-left avatar-medium rounded shadow me-md-4" alt="">
                                </div>
                                <div v-else class="col-lg-2 col-md-3 text-md-start text-center">
                                    <img src="@/assets/img/svg/defaultCompany.svg" class="avatar avatar-large rounded shadow d-block mx-auto" alt="">
                                </div>
                              <div class="col-lg-10 col-md-9">
                                  <div class="row align-items-end">
                                      <div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                          <h3 class="title mb-0" v-if="profile">{{ profile.name }} 
                                              <span class="text-primary" v-if="profile.verified == 1">
                                                    <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                                </span>
                                          </h3>
                                          <h6><span class="text-primary">{{ user.fname }} {{ user.sname }} {{ user.lname }}</span></h6>
                                      </div>
                                  </div>
                              </div>
                          </div>
                           <div class="row align-items-center" v-else>
                              <div  class="col-lg-2 col-md-3 text-md-start text-center">
                                  <img src="@/assets/img/svg/defaultCompany.svg" class="avatar avatar-large rounded shadow d-block mx-auto" alt="">
                              </div>

                              <div class="col-lg-10 col-md-9">
                                  <div class="row align-items-end">
                                      <div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                          <h3 class="title mb-0" v-if="profile">{{ profile.name }} 
                                              <span class="text-primary" v-if="profile.verified == 1">
                                                    <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                                </span>
                                          </h3>
                                          <h6><span class="text-primary">{{ user.fname }} {{ user.lname }}</span></h6>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>

export default {
    props: ['user','profile'],
}
</script>

<style>

</style>