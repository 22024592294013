<template>
<NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div v-if="user_info.profile[0]" class="col-lg-12 col-12">
                        <div class="card border-0 rounded shadow-lg">
                            <div class="card-body">

                                <div class="row justify-content-center d-sm-flex">
                                    <div v-if="user_info.avatar" class="col-md-2">
                                        <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar float-md-left avatar-medium rounded shadow me-md-4" alt="">
                                    </div>
                                    <div v-else class="col-md-2">
                                        <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar float-md-left avatar-medium rounded shadow me-md-4" alt="">
                                    </div>
                                </div>
                                <div class="row justify-content-evenly">
                                    <div class="col-md-2 mt-3 ">
                                        <button @click="$router.go(-1)" class=" btn btn-primary mt-2 border-0 bg-light" type="button" data-bs-toggle="collapse" >Return Back</button>
                                    </div>
                                    <div class="col-md-2 mt-3 ">
                                        <button class="btn btn-primary mt-2 border-0 bg-light" @click="downloadWithCSS" type="button" data-bs-toggle="collapse" >Download CV</button>
                                    </div>
                                </div>
                                <hr>
                                 <div ref="content">
                                     <br>
                                     <div class="row justify-content-center">
                                         <div class="col-md-10">
                                             <div class="row justify-content-center d-sm-flex">
                                                <div v-if="user_info.avatar" class="col-md-4">
                                                    <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar avatar-large shadow rounded me-md-4" alt="">
                                                </div>
                                                <div v-else class="col-md-4">
                                                    <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-large shadow rounded me-md-4" alt="">
                                                </div>
                                                <div class="col-md-8">
                                                    <h3 class="margin-bottom-0">{{ user.fname }} {{ user.sname }}  {{ user.lname }}</h3>
                                                    <div v-for="experience in user_info.experiences.slice(0, 1)" :key="experience.id">
                                                        <h6 class="margin-bottom-0">{{ experience.position }}</h6>
                                                        <h5 class="text-muted margin-bottom-0">{{ experience.company_name }}</h5>
                                                    </div>
                                                    <p class="text-muted margin-bottom-0"><span v-if="user_info.profile[0].city">{{ user_info.profile[0].city.name }},</span> <span v-if="user_info.profile[0].name"> {{ user_info.profile[0].country.name}}</span></p>
                                                    <div v-for="edu in user_info.education" :key="edu.id">
                                                        <p class="text-muted margin-bottom-0">{{ edu.faculty }}, {{ edu.name }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="shadow-sm p-3 bg-body rounded">
                                                <div class="row">
                                                    <div class="col-md-4"><br>
                                                        <h5>Basic Information</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <PersonalInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                                <br>
                                            </div>
                                            <div class="shadow-sm p-3 bg-body rounded">
                                                <div class="row" v-if="user_info.profile[0].private == 0">
                                                    <div class="col-md-4"><br>
                                                        <h5>Contact Information</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <BasicInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                                <div class="row" v-else>
                                                    <div class="col-md-4"><br>
                                                        <h5>Contact Information</h5>
                                                    </div>
                                                    <div class="col-md-8 alert alert-danger">
                                                        <h5 class="text-center">This Account is Private</h5>
                                                        <p class="text-center">This candidate Has limited people who can contact him directly, via mobile phone or email. But that wont stop you from reaching him out</p>
                                                        <div class="text-center">
                                                            <button class="btn btn-primary">Contact Him</button>
                                                        </div>
                                                        <p class="text-center text-dark">Tip:He/She may be a compitent candidate!</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="shadow-sm p-3 bg-body rounded">
                                                <div class="row">
                                                    <div class="col-md-4"><br>
                                                        <h5>Preferred Job</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <TargetJob :user_info="user_info" :salary="salary" :employmentTypes="employmentTypes" :editable="0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.education.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>Education Background</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <EducationInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.experiences.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4"><br>
                                                            <h5>Work Experience</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <ExperienceInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.specialists.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Specialists</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <SpecialistInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.skills.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Skills</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <SkillsInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.languages.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Languages</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <LanguagesInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.trainings.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Trainings</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <TrainingsInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.certificates.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                             <h5>Certifications</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <CertificatesInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.memberships.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Membership</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                             <MembershipInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.hobbies.length">
                                                <div class="shadow-sm p-3 bg-body rounded">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <h5>Hobbies</h5>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <HobbiesInfo :user_info="user_info" :editable="0"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="user_info.referees.length">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h5>Referees</h5>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <RefereesInfo :user_info="user_info" :editable="0"/>
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                     </div>
                                </div>   
                                
                                    
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-lg-8 col-12">
                        <div class="alert alert-danger justify-content-center">
                            <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Empty Resume</h4>
                            <p class="text-center">Get Started by Filling Up Your <router-link :to="{ name : 'Profile' }">Profile</router-link> </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
     <div v-else>
       <Loading />
   </div>
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import domtoimage from 'dom-to-image';
import NavSeeker from '@/components/user/NavSeeker.vue'
import PersonalInfo from '@/components/user/resume/PersonalInfo.vue'
import BasicInfo from '@/components/user/resume/BasicInfo.vue'
import EducationInfo from '@/components/user/resume/EducationInfo.vue'
import ExperienceInfo from '@/components/user/resume/ExperienceInfo.vue'
import SkillsInfo from '@/components/user/resume/SkillsInfo.vue'
import LanguagesInfo from '@/components/user/resume/LanguagesInfo.vue'
import SpecialistInfo from '@/components/user/resume/SpecialistInfo.vue'
import MembershipInfo from '@/components/user/resume/MembershipInfo.vue'
import TrainingsInfo from '@/components/user/resume/TrainingInfo.vue'
import CertificatesInfo from '@/components/user/resume/CertificatesInfo.vue'
import RefereesInfo from '@/components/user/resume/RefereesInfo.vue'
import HobbiesInfo from '@/components/user/resume/HobbiesInfo.vue'
import Loading from '../custom/Loading.vue'
import TargetJob from '@/components/user/resume/TargetJob.vue';
export default {
  components: { 
      Footer,NavSeeker, PersonalInfo, BasicInfo,EducationInfo,ExperienceInfo, SkillsInfo,
      LanguagesInfo,SpecialistInfo,MembershipInfo,TrainingsInfo,CertificatesInfo,RefereesInfo,HobbiesInfo,
    Loading,TargetJob,
    },
    data(){
      return {
        drawer: true,
        currentUser: {},
        user: {roles: [0]},
        user_info: {
        profile: [0],preference_countries: [],preference_industries: [],candidate_jobs: [], education: [], experiences: [], skills: [], languages: [], specialists : [], memberships: [], trainings: []
        , certificates: [], hobbies: [], referees: [], roles: [0]
        },
        salary: [],
        employmentTypes:[],
        job: '',
        isLoading: true,
        token: localStorage.getItem('user_data'),
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }
            axios.get('/profile/' + this.user.id).then(response => {
                this.user_info = response.data
            })
            axios.get('/targetSalary/' + this.user.id).then(response => {
                this.salary = response.data
            })
            axios.get('/employmentTypes/' + this.user.id).then(response => {
                this.employmentTypes = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
            document.title =  this.user.fname + 'Resume - Vipawaworks'
        })
      },
    downloadWithCSS() {
        domtoimage
        .toPng(this.$refs.content)
        .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        const doc = new jsPDF({
            orientation: "landscape",
            format: [900, 1400]
        });
        const imgProps= doc.getImageProperties(img);
            const pageHeight = 900
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            var heightLeft = pdfHeight
            var position = 0
            doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight

            while(heightLeft >= 0){
                position = heightLeft - pdfHeight;
                doc.addPage()
                doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight
            }

        const date = new Date();
        const filename =
            "resume" +
            date.getFullYear() +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            ("0" + date.getDate()).slice(-2) +
            ("0" + date.getHours()).slice(-2) +
            ("0" + date.getMinutes()).slice(-2) +
            ("0" + date.getSeconds()).slice(-2) +
            ".pdf";
        doc.save(filename);
        })
        .catch(function(error) {
        console.error("oops, something went wrong!", error);
        });
    },
    
  },
  created(){
      
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser() 
  }
}
</script>

<style>

</style>