<template>
    <NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Personal Information</h5>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Company Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.name"  >
                                                    <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Short Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.short_name" >
                                                    <div v-if="errors.short_name" class="text-small text-danger">{{ errors.short_name[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Year Founded</label>
                                                <div class="form-icon position-relative">
                                                    <input type="year" class="form-control" v-model="form.founded_at" >
                                                    <div v-if="errors.founded_at" class="text-small text-danger">{{ errors.founded_at[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label>Choose Company Size</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.size">
                                                        <option value="">Choose Company Size</option>
                                                        <option value="1-9 Employees">1-9 Employees</option>
                                                        <option value="10-49 Employees">10-49 Employees</option>
                                                        <option value="50-99 Employees">50-99 Employees</option>
                                                        <option value="100-499 Employees">100-499 Employees</option>
                                                        <option value="500 or More Employees">500 or More Employees</option>
                                                    </select>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Vision</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.vision" >
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Mission</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.mission" >
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="form-label">Company Description</label>
                                                <div class="form-icon position-relative">
                                                    <textarea class="form-control" cols="30" v-model="form.description"></textarea>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
    </div>
    <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { NavEmployer,Footer,Loading, },
    data(){
        return {
            values: [],
            form: {
              name: '', short_name: '', vision: '', mission: '', founded_at : '', description: '',size:''
            },
            user: {}, 
            isLoading: true, 
            submitting: false, 
            user_info: {},
            token: localStorage.getItem('user_data'),
            errors: []
        }
    },
    created(){
        document.title = `Edit Company Info - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
       this.getUser()
       this.getCompany()
       
    },
    methods: {
        getUser(){
             axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
           if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyProfile'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
        getCompany(){
             let id = this.$route.params.id
        axios.get('/company/show/' + id).then(response => {
            this.form = response.data
        })
        .catch(console.log('error'))
        },
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            let id = this.$route.params.id
            axios.post('/company/edit/' + id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CompanyProfile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
    }
    

}
</script>

<style>

</style>