<template>
<NavEmployer :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 d-lg-block " v-if="user_info.companies[0]">
                        <Sidebar :progress="user_info.companies[0]" v-if="user_info.companies[0]"/>
                    </div>

                    <div class="col-lg-8 col-12">
                        <div class="rounded shadow p-4">
                            <div  v-if="user_info.companies[0]">
                                <FreelancerDetails :company="user_info.companies[0]"/>
                            </div>
                                
                           
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
    </div>
  <Footer />
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'
import Profile from '@/components/company/Profile.vue'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import CompanyDetails from '@/components/company/CompanyDetails.vue'
import Loading from '@/views/custom/Loading.vue'
import FreelancerDetails from '@/components/company/FreelancerDetails.vue'
export default {
  components: { SideMenu, Profile, NavEmployer,Footer, CompanyDetails,Loading,FreelancerDetails, },
  data(){
      return {
        drawer: true,
        currentUser: {},
        user: {},
        candidates: {},
        allCandidates:{},
        filter: false,
        user_info: { companies:[0], roles: {}},
        token: localStorage.getItem('user_data'),
        isLoading: true,
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
     
  },
  
  created(){
      document.title = `offer Details - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>
.item + .item:before {
  content: ", ";
}

</style>