  <template>
  <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Work Experience</h5>
                                <div class="alert alert-danger" v-if="errors[0]">
                                    <h6 class="text-center">{{ errors }}</h6>
                                </div>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Starting Date</label>
                                                <div class="form-icon position-relative">
                                                    <input type="date" class="form-control" v-model="form.started_at" >
                                                    <div class="form-text">Tip: Choose the first day of month if you cant remember the exactly date</div>
                                                    <div v-if="errors.started_at" class="text-small text-danger">{{ errors.started_at[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Still Working</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.working" class="form-control" >
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6" v-if="form.working == 0">
                                            <div class="mb-3">
                                                <label class="form-label">Ending Date</label>
                                                <div class="form-icon position-relative">
                                                    <input type="date" class="form-control"  v-model="form.ended_at" >
                                                    <div class="form-text">Tip: Choose the first day of month if you cant remember the exactly date</div>
                                                    <div v-if="errors.ended_at" class="text-small text-danger">{{ errors.ended_at[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Country</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.country" class="form-control" >
                                                        <option value="">Select Your Country</option>
                                                        <option :value="country.name" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                    </select>
                                                    <div v-if="errors.country" class="text-small text-danger">{{ errors.country[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">City</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.city" >
                                                    <div v-if="errors.city" class="text-small text-danger">{{ errors.city[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Company Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.company_name" >
                                                    <div v-if="errors.company_name" class="text-small text-danger">{{ errors.company_name[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Company Size</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.size_id" class="form-control" >
                                                        <option value="">Select Company Size</option>
                                                        <option value="1">1-9 Employees</option>
                                                        <option value="2">10-49 Employees</option>
                                                        <option value="3">50-99 Employees</option>
                                                        <option value="4">100-499 Employees</option>
                                                        <option value="5">500 Employees or More</option>
                                                    </select>
                                                    <div v-if="errors.size_id" class="text-small text-danger">{{ errors.size_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Company industry</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.industry_id" class="form-control" >
                                                        <option value="">Select Company industry</option>
                                                        <option :value="industry.id" v-for="industry in industries" :key="industry.id">{{ industry.name }}</option>
                                                    </select>
                                                    <div v-if="errors.industry_id" class="text-small text-danger">{{ errors.industry_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Job Category</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.category_id" class="form-control" >
                                                        <option value="">Select Job Category</option>
                                                        <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                                                    </select>
                                                    <div v-if="errors.category_id" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Position in Company</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.position" >
                                                    <div v-if="errors.position" class="text-small text-danger">{{ errors.position[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label class="form-label">Description</label>
                                                <div class="form-icon position-relative">
                                                    <textarea v-model="form.description" name="comments" id="comments" rows="4" class="form-control"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                   <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
  <div v-else>
    <Loading />
   </div>
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import Loading from '@/views/custom/Loading.vue'
import qs from 'qs'
export default {
    components: { Footer,NavSeeker,Loading },
    data(){
        return {
            values: [],
            form: {
               started_at: '', ended_at: '', working:0, country: '', city : '', company_name: '',size_id: '',
                industry_id: '',category_id: '',position: '',description: '',
            },
            categories:{},
            countries:{},
            industries:{},
            user: {roles: [0]},
            token: localStorage.getItem('user_data'),
            errors: [],
            isLoading: true,
            submitting: false,
        }
    },
    methods: {
        getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/experience/add/' + this.user.id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'MyResume'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getIndustries(){
        axios.get('/industries').then(response => {
            this.industries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
    },
    created(){
        document.title = `Add Experience - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()  
        this.getCountries()
        this.getIndustries()
        this.getCategories()
    }   

}
</script>

<style>

</style>