import { computed, ref } from'vue'

const getCandidates = () => {
    const search = ref('')
    const candidates = ref([])
    const error = ref(null)

    const load = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/candidates')
            if (!data.ok){
                throw Error('no data available')
            }
            candidates.value = await data.json()
        }
        catch (err){
            error.value = err.message
        }
    }
    const matchingCandidates = computed(() => {
        return candidates.value.filter(function(jobName){
            return jobName.fname.toLowerCase().indexOf(search.value.toLowerCase()) !== -1 
        })
    })
    return { candidates, error, search, matchingCandidates, load }
  }

  export default getCandidates

