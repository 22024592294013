<template>


  <div class="testimonial">
    <div class=" mt-100 mt-60">
      <div class="row">
        <h2 class="text-center text-primary">WHAT CLIENTS SAY </h2>

        <div class="col-md-2">
        </div>
        <div class="col-md-8 ">
          <Feedback/>
        </div>
        <!--                <div class="col-md-6">-->
        <!--                    <Feedback/>-->
        <!--                </div>-->

      </div>
    </div>
  </div>

    <div class="alertJob">
        <div class=" mt-100 mt-60">
            <div class="row">
              <div class="col-md-2">
              </div>
                <div class="col-md-8 ">
                    <div class="section-title mt-4">
                        <h3 class="text-white">Set Job Alert</h3>
                        <h6 class="text-white">Want To be the First to know New Job Post?</h6>
                        <p class="text-white">Set Job Alert, when hiring company Post a job <br> through <span class="text-success fw-bold">Vipawaworks,</span> We will notify you Right a way</p>
                        <router-link :to="{ name : 'JobAlert' }" class="btn btn-outline-light mt-4">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
                            Set Job Alert
                        </router-link>
                    </div>
                </div>
<!--                <div class="col-md-6">-->
<!--                    <Feedback/>-->
<!--                </div>-->

            </div>
        </div>
    </div>

</template>

<script>
import getCompanies from '@/composable/getFrontCompanies'
import Feedback from '@/components/Feedback.vue'
import Testimonial from '@/components/Testimonials.vue'

export default {
    components:{ Feedback,Testimonial
    },
    setup(){
      const { companies, search, matchingCompanies, error, loadCompanies } = getCompanies()
      loadCompanies()
      return { companies, matchingCompanies, search, loadCompanies, error }
  },
}


</script>

<style scoped>
.testimonial {
    //background-image: url(../assets/img/background/testimonial.jpg);
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center right;
  height: auto;

}.alertJob {
    background-image: url(../assets/img/jobAlert.png);
    background-color: #156394;
    background-repeat: no-repeat;
    background-position: center right;
    height: auto;
    line-height: 1.2;
    font-size: 1rem;
    text-align: left;
}
.card {
    margin: 0 auto;
    border: none;
}
.card .carousel-item {
    min-height: 190px;
}
.card .carousel-caption {
    padding: 0;
    right: 15px;
    left: 15px;
    top: 15px;
    color: #3d3d3d;
    border: 1px solid #ccc;
    min-height:175px;
    padding: 15px;
}
.card .carousel-caption .col-sm-3 {
    display: flex;
    align-items: center;
}
.card .carousel-caption .col-sm-9 {
    text-align: left;
}
.card .carousel-control-prev, .card .carousel-control-next {
    color: #3d3d3d !important;
    opacity: 1 !important;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    background-image: none;
    color: #fff;
    font-size: 14px;
    background-color: #cd3a54;
    height: 32px;
    line-height: 32px;
    width: 32px;
}
.carousel-control-prev-icon:hover, .carousel-control-next-icon:hover {
    opacity: 0.85;
}
.carousel-control-prev {
    left: 40%;
    top: 110%;
}
.carousel-control-next {
    right: 40%;
    top: 110%;
}
.midline {
    width: 60px;
    border-top: 1px solid #d43025;
}
.carousel-caption h2 {
    font-size: 14px;
}
.carousel-caption h2 span {
    color: #cd3a54;
}
@media (min-width: 320px) and (max-width: 575px) {
    .carousel-caption {
        position: relative;
    }
    .card .carousel-caption {
        left: 0;
        top: 0;
        margin-bottom: 15px;
    }
    .card .carousel-caption img {
        margin: 0 auto;
    }
    .carousel-control-prev {
        left: 35%;
        top: 105%;
    }
    .carousel-control-next {
        right: 35%;
        top: 105%;
    }
    .card .carousel-caption h3 {
        margin-top: 0;
        font-size: 16px;
        font-weight: 700;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .carousel-caption {
        position: relative;
    }
    .card .carousel-caption {
        left: 0;
        top: 0;
        margin-bottom: 15px;
    }
    .card .carousel-caption img {
        margin: 0 auto;
    }
    .card .carousel-caption h3, .card .carousel-caption small {
        text-align: center;
    }
    .carousel-control-prev {
        left: 35%;
        top: 105%;
    }
    .carousel-control-next {
        right: 35%;
        top: 105%;
    }
}
@media (min-width: 767px) and (max-width: 991px) {
    .card .carousel-caption h3 {
        margin-top: 0;
        font-size: 16px;
        font-weight: 700;
    }
}

</style>
