<template>
    <div class="row">
        <div v-for="job in matchingJobs" :key="job.id" class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 border-light mb-3">
            <SingleJob :job="job" />
        </div>
    </div>    
</template>

<script>
import { onMounted } from 'vue'
import SingleJob from './SingleJob.vue'
export default {
  components: { SingleJob },
    props: ['matchingJobs']
    

}
</script>

<style>

</style>