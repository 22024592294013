<template>
    <section v-if="blogs.length">
        <div class="vc_row wpb_row vc_row-fluid vc_custom_1527071317799">
            <div class="container">
                <br><br>
                <h2 class="text-center text-primary">JOB MARKET INSIGHTS</h2>
                <h6 class="text-muted text-center">We'll always match you up with opportunities that are the right fit</h6>
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 mt-4 mb-4 pt-2" v-for="blog in blogs" :key="blog.id">
                        <div class="card blog rounded border-0 shadow overflow-hidden">
                            <div class="position-relative">
                                <router-link :to="{ name: 'BlogDetails', params: {id: blog.id}}">
                                    <img :src="'https://adminora.vipawaworks.com/images/blogs/' + blog.image" class="card-img-top">
                                </router-link>
                                <div class="overlay rounded-top bg-dark"></div>
                            </div>
                            <div class="card-body content">
                                <h5><router-link :to="{ name: 'BlogDetails', params: {id: blog.id}}" class="card-title title text-dark">{{ blog.name && blog.name.length < 40 ? blog.name : blog.name.substring(0,37)+".." }}</router-link></h5>
                                <div class="post-meta d-flex justify-content-between mt-3">

                                    <router-link :to="{ name: 'BlogDetails', params: {id: blog.id}}" class="text-muted readmore">Read More
                                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import getFrontBlogs from '@/composable/getFrontBlogs'
export default {
  setup(){
      const { blogs, error, loadFrontBlogs } = getFrontBlogs()
      loadFrontBlogs()

      return { blogs, error, loadFrontBlogs }
  }
}
</script>
<style scoped>
.card-img-top{
    height: 176px;
}
</style>
