<template>
   <Navbar />
    <section class="bg-auth-home bg-circle-gradiant d-table w-100">
            <div class="bg-overlay bg-overlay-white"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-8"> 
                        <div class="card shadow rounded border-0 mt-4">
                            <div class="card-body">
                            <h4 class="card-title text-center">Login</h4>  
                                <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </section>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import LoginForm from '@/components/auth/LoginForm.vue'
export default {
  components: { Navbar, Footer,LoginForm },
created(){
    document.title = `Login - Vipawaworks`
}
}
</script>

<style>

</style>