<template>
<Navbar /> 
    <section class="bg-home bg-light d-flex align-items-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="text-center">
                        <div class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto" style="height: 90px; width:90px;">
                            <img src="@/assets/img/svg/banned.svg" alt="" height="200">
                        </div>
                        <br>
                        <h1 class="my-4 fw-bold">You are Banned</h1>
                        <p class="text-muted para-desc mx-auto">You are banned from using our website, this happened because you were involved in activities that are against our terms and conditions.</p>
                        <br>
                        <p class="text-muted para-desc mx-auto">For more information and help Please <router-link :to="{ name : 'Contacts' }" class="text-primary">Contact Us</router-link></p>
                        <router-link :to="{ name : 'Home' }" class="btn btn-primary mt-3">Return Home</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer /> 
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
components: {
    Navbar,Footer
  },
  created(){
      document.title = `Banned - Vipawaworks`
  }
}
</script>

<style>

</style>