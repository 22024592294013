<template>
<FreelancerNav :user="user"/>
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-12">
                        <div class="card border-0 rounded">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="card-body">
                                        <h5 class="text-center">Skills</h5>
                                        <SkillsList v-if="user" :user_info="user_info" />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="card-body">
                                        <h5 class="text-center">Languages</h5>
                                        <LanguagesList v-if="user" :user_info="user_info" />
                                    </div>
                                </div>         
                            </div>
                                                   
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
    </div>
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'
import SkillsList from '@/components/freelancer/SkillsList.vue'
import LanguagesList from '@/components/freelancer/LanguagesList.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { Footer,FreelancerNav,SkillsList,LanguagesList,Loading },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {},
          user_info: { skills: [], languages: []},
          token: localStorage.getItem('user_data'),
          isLoading: true,
      }
  },
  methods: {
      getUser(){
        axios.get('user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
            axios.get('/skills/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
  },
  created(){
      document.title = `Skills and Languages - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()      
  }
}
</script>

<style>

</style>