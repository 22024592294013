<template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <JobTarget :jobs="jobs" />
                        <TargetJobInfo :salary="salary" />
                        <PreferrenceInfo :preferences="preferences" :employmentTypes="employmentTypes" />
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
   </div>
    	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import JobTarget from '@/components/user/resume/JobTarget.vue'
import TargetJobInfo from '@/components/user/resume/TargetJobInfo.vue'
import PreferrenceInfo from '@/components/user/resume/PreferrenceInfo.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
  components: { Footer,NavSeeker,JobTarget,TargetJobInfo,PreferrenceInfo,Loading, },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {roles: [0]},
          jobs: [],
          salary: [],
          preferences: {
              preference_countries: [],
              preference_industries: []
          },
          employmentTypes: [],
          token: localStorage.getItem('user_data'),
          isLoading: true,
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/targetJob/' + this.user.id).then(response => {
                this.jobs = response.data
            })
            axios.get('/targetSalary/' + this.user.id).then(response => {
                this.salary = response.data
            })
            axios.get('/preferences/' + this.user.id).then(response => {
                this.preferences = response.data
            })
            axios.get('/employmentTypes/' + this.user.id).then(response => {
                this.employmentTypes = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
  },
  created(){
      document.title = `Preference Job - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>

</style>