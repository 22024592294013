<template>

    <div id="feedback-main">
        <div id="feedback-div">
            <form action="contact.php" method="post" class="form" id="feedback-form1" name="form1" enctype="multipart/form-data">

                <p class="name">
                    <input name="name" type="name" class="validate[required,custom[onlyLetter],length[0,100]] feedback-input" required placeholder="Name" id="feedback-name" />
                </p>

                <p class="email">
                    <input name="email" type="email"       class="validate[required,custom[email]] feedback-input" id="feedback-email" placeholder="Email" required />
                </p>

                <p class="text">
                    <textarea name="comment" type="comment" class="validate[required,length[6,300]] feedback-input" id="feedback-comment" required placeholder="Hey, I really love the site but I believe that you could incorporate some ..... and also get rid of the ...... on the section."></textarea>
                </p>

                <div class="feedback-submit">
                    <input type="submit" value="SEND" id="feedback-button-blue"/>
                    <div class="feedback-ease"></div>
                </div>
            </form>
        </div>
    </div>
    <button id="popup" class="feedback-button" >Feedback</button>
</template>
<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        components: {
            Carousel,
            Slide
        },
        data(){
            return {
                allFeedbacks: [],
            }
        },
        methods: {
            getFeedbacks(){
                axios.get('https://api.vipawaworks.com/api/all_feedbacks').then(response => {
                    console.log(response.data)
                    this.allFeedbacks = response.data
                }).catch(errors => {
                    this.CountryErrors = error.response.data.errors
                })
            },
        },
        computed: {
        },
        mounted () {
            this.getFeedbacks()
        },

    }
</script>
<style scoped>

    .feedback-button {height:40px; border:solid 3px #CCCCCC; background:#333; width:100px; line-height:32px; -webkit-transform:rotate(-90deg); font-weight:600; color:white; transform:rotate(-90deg);  -ms-transform:rotate(-90deg);  -moz-transform:rotate(-90deg); text-align:center; font-size:17px; position:fixed; right:-40px; top:45%; font-family: "Roboto", helvetica, arial, sans-serif; z-index:999; }

    #feedback-main{
        display:none;
        float:left;
        padding-top:0px;
    }

    #feedback-div {
        background-color:rgba(72,72,72,0.4);
        padding-left:35px;
        padding-right:35px;
        padding-top:35px;
        padding-bottom:50px;
        width: 450px;
        float: left;
        left: 50%;
        position: absolute;
        margin-top:30px;
        margin-left: -260px;
        -moz-border-radius: 7px;
        -webkit-border-radius: 7px;
    }

    .feedback-input {
        color:#3c3c3c;
        font-family: "Roboto", helvetica, arial, sans-serif;
        font-weight:500;
        font-size: 18px;
        border-radius: 0;
        line-height: 22px;
        background-color: #fbfbfb;
        padding: 13px 13px 13px 54px;
        margin-bottom: 10px;
        width:100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        border: 3px solid rgba(0,0,0,0);
    }

    .feedback-input:focus{
        background: #fff;
        border: 3px solid #3498db;
        color: #3498db;
        outline: none;
        padding: 13px 13px 13px 54px;
    }

    /* Icons ---------------------------------- */
    #feedback-name{
        background-size: 30px 30px;
        background-position: 11px 8px;
        background-repeat: no-repeat;
    }

    #feedback-name:focus{
        background-size: 30px 30px;
        background-position: 8px 5px;
        background-position: 11px 8px;
        background-repeat: no-repeat;
    }

    #feedback-email{
        background-size: 30px 30px;
        background-position: 11px 8px;
        background-repeat: no-repeat;
    }

    #feedback-email:focus{
        background-size: 30px 30px;
        background-position: 11px 8px;
        background-repeat: no-repeat;
    }

    #feedback-comment{
        background-size: 30px 30px;
        background-position: 11px 8px;
        background-repeat: no-repeat;
    }

    #feedback-comment {
        width: 100%;
        height: 150px;
        line-height: 150%;
        resize:vertical;
    }

    input:hover, #feedback-comment:hover, input:focus, #feedback-comment:focus {
        background-color:white;
    }

    #feedback-button-blue{
        font-family: "Roboto", helvetica, arial, sans-serif;
        float:left;
        width: 100%;
        border: #fbfbfb solid 4px;
        cursor:pointer;
        background-color: #3498db;
        color:white;
        font-size:24px;
        padding-top:22px;
        padding-bottom:22px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        margin-top:-4px;
        font-weight:700;
    }

    #feedback-button-blue:hover{
        background-color: rgba(0,0,0,0);
        color: #0493bd;
    }

    .feedback-button-blue:hover {
        color: #3498db;
    }

    .feedback-ease {
        width: 0px;
        height: 74px;
        background-color: #fbfbfb;
        -webkit-transition: .3s ease;
        -moz-transition: .3s ease;
        -o-transition: .3s ease;
        -ms-transition: .3s ease;
        transition: .3s ease;
    }

    .feedback-submit:hover .feedback-ease{
        width:100%;
        background-color:white;
    }

    @media only screen and (max-width: 580px) {
        #feedback-div{
            left: 3%;
            margin-right: 3%;
            width: 88%;
            margin-left: 0;
            padding-left: 3%;
            padding-right: 3%;
        }
    }

</style>
