<template>
<Navbar />
  <section class="section">
    <div class="container" v-if="!isLoading">
        <div class=" justify-content-center">
            <img src="@/assets/img/svg/welldone.svg" height="200" class=" rounded mx-auto d-block">
            <h3 class="text-center">WellDone</h3>
            <p class="text-center">Thank You For Applying Job Through Vipawaworks</p>
            <p class="text-center">We would like to inform you that we have submitted your Application to the hiring Company, the hiring company is currently reviewing all applications,
              if you are among qualified candidate we will contact you and schedule for an interview.
            </p>
            <p class="text-center">In any case you were contacted you then unfortunately your application was not successful. However, keep on following our social media accounts for all updates and goodluck.</p>
            <p class="text-center">
              To compete for employment market we emphasize to try out <router-link :to="{ name : 'SelfTests' }">our tests </router-link> for your field, this tests will help increase your knowledge and prepares you for interviews and compitence in employemt market.
              For more knowledge you can always visit <a href="http://training.vipawaworks.com" class="text-primary ms-1 me-1" target="_blank">Our Training Website</a>. Having completed our course will guarantee up to 80% chance to compete for employment market.
            </p>
            <div class="col-md-12 text-center">  
                <router-link class="btn btn-success mt-4" :to="{ name: 'Jobs'}">Browse More Jobs</router-link>
                <router-link class="btn btn-primary mt-4 ms-2" :to="{ name: 'Home'}">Go To Home</router-link>
            </div>
        </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </section>
  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import SingleJob from '@/components/SingleJob.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  name: 'Jobs',
  emits: ["paginate"],
  components: {
    Footer, Navbar, SingleJob,
    Loading,
  },
  data(){
      return {
        currentPage: 'Congraturations',
        previousPage: '',
        search: '',
        location: '',
        countries: '',
        categories: '',
        category: '',
        isLoading: true,
        jobs: {current_page: 1},
        allJobs: {},
        data: {},
        errors: false,
      }
  },
    methods:{
    isLoad() {
      setTimeout(function () {
        this.isLoading =  false
    }.bind(this), 3000);
   }
  },

  mounted () {
    document.title = `Congraturation For Application - Vipawaworks`
      this.isLoad()
  }
}
</script>

<style scoped>

</style>

