<template>
    <Navbar />
    <div v-if="!isLoading">
        
        <section class="section mt-60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                
                                <div v-if="job.applicants.length">
                                    <h3 class="text-center text-primary">Candidates Requires Your Attention For <span class="text-dark">{{ job.name }}</span></h3>
                                    <div class="col mt-4 pt-2" id="tables">
                                    <div class="component-wrapper">
                                        <div class="p-4">
                                            <div class="row justify-content-center">
                                                    <div class="alert alert-success" v-if="success">
                                                        <p class="text-center">{{ success }}</p>
                                                    </div>
                                                    <div class="alert alert-danger" v-if="errors">
                                                        <p class="text-center">{{ errors }}</p>
                                                    </div>
                                                <div class="col-6  mt-4 pt-2" v-for="applicant in job.applicants" :key="applicant.id">
                                                    <div class="card shop-list border-0 shadow position-relative">
                                                        <div class="row align-items-center g-0">
                                                            <div class="col-lg-3 col-md-9">
                                                                <div class="shop-image position-relative overflow-hidden">
                                                                    <router-link :to="{ name:'TemporaryResume', params:{ id:applicant.id} }">
                                                                        <div v-if="applicant.avatar">
                                                                            <img v-bind:src="'https://api.vipawaworks.com/' + applicant.avatar" class="avatar float-md-left avatar-large img-fluid" alt="">
                                                                        </div>
                                                                        <div v-else>
                                                                            <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar float-md-left avatar-large img-fluid" alt="">
                                                                        </div>
                                                                    </router-link>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-9 col-md-9">
                                                                <div class="card-body content p-4">
                                                                    <router-link :to="{ name:'TemporaryResume', params:{ id:applicant.id} }" class="text-dark product-name h4">{{ applicant.fname }} {{ applicant.lname}}</router-link>
                                                                    <a href="javascript:void(0)" class="badge badge-link rounded-pill text-primary" v-if="applicant.verified == 1">
                                                                        <svg height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                                                    </a>
                                                                    <div class="d-lg-flex align-items-center mt-2 mb-3">
                                                                        <h6 class="text-primary small fst-italic mb-0 me-3">{{ applicant.roles[0].name }}</h6>
                                                                        <h6 class="text-muted small fst-italic mb-0 me-3" v-if="applicant.specialists[0]">{{ applicant.specialists[0].name && applicant.specialists[0].name.length < 20 ? applicant.specialists[0].name : applicant.specialists[0].name.substring(0,17)+".."  }}</h6>
                                                                        <h6 class="text-muted small fst-italic mb-0 me-3" v-else>No Specialist</h6>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="text-muted small fst-italic mb-0 me-3" v-if="applicant.education[0]">{{ applicant.education[0].faculty && applicant.education[0].faculty.length < 36 ? applicant.education[0].faculty : applicant.education[0].faculty.substring(0,32)+".."  }}</h6>
                                                                    </div>
                                                                    <p class="para-desc text-muted mb-1" v-if="applicant.profile[0]">
                                                                        <span v-if="applicant.profile[0].city">{{ applicant.profile[0].city.name }}, </span>
                                                                        <span>{{ applicant.profile[0].country.emoji }} {{ applicant.profile[0].country.name }}</span>
                                                                    </p>
                                                                    <p class="para-desc text-muted mb-1" v-else>No Profile Infomation</p>
                                                                    <div class="d-grid gap-2 d-md-block d-lg-block">
                                                                        <router-link :to="{ name:'TemporaryResume', params:{ id:applicant.id} }" class="btn btn-secondary  me-md-2">Resume</router-link>
                                                                        <router-link :to="{ name:'TemporaryDetails', params:{ id:applicant.id, job:job.id} }" class="btn btn-primary">Take Action</router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="text-center" v-else>
                                    <div class="">
                                        <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                        <h3 class="text-center">{{ errors }}</h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import moment from 'moment'
import ProgressSidebar from '@/components/company/ProgressSidebar.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { Navbar,Footer,ProgressSidebar,Loading, },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {},
          success: false,
          errors: false,
          applicant: {},
          job: {
              applicants: []
          },
          isLoading: true,
      }
  },
  methods: {
      getApplicants(){
          let link = this.$route.params.link
        axios.get('/temporary/' + link).then(response => {
            this.job = response.data
        }).catch(errors => {
             this.errors = errors.response.data.errors
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
      shortList(applicant){
          let job = this.$route.params.id
          axios.post('/application/shortlist/' + job, applicant).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      rejectApplicant(applicant){
          let job = this.$route.params.id
          axios.post('/application/reject/' + job, applicant).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      
  },
  created(){
      document.title = `All Applicants - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getApplicants()  
  },
}
</script>

<style>

</style>