<template>
  <section class="bg-profile d-table w-100 bg-primary">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
                      <div class="card-body">
                          <div class="row align-items-center">
                                <div v-if="user.avatar" class="col-lg-2 col-md-3 text-md-start text-center">
                                    <img v-bind:src="'https://api.vipawaworks.com/' + user.avatar" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                </div>
                                <div v-else class="col-lg-2 col-md-3 text-md-start text-center">
                                  <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                                </div>

                              <div class="col-lg-10 col-md-9">
                                  <div class="row align-items-end">
                                      <div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                          <h3 class="title mb-0">{{ user.fname }} <span v-if="user.sname">{{ user.sname }} </span> {{ user.lname }} 
                                              <span class="text-primary" v-if="user.verified == 1">
                                                <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                              </span>
                                            </h3>
                                          <ul class="list-inline mb-0 mt-3">
                                              <li class="list-inline-item me-2" v-if="user.roles[0].id == 3"><a href="javascript:void(0)" class="text-muted" title="Instagram">Job Seeker</a></li>
                                               <li class="list-inline-item me-2" v-else-if="user.roles[0].id == 5"><a href="javascript:void(0)" class="text-muted" title="Instagram">Fresher</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>

export default {
    props: ['user'],
}
</script>

<style>

</style>