<template>
<FreelancerNav :user="user"/>
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">

                    <div class="col-lg-8 col-12">
                        <div class="rounded shadow p-4">
                            <h3 class="mb-0 text-center text-primary">Request Received: </h3>
                            <div v-if="offers.length">
                                <RequestInfo :offers="offers" />
                            </div>
                            <div v-else>
                                <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                <h5 class="text-center">You Have no Request Yet</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'
import RequestInfo from '@/components/freelancer/RequestInfo.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
  components: { Footer,FreelancerNav,RequestInfo,Loading, },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {},
          offers: [],
          token: localStorage.getItem('user_data'),
          isLoading: true,
          errors: {},
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
            axios.get('/freelancer/requests').then(response => {
                this.offers = response.data
            }).catch(errors => {
                this.errors = 'Something went wrong' 
            })
        }).catch(errors => {
            if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
      },
  },
  created(){
      document.title = `All Request - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>

</style>