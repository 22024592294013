<template>
<Navbar />
    <section class="section">
        <div class="container">
            <div class="row align-items-center" id="counter">
                <div class="col-md-6">
                    <img src="@/assets/img/others/about.jpg"  class="img-fluid rounded shadow" alt="">
                </div>
                <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div class="ms-lg-4">
                        <div class="d-flex mb-4">
                            <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" data-target="10">10</span>+</span>
                            <span class="h6 align-self-end ms-2">Years <br> Experience</span>
                        </div>
                        <div class="section-title">
                            <h4 class="title mb-4">Who we are ?</h4>
                            <p align="justify" class="text-muted"><span class="text-primary fw-bold">Vipawaworks</span> 
                            is a dynamic recruitment and outsourcing firm established in Tanzania to champion a valuable link between professionals.
                            We believe in quality production for quality staffing and endeavor to create an affordable,
                            cost effective and hassle free hiring and job-seeking ground that meets both the job description and the specific career requirements.
                            We are driven by the major pillars of professionalism, reliability,
                            trust and integrity to craft a quality platform for employers and employees to power up their talent potentials
                            </p>
                            <router-link :to="{ name : 'Contacts' }" class="btn btn-primary mt-3">Contact us</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-light">
        <div class="container">
            <div class="row">
                <div class="text-center">
                        <h2 class="title">Mission Vision & Goals</h2>
                </div>
                <div class="col-md-4 pt-2">
                    <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                        <div class="icons text-primary text-center mx-auto">
                            <svg height="80" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                        </div>

                        <div class="card-body">
                            <h5 class="text-dark">Vision</h5>
                            <p class="text-muted mb-0" align="justify">Our Vision is to be the preferred recruitment agency and Human Resource consultant of every employer in Tanzania.</p>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                    <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                        <div class="icons text-primary text-center mx-auto">
                            <svg height="80" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                        </div>

                        <div class="card-body">
                            <h5 class="text-dark">Mission</h5>
                            <p class="text-muted mb-0" align="justify">We will continue to source talented individuals in Tanzania and attract new experts around the world by identifying candidates with outstanding abilities and matching their skills to client's requirement. 
                                We strengthen our client's human resource capabilities while improving the candidate's career prospects. </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                    <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                        <div class="icons text-primary text-center mx-auto">
                            <svg height="80" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
                        </div>

                        <div class="card-body">
                            <h5 class="text-dark">Our Goal</h5>
                            <p class="text-muted mb-0" align="justify">Our goal is to help not only the candidates but also the organizations to beat the competition in the market we are dedicated to help organizations to match with top talents.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row align-items-end">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-start">
                        <h6 class="text-primary">Services</h6>
                        <h4 class="title mb-4">What we do ?</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <ul class="nav nav-pills nav-justified flex-column bg-white rounded shadow p-3 mb-0 sticky-bar" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link rounded active" id="webdeveloping" data-bs-toggle="pill" href="#developing" role="tab" aria-controls="developing" aria-selected="false">
                                <div class="text-center py-1">
                                    <h6 class="mb-0">Human Resources Consultancy</h6>
                                </div>
                            </a>
                        </li>
                        
                        <li class="nav-item mt-2">
                            <a class="nav-link rounded" id="database" data-bs-toggle="pill" href="#data-analise" role="tab" aria-controls="data-analise" aria-selected="false">
                                <div class="text-center py-1">
                                    <h6 class="mb-0">Recruitment</h6>
                                </div>
                            </a>
                        </li>
                        
                        <li class="nav-item mt-2">
                            <a class="nav-link rounded" id="server" data-bs-toggle="pill" href="#security" role="tab" aria-controls="security" aria-selected="false">
                                <div class="text-center py-1">
                                    <h6 class="mb-0">HR Outsourcing</h6>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-md-8 col-12 mt-4 pt-2">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
                            <div style="width:100%;">
                                <div style="float:left;width:50%;" class="px-2">
                                    <img src="@/assets/img/others/consultancy.jpg" class="img-fluid rounded shadow" alt="">
                                </div>
                                <div style="float:none;"> 
                                     <p class="text-muted" align="justify">
                                            We are innovatively creative and focus on client's specific requirements to deliver quality and cost effective service at affordable levels. We value our client's investment. 
                                            Being business ownners ourselves, we know what success is all about, and strive to attain maximum results for our clients and for ourselves. 
                                            We are good listeners and comfortably adaptive. We combine the qualities of team building to craft healthy relationships that yield powerful results at all levels at the workplace. 
                                            Quality networking remains the key strength. We advocate for quality communication and meet-ups among parties - clients, employees 
                                        </p>
                                </div>
                            </div>         
                        </div>
                        
                        <div class="tab-pane fade bg-white p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="database">
                             <div style="width:100%;">
                                <div style="float:left;width:50%;" class="px-2">
                                    <img src="@/assets/img/others/recruitment.jpg" class="img-fluid rounded shadow" alt="">
                                </div>
                                <div style="float:none;" class="px-2"> 
                                     <p class="text-muted" align="justify"> 
                                        VIPAWAWORKS (T) Ltd is a dynamic recruitment and outsourcing firm established in Tanzania to champion a valuable link between professionals. 
                                        We believe in quality production for quality staffing and endeavor to create an affordable, cost effective and hassle free hiring and job-seeking ground that meets both the job descripton and the specific career requirements. 
                                    </p>
                                </div>
                            </div> 
                        </div>
                        <div class="tab-pane fade bg-white p-4 rounded shadow" id="security" role="tabpanel" aria-labelledby="server">
                            <div style="width:100%;">
                                <div style="float:left;width:50%;" class="px-2">
                                    <img src="@/assets/img/others/outsourcing.jpg" class="img-fluid rounded shadow" alt="">
                                </div>
                                <div style="float:none;" class="px-2"> 
                                    <p class="text-muted" align="justify">
                                    VIPAWAWORKS (T) Ltd can develop customized HR outsourcing services for you based on the type of workforce you have and the specific needs of your business. You deserve a partner that can provide you with the freedom to focus on growing your business every day.
                                    From recruitment through the entire employee lifecycle, we optimize talent management, reduce costs and improve efficiency.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  name: 'Jobs',
  emits: ["paginate"],
  components: {
    Loading,Navbar,Footer
  },
  created(){
      document.title = `About Us - Vipawaworks`
  },
  }
</script>
<style scoped>
.nav-pills .nav-link {
    color: #495057 !important;
}
.nav-pills .nav-link.active {
	color: #fff !important;
}
</style>
