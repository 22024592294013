<template>
        <div class="alert alert-success" v-if="success">
            <p class="text-center">{{ success }}</p>
        </div>
      <div class="row justify-content-center">
            <div v-for="user in companyUsers.users" :key="user.id" class="col-lg-4 col-md-4 mt-4 pt-2">
                <div class="text-center">
                    <div v-if="user.avatar" >
                    <img v-bind:src="'https://api.vipawaworks.com/' + user.avatar" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                    </div>
                    <div v-else >
                        <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
                    </div>
                    <div class="content mt-3">
                        <a href="javascript:void(0)" class="h5 text-dark">{{ user.fname }} {{ user.lname }}</a>
                        <br>
                        <a href="javascript:void(0)" class="h6 text-dark" v-if="user.roles.length">
                            <span v-if="user.roles[0].id == 1">Administrator</span> <span v-else>HRO</span>
                        </a>
                        <a href="javascript:void(0)" class="h6 text-dark" v-else>Not Assigned</a>
                        <div v-if="currentUser.roles[0].id == 1">
                            <ul class="list-unstyled social-icon social mb-0 mt-2" v-if="user.roles.length">                            
                                <li class="list-inline-item" v-if="currentUser.id != user.id">
                                    <a @click="deleteUser(user.id)" class="rounded text-danger" title="Delete">
                                        <svg class="w-6 h-6"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                    </a>
                                </li>
                            </ul>
                            <ul class="list-unstyled social-icon social mb-0 mt-2" v-if="!user.roles.length"> 
                                <li class="list-inline-item" >
                                    <a @click="assignHRRole(user.id)" class="rounded text-success" title="Assign">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                    </a>
                                </li>                           
                                <li class="list-inline-item" >
                                <a @click="deleteUser(user.id)" class="rounded text-danger" title="Delete" >
                                        <svg class="w-6 h-6"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                            
                        
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
     props : ['user_info', 'currentUser'],
      data(){
      return {
          token: localStorage.getItem('user_data'),
          companyUsers: {},
          success: false
      }
  },
    methods:{
        getCompanyusers(){
            axios.get('/company/users/' + this.user_info.id).then(response => {
                this.companyUsers = response.data
            })
        },
        assignHRRole(id){
             axios.post('/company/user/assignHR/' + id, ).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
        },
         deleteUser(id){
         Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/company/user/destroy/'+ id)
                .then(response => {
                    this.companyUsers.users = this.companyUsers.users.filter(user => {
                      return user.id != id
                      })
                }).catch(() => {
                    this.$router.push({ name: 'CompanyUsers'})
                })
                Swal.fire(
                'Deleted!',
                'Skill has been Deleted.',
                'success'
                )
            }
            })
        }
    },
     created(){
     axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 

     if (this.user_info) {
         this.getCompanyusers()   
     }
     
  }

}
</script>

