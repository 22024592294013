<template>
  <NavEmployer :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="rounded shadow p-4">
                            <h4 class="text-center">Requested Freelancers</h4>
                            <div  v-if="user_info.companies[0]">
                                <div v-if="user_info.companies[0].active == 1">
                                    <RequestedFreelancer :company="user_info.companies[0]"/>
                                </div>
                                <div class="text-center" v-else>
                                    <img src="@/assets/img/svg/campling.svg" height="200" class="rounded mx-auto d-block">
                                    <h2>Your Company Is Domant</h2>
                                    <p>Since we need to make sure that the entered details are correct and do not violate our terms and Conditions. At meantime you cannot enjoy this Service.</p>
                                    <p>We apologies for inconvinient, since there have been alot of untrusted people in internet, we may contact you inorder to verify your Company during working hour.</p>
                                    <p>If this takes more time please <router-link  :to="{ name : 'Contacts' }">Contact us</router-link></p>
                                </div>   
                            </div>
                            <div v-else class="col-lg-8 col-12">
                                <div class="justify-content-center">
                                    <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                    <h4 class="text-center">Company Not Registed</h4>
                                    <p class="text-center">Get Started by Filling Up Your <router-link class="text-dark" :to="{ name : 'AddCompanyProfile' }">Company Info</router-link></p>
                                </div>
                            </div>   
                           
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
    </div>
  <Footer />
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'
import Profile from '@/components/company/Profile.vue'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import CompanyDetails from '@/components/company/CompanyDetails.vue'
import Loading from '@/views/custom/Loading.vue'
import RequestedFreelancer from '@/components/company/RequestedFreelancer.vue'
export default {
  components: { SideMenu, Profile, NavEmployer,Footer, CompanyDetails,Loading,RequestedFreelancer, },
  data(){
      return {
        drawer: true,
        currentUser: {},
        user: {},
        candidates: {},
        allCandidates:{},
        filter: false,
        user_info: { companies:[0], roles: {}},
        token: localStorage.getItem('user_data'),
        isLoading: true,
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
     
  },
  
  created(){
      document.title = `Requested Freelancers - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>
.item + .item:before {
  content: ", ";
}

</style>