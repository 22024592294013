<template>
  <div v-if="!isLoading">
      <div class="back-to-home rounded d-none d-sm-block">
            <router-link :to="{ name: 'Home'}" class="btn btn-icon btn-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home icons"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
            </router-link>
        </div>

        <section class="bg-home bg-circle-gradiant d-flex align-items-center">
            <div class="bg-overlay bg-overlay-white"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-8"> 
                        <div class="card shadow rounded border-0">
                            <div class="card-body" v-if="enterPassword">
                                <h4 class="card-title text-center">Recover Account</h4>  
                                <form class="login-form mt-4" @submit.prevent="resetPassword">
                                    <div class="row">
                                        <p class="text-muted">Please Write a new Password carefully.</p>
                                        <input type="hidden" v-model="form.user_id">
                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label class="form-label">New Password <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                                    <input type="password" class="form-control ps-5" v-model="form.password" required="">
                                                    <div v-if="errors.password" class="text-small text-danger">{{ errors.password[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label class="form-label">Comfirm Password <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                   <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                                    <input type="password" class="form-control ps-5" v-model="form.password_confirmation" required="">
                                                    <div v-if="errors.password_confirmation" class="text-small text-danger">{{ errors.password_confirmation[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="d-grid">
                                                <button class="btn btn-primary">Reset Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            
                            <div class="card-body" v-else-if="tokenPresent">
                                <h4 class="card-title text-center">Recover Account</h4>  
                                <form class="login-form mt-4" @submit.prevent="validateToken">
                                    <div class="row">
                                        
                                        <div class="col-lg-12">
                                             <p class="text-muted">Please enter your token you recieved through email.</p>
                                            <div class="mb-3">
                                                <label class="form-label">Token <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control ps-5" v-model="form.token" required="">
                                                    <div v-if="errors[0]" class="text-small text-danger">{{ errors }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="d-grid">
                                                <button class="btn btn-primary">Validate Token</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body" v-else>
                                <h4 class="card-title text-center">Recover Account</h4>  
                                <form class="login-form mt-4" @submit.prevent="sendToken">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p class="text-muted">Please enter your email address. You will receive a token to create a new password via email.</p>
                                            <div class="mb-3">
                                                <label class="form-label">Email address <span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                    <input type="email" class="form-control ps-5" placeholder="Enter Your Email Address" v-model="form.email" required="">
                                                    <div v-if="errors[0]" class="text-small text-danger">{{ errors }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="d-grid">
                                                <button class="btn btn-primary">Send</button>
                                            </div>
                                        </div>
                                        <div class="mx-auto">
                                            <p class="mb-0 mt-3"><small class="text-dark me-2">Remember your password ?</small> <router-link :to="{ name : 'Login' }" class="text-dark fw-bold">Sign in</router-link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
  <div v-else>
       <Loading />
   </div>
</template>

<script>
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { Loading },
  data(){
        return{
            success: false,
            enterEmail: true,
            tokenPresent: false,
            enterPassword: false,
            isLoading: false,
            values: [],
            form:{
                email: '',
                token: '',
                password: '',
                password_confirmation: '',
                user_id: ''
            },
            
            user: {},
            errors: {}
        }
    },
    methods:{
        sendToken(){
            this.Loading = true
                axios.post('/forgot/password',this.form).then(response =>{
                this.tokenPresent = true
                this.success = response.data
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
        validateToken(){
            this.Loading = true
                axios.post('/validate/token',this.form).then(response =>{
                this.success = response.data
                this.enterPassword = true
                this.form.user_id = response.data.id
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
        resetPassword(){
            this.Loading = true
            axios.post('/reset/password',this.form).then(response =>{
                this.$router.push({ name: 'Login'})
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
        document.title = `Forgot Password - Vipawaworks`
    }
}
</script>

<style  scoped>

</style>