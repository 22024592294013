<template>
  <div class="row">
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Dashboard' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Dashboard</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Activities' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Activities</h6>
        </router-link>
    </div> 
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Resume', params:{ id:user.id } }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Preview Resume</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Profile' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Profile</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Specialists' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Specialist & Membership</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Carrier' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Career & Education</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Skills' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Skills & Languages</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Training' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Training & Certification</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Hobby' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Hobby and Interest</h6>
        </router-link>
    </div>
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'Referee' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Referee</h6>
        </router-link>
    </div>

    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <router-link :to="{ name : 'CandidateJob' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">My Jobs Preference</h6>
        </router-link>
    </div>
    
    <div class="col-lg-6 col-md-2 col-sm-2 col-2 mt-4 pt-2">
        <a @click="logout" class="btn accounts rounded d-block shadow text-center py-3">
             <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
            <h6 class="title h6 my-0 d-lg-block d-none">Logout</h6>
        </a>
    </div>
</div><!--end row-->
</template>

<script>

export default {
    props:['user'],
     methods: {
         logout() {
             axios.post('/logout').then(response => {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
             }).catch(errors => {
             })
         }
     }
}
</script>

<style scoped>

</style>