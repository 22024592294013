  <template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Personal Information</h5>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">First Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.fname"  >
                                                    <div v-if="errors.fname" class="text-small text-danger">{{ errors.fname[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Second Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.sname" >
                                                    <div v-if="errors.sname" class="text-small text-danger">{{ errors.sname[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Last Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.lname" >
                                                    <div v-if="errors.lname" class="text-small text-danger">{{ errors.lname[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Email</label>
                                                <div class="form-icon position-relative">
                                                    <input type="email" class="form-control" v-model="form.email" >
                                                    <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <input type="submit" id="submit" class="btn btn-primary" value="Save Changes" v-else>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
    	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
    components: { Footer,NavSeeker,Loading },
    data(){
        return {
            values: [],
            form: {
               email: '', fname: '', sname: '', lname: '', phone : '', description: '',
            },
            user: {roles: [0]}, 
            token: localStorage.getItem('user_data'),           
            errors: [],
            isLoading: true,
            submitting: false,
        }
    },
    created(){
        document.title = `Edit Profile - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            this.form = this.currentUser.user
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
    },
    methods: {
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/profile/edit/' + this.user.id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'Profile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
    }
    

}
</script>

<style>

</style>