<template>
    <div class="alert alert-warning">
        <h6 class="text-center">Please note: Any information you change in "My Account" will also be changed in your CVs.</h6>
    </div>
    <h5 class="text-center">Personal Information</h5>
    <div class="d-flex flex-row-reverse">
        <router-link class="btn text-primary end-0" :to="{ name : 'EditProfile' }">
            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
        </router-link>
    </div>
    <div v-if="user">
        <table class="table table-borderless">    
            <tbody>
                <tr>
                    <td><span class="fw-bold">First Name:</span> {{ user.fname }}</td>
                    <td><span class="fw-bold">Second Name:</span> {{ user.sname }}</td>
                    <td><span class="fw-bold">Last Name:</span> {{ user.lname }}</td>
                    <td><span class="fw-bold">Email:</span> {{ user.email }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="alert alert-danger" v-else>
        <h6 class="text-center">Nothing to Show!</h6>
    </div>
    <hr>
    <h5 class="text-center">Basic Information</h5>
     <div class="alert alert-danger" v-if="!user_info.profile[0]" >
            <div class="d-flex flex-row-reverse">
                <router-link class="btn text-primary end-0" :to="{ name : 'BasicProfile' }">
                    <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                </router-link>
            </div>
            <h6 class="text-center">You Have Not Added </h6>
        </div>
   <div v-if="user_info.profile[0]">    
       <div v-if="user_info.profile[0]">
            <div class="d-flex flex-row-reverse">
                <router-link class="btn text-primary end-0" :to="{ name : 'EditBasicProfile' , params: {id:user_info.profile[0].id}}">
                    <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                </router-link>
            </div>
            <table class="table table-borderless">    
                <tbody>
                    <tr>
                        <td><span class="fw-bold">Full Name:</span> {{ user_info.fname }} {{ user_info.profile[0].sname }} {{ user_info.lname }}</td>
                        <td><span class="fw-bold">Age:</span> {{ age }} Years</td>
                        <td><span class="fw-bold">Gender:</span> {{ user_info.profile[0].sex }}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Nationality:</span> {{ user_info.profile[0].country.name }}</td>
                        <td v-if="user_info.profile[0].city"><span class="fw-bold">City:</span>{{ user_info.profile[0].city.name }}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Visa Status:</span> {{ user_info.profile[0].visa }}</td>
                        <td><span class="fw-bold">Martial Status:</span> {{ user_info.profile[0].martial_status }}</td>
                        <td><span class="fw-bold">Number Of Dependants:</span>{{ user_info.profile[0].dependants }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="alert alert-danger" v-else>
            <div class="d-flex flex-row-reverse">
                <router-link class="btn text-primary end-0" :to="{ name : 'BasicProfile' }">
                    <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                </router-link>
            </div>
            <h6 class="text-center">Add Basic Infomation So that Employers can Know You Better</h6>
        </div>
        <hr>
        <h5 class="text-center">Contacts Info</h5>
        <div v-if="user_info.profile[0].mobile_phone">
            <div class="d-flex flex-row-reverse">
                <router-link class="btn text-primary end-0" :to="{ name : 'EditContactProfile' , params: {id:user_info.profile[0].id}}">
                    <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                </router-link>
            </div>
            <div >
                <table class="table table-borderless" >    
                    <tbody>
                        <tr>
                            <td><span class="fw-bold">Email:</span> {{ user_info.email }}</td>
                            <td><span class="fw-bold">Phone:</span> {{ user_info.profile[0].mobile_phone }}</td>
                            <td><span class="fw-bold">Website:</span> {{ user_info.profile[0].website }}</td>
                        </tr>
                        <tr>
                            <td><span class="fw-bold">Physical Address:</span> {{ user_info.profile[0].physical_address }}</td>
                            <td v-if="user_info.profile[0].city"><span class="fw-bold">City:</span> {{ user_info.profile[0].city.name }}</td>
                            <td><span class="fw-bold">Country:</span> {{ user_info.profile[0].country.name }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="alert alert-danger" v-else>
            <div class="d-flex flex-row-reverse">
                <router-link class="btn text-primary end-0" :to="{ name : 'ContactProfile' }">
                    <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                </router-link>
            </div>
            <h6 class="text-center">Add Contact Infomation So that Employers can Find You Easily</h6>
        </div>
        <hr>
        <div v-if="user_info.profile[0].private == 0">
            <h5 class="text-center">Private Account</h5>
            <h6 class="text-center">
                Change your account to Private Account, this means an employer will be able to find you when searching for Candidates and they will not be able to see your contact infomation such as Phone Numbers and emails.
                 This is best practise when you are not urgent looking for job, and no one will disturbe you. We will only send you a massage when they try to contact you.
            </h6>
            <h6 class="text-center alert alert-warning">NB: Your Account is Public</h6>
            <div class="text-center">
                <button class="btn btn-primary end-0" disabled v-if="submitting">
                    <span class="label">{{ value }}</span>
                </button>
                <button class="btn btn-primary end-0" @click="SwitchToPrivate" v-else>
                    Switch To Private Account
                </button>
            </div>
        </div>
        <div v-else>
            <h5 class="text-center">Public Account</h5>
            <h6 class="text-center">
                Change your account to <span class="text-primary">Public Account</span>, this means an employer will be able to find you when searching for Candidates even when you apply for job, they may see your contact Infomation, and tjhey will be able to call you direct.
                 This will speed up the recruitment process and Employers will be able to contact you very quikly. This is best practise when you are urgent looking for job
            </h6>
            <h6 class="text-center alert alert-warning">NB: Your Account is Private</h6>
            <div class="text-center">
                <button class="btn btn-primary end-0" disabled v-if="submitting">
                    <span class="label">{{ value }}</span>
                </button>
                <button class="btn btn-primary end-0" @click="SwitchToPublic" v-else>
                    Switch To Public Account
                </button>
            </div>
        </div>
        <hr>
        <div class="">
            <div class="d-flex flex-row-reverse">
                <router-link class="btn text-primary end-0" :to="{ name : 'ChangePassword' }">
                     <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                </router-link>
            </div>
            <h5 class="text-center">Password</h5>
            <h6 class="text-center">Change your account password</h6>
        </div>
    </div> 
    
</template>

<script>
export default {
     props : ['user_info','user'],
    data(){
        return {
            profile: [],
            age: '',
            token: localStorage.getItem('user_data'),
            submitting: false,  
        }
    },
    methods:{
        getAge(){
            axios.get('/profile/getAge/' + this.user.id).then(response => {
                this.age = response.data
            }).catch(errors => {
                
            })
        },
        SwitchToPrivate(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/profile/change/private').then(response => {
                this.success = response.data.success
                this.$router.push({ name: 'Dashboard'})
            }).catch(errors => {
                this.errors = errors.response.data.errors
                this.submitting = false
            })
        },
        SwitchToPublic(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/profile/change/public').then(response => {
                this.success = response.data.success
                this.$router.push({ name: 'Dashboard'})
            }).catch(errors => {
                this.errors = errors.response.data.errors
                this.submitting = false
            })
        },
    },
      created(){
      this.getAge()  
  }

}
</script>

<style>

</style>