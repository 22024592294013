<template>
    <Navbar /> 
    <div v-if="!isLoading">
        <section class="bg-home bg-light d-flex align-items-center">
            <div class="container">
                <h4 class="card-title text-center">Verify Email</h4> 
                <div class="alert alert-success" v-if="success">
                    <p class="text-center">{{ success[0] }}</p>
                </div>
                <div class="card-body">
                    <form class="login-form mt-4" @submit.prevent="validateEmail">
                        <div class="row justify-content-center">
                            
                            <div class="col-lg-8">
                                <p class="text-muted">Please enter your token you recieved through email <strong>{{ user.email }}.</strong> </p>
                                <div class="mb-3">
                                    <label class="form-label">Token <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input type="text" class="form-control ps-5" placeholder="Enter Token" v-model="form.token" >
                                        <div v-if="errors[0]" class="text-small text-danger">{{ errors }}</div>
                                    </div>
                                </div>
                                <div class="d-grid">
                                    <button class="btn btn-primary" disabled v-if="submitting">
                                        <span class="label">{{ value }}</span>
                                    </button>
                                    <button class="btn btn-primary" v-else>Validate Token</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <p class="text-center">Didn't receive Token? <a @click="reSend" class="btn text-primary">Resend</a></p>
                </div>
            </div>
        </section>    
    </div>
    <div v-else>
        <Loading />
    </div>
    <Footer /> 
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { Navbar,Footer,Loading },
    data(){
        return {
            drawer: true,
            currentUser: {},
            user: {roles: []},
            token: localStorage.getItem('user_data'),
            form:{
                token: '',
                user_id: ''
            },
            isLoading: true,
            success: false,
            submitting: false,
            errors: {}
        }
    },
    methods: {
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
                if (this.user.banned == 1) {
                    this.$router.push({ name: 'Banned'})
                }
                if (this.user.email_verified_at != null) {
                    this.$router.push({ name: 'Dashboard'})
                }
            
            }).catch(errors => {
                if (errors.response.status === 401) {
                    localStorage.removeItem('user_data')
                    this.$router.push({ name: 'Login'})
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
        reSend(){
                axios.post('/resend/token',this.user.email).then(response =>{
                this.tokenPresent = true
                this.success = response.data
                
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
        },
        validateEmail(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/validate/email',this.form).then(response =>{
                this.success = response.data
                this.form.user_id = response.data.id
                this.$router.push({ name: 'Dashboard'})
            }).catch((errors) => {
                this.errors = errors.response.data.errors
                this.submitting = false
            })
        },
    },
  created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      document.title = `Verify Email - Vipawaworks`  
},
}
</script>

<style>

</style>