  <template>
   <FreelancerNav :user="user"/>
  <div>
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-primary text-center">Personal Information</h5>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gender</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.sex" id="" class="form-control ps-5">
                                                        <option value="">Select Your Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    <div v-if="errors.sex" class="text-small text-danger">{{ errors.sex[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Martial Status</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.martial_status" id="" class="form-control ps-5">
                                                        <option value="">Select Your Status</option>
                                                        <option value="Single">Single</option>
                                                        <option value="Married">Married</option>
                                                        <option value="Unspecified">Unspecified</option>
                                                    </select>
                                                    <div v-if="errors.martial_status" class="text-small text-danger">{{ errors.martial_status[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Date Of Birth</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.birth_date" type="date" class="form-control ps-5" >
                                                    <div v-if="errors.birth_date" class="text-small text-danger">{{ errors.birth_date[0] }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Residence Country</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.nationality" class="form-control ps-5" @change="getCities()">
                                                        <option value="">Select Your Nationality</option>
                                                        <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                    </select>
                                                    <div v-if="errors.nationality" class="text-small text-danger">{{ errors.nationality[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">City</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control ps-5"  v-model="form.city_id">
                                                        <option value="">Select City</option>
                                                        <option :value="city.id" v-for="city in cities" :key="city.id">{{ city.name }}</option>
                                                    </select>
                                                    <div v-if="errors.city_id" class="text-small text-danger">{{ errors.city_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Visa</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.visa" class="form-control ps-5" >
                                                        <option value="No Visa">No Visa</option>
                                                        <option value="Residency Visa(Non Transferble)">Residency Visa(Non Transferble)</option>
                                                        <option value="Residency Visa(Transferble)">Residency Visa(Transferble)</option>
                                                        <option value="Student Visa">Student Visa</option>
                                                        <option value="Transit Visa">Transit Visa</option>
                                                        <option value="Visit Visa">Visit Visa</option>
                                                    </select>
                                                </div>
                                                <div v-if="errors.visa" class="text-small text-danger">{{ errors.visa[0] }}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Number of Dependants</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.dependants" type="number" class="form-control ps-5" placeholder="" min="0">
                                                    <div v-if="errors.dependants" class="text-small text-danger">{{ errors.dependants[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label class="form-label">Description</label>
                                                <div class="form-icon position-relative">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                                    <textarea class="form-control ps-5" v-model="form.description"></textarea>
                                                    <div v-if="errors.description" class="text-small text-danger">{{ errors.description[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
  <Footer /> 
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import qs from 'qs'
export default {
    components: { Footer,FreelancerNav, },
    data(){
        return {
            form: {
               sex:'',birth_date: '',nationality: '', addition_nationality:'', city: '', visa: 'No Visa', dependants: '', martial_status: '',website: '',description: ''
            },
            editor: ClassicEditor,
            user: {}, 
            countries: {},
            cities:{},
            token: localStorage.getItem('user_data'),
            submitting: false,           
            errors: []
        }
    },
    created(){
        document.title = `Add Basic Info - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()
        this.getCountries()

    },
    methods: {
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/profile/addProfile/' + this.user.id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'FreelancerProfile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCities(){
        axios.get('/cities/' + this.form.nationality).then(response => {
            this.cities = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getUser(){ 
          axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user 
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
            axios.get('/profile/' + this.user.id).then(response => {
        })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
      }
       
    }
    
}
</script>

<style>

</style>