<template>
    <div class="col-md-6 mt-4">
        <h5>Personal Details :</h5>
        <div class="mt-4">
            <div class="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-ex-md text-muted me-3"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Email :</h6>
                    <a href="javascript:void(0)" class="text-muted">{{ user_info.email }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.skills.length">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark fea icon-ex-md text-muted me-3"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Skills:</h6>
                    <a class="text-muted item" v-for="skill in user_info.skills" :key="skill.id" >{{ skill.name }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark fea icon-ex-md text-muted me-3"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Add Skills</h6>
                    <p>Employers need to know your skills</p>
                    <router-link :to="{ name : 'FreelancerAddSkill' }" class="btn btn-primary">Add Skills</router-link>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.languages.length">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-italic fea icon-ex-md text-muted me-3"><line x1="19" y1="4" x2="10" y2="4"></line><line x1="14" y1="20" x2="5" y2="20"></line><line x1="15" y1="4" x2="9" y2="20"></line></svg>
                <div class="flex-1" >
                    <h6 class="text-primary mb-0">Language :</h6>
                    <a href="javascript:void(0)" class="text-muted item" v-for="language in user_info.languages" :key="language.id" >{{ language.name }}</a>
                </div>
            </div>
             <div class="d-flex align-items-center mt-3" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark fea icon-ex-md text-muted me-3"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Add Language</h6>
                    <p>Employers need to know the language you use to communicate</p>
                    <router-link :to="{ name : 'FreelancerAddSkill' }" class="btn btn-primary">Add Skills</router-link>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.profile[0].website">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe fea icon-ex-md text-muted me-3"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Website :</h6>
                    <a href="javascript:void(0)" class="text-muted">{{ user_info.profile[0].website }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.profile[0].birth_date">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-gift fea icon-ex-md text-muted me-3"><polyline points="20 12 20 22 4 22 4 12"></polyline><rect x="2" y="7" width="20" height="5"></rect><line x1="12" y1="22" x2="12" y2="7"></line><path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path><path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Birthday :</h6>
                    <p class="text-muted mb-0">{{ user_info.profile[0].birth_date }}</p>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3"  v-if="user_info.profile[0].nationality">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin fea icon-ex-md text-muted me-3"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Location :</h6>
                    <a href="javascript:void(0)" class="text-muted"> <span v-if="user_info.profile[0].city">{{ user_info.profile[0].city.name }},</span> {{ user_info.profile[0].country.name }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.profile[0].mobile_phone">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-ex-md text-muted me-3"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Cell No :</h6>
                    <a href="javascript:void(0)" class="text-muted">{{ user_info.profile[0].mobile_phone }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 mt-4">
        <h5 class="text-center">Recently Client Requests</h5>
        <div class="mt-4">
            <div class="col mt-4 pt-2" id="tables" v-if="offers.length">
                <div class="component-wrapper rounded shadow">
                    <div class="p-4">
                        <div class="table-responsive bg-white shadow rounded">
                    <table class="table mb-0 table-center">
                        <thead>
                            <tr>
                            <th scope="col" class="border-bottom">Offer From</th>
                            <th scope="col" class="border-bottom">View Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="offer in offers" :key="offer.id">
                                <td>
                                    {{ offer.company.name }}
                                </td>
                                <td>
                                    <router-link :to="{ name: 'FreelancerRequestDetails', params: {id: offer.id}}" class="text-primary">
                                    View Request
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>                     
            </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                <h6 class="text-center">You Have no Request Yet</h6>
                <p>Freelancer Profile will only be contacted by company when company need your service. We will send you Emails notification if there is anyone has contacted you.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['user_info','offers'],
    data(){
        return {
            limit: 3
        }
    },
    computed:{
        limitedOffer(){
            return this.limit ? this.offers.slice(0,this.limit) : this.offers
        }
    }
}
</script>

<style>

</style>