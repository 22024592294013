  <template>
  <FreelancerNav :user="user"/>
  <div>
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 d-lg-block ">
                        <Sidebar :user="user"/>
                    </div>

                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Personal Information</h5>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Mobile No. :</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.mobile_phone" id="number" type="number" class="form-control ps-5">
                                                    <div v-if="errors.mobile_phone" class="text-small text-danger">{{ errors.mobile_phone[0] }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Telephone No. :</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.telephone" id="number" type="number" class="form-control ps-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Physical Andress :</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.physical_address" type="text" class="form-control ps-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Postal Box :</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.postal_box"  type="text" class="form-control ps-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Website :</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.website"  class="form-control ps-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn btn-primary" disabled v-if="submitting">
                                                <span class="label">{{ value }}</span>
                                            </button>
                                            <button class="btn btn-primary" v-else>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'

import qs from 'qs'
export default {
    components: { Footer,FreelancerNav, },
    data(){
        return {
            values: [],
            form: {
               mobile_phone:'',telephone: '',physical_address: '',postal_box: '',website: ''
            },
            user: {}, 
            token: localStorage.getItem('user_data'),
            submitting: false,   
            errors: []
        }
    },
    created(){
        document.title = `Add Contact Info - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user 
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
        let id = this.$route.params.id
        axios.get('/profile/show/' + id).then(response => {
            this.form = response.data
            })
    },
    methods: {
        
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            let id = this.$route.params.id
            axios.post('/profile/editProfileContact/' + id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'FreelancerProfile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
    }
    

}
</script>

<style>

</style>