<template>
        <h4 class="text-center">Request Details For {{ freelancer.user.fname }} {{ freelancer.user.lname }}</h4>
        <div>
            <hr>
            <p class="mb-0">
                Hellow Mr/Mrs, I hope you re doing okay at <strong>{{ freelancer.company.name }}.</strong>    
            </p>
            <p v-if="freelancer.rejected == 1">
                I am sorry i had to reject your offer for my services due to the follwing reasons. <br>
                {{ freelancer.rejection_reason }} <br>
                I hope you understand, thanks
            </p>
            <p v-else-if="freelancer.completed == 1">
                Thank You for your Rating, It was pleasure to work with you. You can Request My service Anytime. It wont Cost your Packages <br>
                I hope to here soon from you, thanks
            </p>
            <p v-else-if="freelancer.accepted == 1">
                Thank You for your Offer, I am pleased and very exited to work with you. Here are my contacts for Efficient Communication <br>
                 <strong>{{ freelancer.user.profile[0].mobile_phone }} && {{ freelancer.user.email }}</strong>  <br>
                I hope to here soon from you, thanks
            </p>
        </div>
        <div v-if="freelancer.rejected == 1">
            <button type="button" class="btn btn-pills btn-success" v-on:click="request = !request">Request For Service</button>
        </div>
        <div v-if="freelancer.completed == 1">
            <button type="button" class="btn btn-pills btn-success" v-on:click="request = !request">Request For Service</button>
        </div>
        <div v-else-if="freelancer.accepted == 1">
            <button type="button" class="btn btn-pills btn-success" v-on:click="complete = !complete">Completed Service</button>
        </div><br>

        <div v-if="complete">
            <form @submit.prevent="rateService" enctype="multipart/form-data" method="post">
                <div class="col-6">
                    <label class="form-label">Rate Me</label>
                    <select class="form-control "  v-model="form.rating">
                        <option value="1">Extreemly Poor</option>
                        <option value="2">Very Poor</option>
                        <option value="3">Poor</option>
                        <option value="4">Below Average</option>
                        <option value="5">Average</option>
                        <option value="6">Above Average</option>
                        <option value="7">Good</option>
                        <option value="8">Very Good</option>
                        <option value="9">Excellent</option>
                        <option value="10">Outstanding</option>
                    </select>
                    <div v-if="errors.rating" class="text-small text-danger">{{ errors.rating[0] }}</div>
                </div>
                <button type="submit" class="btn btn-outline-success mt-2 ms-2" >Rate Me</button>
            </form>
        </div>
        <div v-if="request">
            <div class="alert alert-success" v-if="success">
                <p class="text-center">{{ success }}</p>
            </div>
            <form class="mt-4" @submit.prevent="requestService">
            <div class="row justify-content-center">
                <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label">Amount Offer<span class="text-danger">*</span></label>
                        <input type="number" v-model="form.amount" class="form-control" >
                        <div v-if="errors.amount" class="text-small text-danger">{{ errors.amount[0] }}</div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-3">
                        <label class="form-label">Currency <span class="text-danger">*</span></label>
                        <select class="form-select form-control" v-model="form.currency" aria-label="Default select example">
                            <option value="1">Tsh</option>
                            <option value="2">USD</option>
                        </select>
                        <div v-if="errors.currency" class="text-small text-danger">{{ errors.currency[0] }}</div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Duration <span class="text-danger">*</span></label>
                        <select class="form-select form-control" v-model="form.duration" aria-label="Default select example">
                            <option value="1">Per Day</option>
                            <option value="2">Weekly</option>
                            <option value="3">Monthly</option>
                            <option value="4">Until Service is Over</option>
                        </select>
                        <div v-if="errors.duration" class="text-small text-danger">{{ errors.duration[0] }}</div>
                    </div>
                </div>
                <div class="col-12">
                    <label class="form-label">Short Details</label>
                    <textarea v-model="form.details" rows="4" class="form-control" placeholder="Tell me in short What service you need from me"></textarea>
                    <div v-if="errors.details" class="text-small text-danger">{{ errors.details[0] }}</div>
                </div> 
                <div class="col-6 mt-4 pt-2">
                    <div class="d-grid">
                        <button type="submit" class="btn btn-primary">Request Service</button>
                    </div>
                </div>
            </div>
    </form>
        </div>

</template>

<script>
export default {
    props:['company'],
    data(props){
        return {
            freelancer: {
                user:[],
                company:[]
            },
            complete: false,
            request: false,
             form: {
                rating: 4,
                id: props.company.id,
                currency: 1,
                duration: 1,
                details: '',
            },
            success: false,
            errors: false,
        }
    },
    methods:{
         getFreelancerDetail(){
             let id = this.$route.params.id
          axios.get('/company/freelancer/details/' + id).then(response => {
                this.freelancer = response.data
        }).catch(errors => {
        })
      },
      rateService(){
          let id = this.$route.params.id
          axios.post('/freelancer/request/complete/' + id, this.form).then(response => {
              this.$router.push({ name: 'CompanyFreelancers'})
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      requestService(){
            axios.post('/freelancing/request/service/again/' + this.freelancer.user.id, this.form).then(response => {
                this.success = response.data.success
            }).catch(errors => {
            this.errors = errors.response.data.errors
        })
        },
    },
    created(){
        this.getFreelancerDetail()
    }
}
</script>

<style>

</style>