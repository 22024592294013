<template>
<Navbar />
  <section class="section">
    <div class="container" v-if="!isLoading">
      <div v-if="errors">
          <div class="alert alert-danger text-center">{{ errors }}</div>
      </div>
  <h1 class="text-center">Find the Job You Love</h1>
  <p class="text-center">Join the East African #1 Job Site</p>
  <br>
      <div class="row">
          <div class="col-12">
              <div class="border-bottom pb-4">
              <div class="row">
                  <div class="col-md-6">
                      <input type="text" v-model="search"  class="form-control banner-search"  name="keyword" id="joblist" placeholder="Search Keyword" autocomplete="off" aria-label="Skills, Designations, Company Keyword">
                  </div>
                  <div class="col-md-3 ">
                      <div class="mb-0">
                          <select class="form-control custom-select" v-model="category">
                              <option value="">Category</option>
                              <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                          </select>
                      </div>
                  </div>
                  <div class="col-md-3 ">
                      <div class="mb-0">
                          <select class="form-control custom-select" v-model="location">
                              <option value="">Location</option>
                               <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                          </select>
                      </div>
                  </div>
              </div>
              <br>
              </div>
          </div>
      <div v-if="matchingJobs.length">
          <div class="row">
              <div v-for="job in matchingJobs" :key="job.id" class="col-xs-6 col-sm-4 col-md-3 col-lg-3 border-light mb-3">
                  <SingleJob :job="job" />
              </div>
          </div>
          <div class="text-center">
            <pagination v-model="jobs.current_page" :records="jobs.total" :per-page="jobs.per_page" @paginate="getJobs"/>
          </div>
      </div>
      <div class="justify-content-center" v-else>
        <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
        <h3 class="text-center">No Job Found</h3>
        <p class="text-center">We are Sorry We couldnot find what you are looking for. Please check if you may have mispelled texts</p>
        <div class="col-md-12 text-center">
            <button @click="$router.go(-1)" class="btn btn-outline-primary mt-4">Go Back</button>
            <router-link class="btn btn-primary mt-4 ms-2" :to="{ name: 'Home'}">Go To Home</router-link>
        </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>

  </section>
<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import SingleJob from '@/components/SingleJob.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  name: 'Jobs',
  emits: ["paginate"],
  components: {
    Navbar, Footer, SingleJob,
    Loading,
  },
  data(){
      return {
        currentPage: 'All Jobs',
        previousPage: '',
        search: '',
        location: '',
        countries: '',
        categories: '',
        category: '',
        isLoading: true,
        jobs: {current_page: 1},
        allJobs: {},
        data: {},
        errors: false,
      }
  },
  methods: {
    getJobs(page){
      if (typeof page === 'undefined') {
        page = 1
      }
      this.isLoading =  true
      axios.get('/jobs?page=' + page).then(response => {
          this.jobs = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 200);
        })
    },
    getFilteredJobs(){
      axios.get('/allJobs').then(response => {
          this.allJobs = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      })
    },
    getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
  },
    computed: {
    matchingJobs: function(){
      var self = this
      if(this.category && this.location && this.search) {
          return this.allJobs.filter(function(jobFilter){
            return (jobFilter.category_id == self.category) && ( jobFilter.country_id == self.location) && (jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1)
        })
      }if(this.category && this.location) {
          return this.allJobs.filter(function(jobFilter){
            return (jobFilter.category_id == self.category) && ( jobFilter.country_id == self.location)
        })
      }if (this.search) {
          return this.allJobs.filter(function(jobFilter){
          return jobFilter.name.toLowerCase().indexOf(self.search.toLowerCase()) !== -1
         })
      }if(this.category) {
          return this.allJobs.filter(function(jobFilter){
            return jobFilter.category_id == self.category
        })
      }if(this.location) {
          return this.allJobs.filter(function(jobFilter){
            return jobFilter.country_id == self.location
        })
      }else{

      }
        return this.jobs.data
    }
  },

  mounted () {
  document.title = `Job - Vipawaworks`
  this.getJobs()
  this.getFilteredJobs()
  this.getCategories()
  this.getCountries()
}
}
</script>

<style scoped>

</style>

