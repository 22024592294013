<template>
<div class="row">
        <div class="col-md-6" v-for="member in user_info.memberships" :key="member.id">
            <div class="d-flex flex-row-reverse" v-if="editable == 1">
                <a @click="deleteMembership(member.id)" class="btn btn-pills text-primary">
                    <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                </a>
                <router-link :to="{ name: 'EditMembership', params:{ id:member.id }}" class="btn btn-pills text-primary">
                    <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                </router-link>
            </div>
        <h5>
                <strong>{{ member.name }}</strong>
            </h5>
            <h6>
            <span class="text-muted">{{ member.role }}</span> 
            </h6>
            <h6>
            <span class="text-muted">{{  moment(new Date(member.start_day)).format('DD/MM/YYYY') }}</span> 
            </h6>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Swal from 'sweetalert2'
export default {
    props: ['user_info','editable'],
     methods: {
        deleteMembership(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/membership/destroy/'+ id)
                .then(response => {
                    this.user_info.memberships = this.user_info.memberships.filter(member => {
                      return member.id != id
                      })
                }).catch(() => {
                    this.$router.push({ name: 'Specialists'})
                })
                Swal.fire(
                'Deleted!',
                'Membership has been Deleted.',
                'success'
                )
            }
            })
        }
    },
    created(){
    this.moment = moment
}
}
</script>

<style>

</style>