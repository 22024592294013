import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/materialdesignicons.min.css'
import './assets/css/tiny-slider.css'
import './assets/css/style.css'
import './assets/css/colors/default.css'
// import CKEditor from '@ckeditor/ckeditor5-vue';
import Swal from 'sweetalert2'
import "vue-material-design-icons/styles.css"
import MenuIcon from "vue-material-design-icons/Menu.vue"
import Pagination from 'v-pagination-3';
import VueHtml2Canvas from 'vue-html2canvas';
import htmlToPdf from "@/composable/convertPDF"
import GSignInButton from 'vue-google-signin-button'
import { createHead } from '@vueuse/head'
import VueSocialSharing from 'vue-social-sharing'
import Tawk from 'vue-tawk';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
import '@babel/polyfill';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



import VueCarousel from 'vue-carousel';

import axiosApi from 'axios';

const axios = axiosApi.create({
    baseURL:`https://api.vipawaworks.com/api`,
    // baseURL:`http://127.0.0.1:8000/api`,
});
const head = createHead()

//Use the window object to make it available globally.
window.axios = axios;

// Vue.use(CKEditor, {
//     editor: ClassicEditor,
// });

createApp(App).use(router).use(head).use(CKEditor, {
    editor: ClassicEditor,
}).use(VueCarousel).use(VueSocialSharing).use(VueHtml2Canvas).use(GSignInButton).component("menu-icon", MenuIcon).component('pagination', Pagination).mount('#app')
