<template>

    <section class="bg-home d-flex align-items-center">
        <div class="container">
            <br><br><br>
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12 text-center">
                    <img src="@/assets/img/svg/404.svg" class="img-fluid" alt="" style="height: 240px">
                    <div class="text-uppercase mt-4 display-3">Page Not Found</div>
                    <div class="text-capitalize text-dark mb-4 error-page">You typed it Wrong</div>
                    <p class="text-muted para-desc mx-auto">The page you are looking for was not found on this server please check your url and try again</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 text-center">  
                    <button @click="$router.go(-1)" class="btn btn-outline-primary mt-4">Go Back</button>
                    <router-link class="btn btn-primary mt-4 ms-2" :to="{ name: 'Home'}">Go To Home</router-link>
                </div>
            </div>
            <br>
        </div>
    </section>
</template>

<script>
export default {
created(){
      document.title = `Not Found - Vipawaworks`
  },
}
</script>

<style>

</style>