<template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="rounded shadow p-4">
                            <div class="row projects-wrapper">
                                <ApplicationsInfo :jobs="jobs" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import ApplicationsInfo from '@/components/user/ApplicationsInfo.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
  components: { Footer,NavSeeker,ApplicationsInfo,Loading, },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {roles: [0]},
          jobs: [],
          token: localStorage.getItem('user_data'),
          isLoading: true,
          errors: {},
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/candidate/application').then(response => {
                this.jobs = response.data
                }).catch(errors => {
                    this.errors = 'Something went wrong'
            })
            }).catch(errors => {
                 if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
      },
  },
  created(){
      document.title = `All Applications - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>

</style>