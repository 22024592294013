<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <router-link :to="{ name : 'Home' }" class="logo-footer">
                        <img src="@/assets/img/logon.png" alt="" style="height: 60px;">
                    </router-link>
                    <p class="text-white"  align="justify"><strong>Vipawaworks</strong> is the number one job site in Tanzania, offering a complete range of end-to-end employment solutions and career planning tools. Vipawaworks offers the fastest, easiest, most effective and cost-efficient methods for employers to find quality candidates and for job seekers to find top jobs in East Africa.
                                Vipawaworks is firmly committed to the region and has an excellent and thorough understanding of the East Africa recruitment market and business landscape.</p>
                    <ul class="list-unstyled social-icon foot-social-icon mb-0">
                        <li class="list-inline-item">
                            <a target="_blank" href="https://www.facebook.com/vipawaworkstz?mibextid=LQQJ4d" class="rounded text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social">
                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a target="_blank" href="https://www.instagram.com/vipawaworks/" class="rounded text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram fea icon-sm fea-social">
                                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a target="_blank" href="https://twitter.com/vipawaworks" class="rounded text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social">
                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a target="_blank" href="https://www.linkedin.com/company/vipaji-jobs/" class="rounded text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social">
                                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mt-sm-0 pt-sm-0">
                    <h5 class="text-white footer-head">Contacts</h5>
                    <ul class="list-unstyled footer-list">
                        <li class="text-white">Chabruma Street, Ali Hassan Mwinyi Road, ALFA Plaza, 3rd Floor, Room no. 303, </li>
                        <li class="text-white">P.O. Box 32080 Dar es Salaam, Tanzania </li>
                        <li class="text-white">+255 (757) 888110</li>
                        <li > <a href="mailto:info@vipawaworks.com" class="text-success">info@vipawaworks.com</a></li>
                        <li><router-link :to="{ name : 'Home' }" class="text-success">Vipawaworks</router-link></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-4 col-12 mt-sm-0 pt-2 pt-sm-0">
                    <h5 class="text-white footer-head">Quick Links</h5>
                    <ul class="list-unstyled footer-list">
                        <li><router-link :to="{ name : 'About' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> About us</router-link></li>
                        <li><router-link :to="{ name : 'Contacts' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> Contact Us</router-link></li>
                        <li><router-link :to="{ name : 'Help' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>Help & User Guide</router-link></li>
                        <li><router-link :to="{ name : 'Feedback' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>Feedback</router-link></li>
                        <li><router-link :to="{ name : 'Terms' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> Terms of Services</router-link></li>
                        <li><router-link :to="{ name : 'Privacy' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> Privacy Policy</router-link></li>
                        <li v-if="!token"><router-link :to="{ name : 'Login' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> Login</router-link></li>
                        <li v-else><router-link :to="{ name : 'Dashboard' }" class="text-white"><svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> Dashboard</router-link></li>
                        <li><a class="text-white" href="https://vmg.co.tz/" target="_blank"> <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg> HR Consultation</a></li>
                    </ul>
                </div>
            </div>

        </div>
    </footer>
    <div class="row justify-content-center hidden-mobile">
        <div class="col-sm-12">
            <div class="text-center">
                <p class="mb-0 text-primary"> © {{new Date().getFullYear()}}. All Rights Reserved
                    <span>Developed by <a class="text-success" href="https://ikabit.co.tz/" target="_blank">Ikabit</a></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
            token:false,
        }
    },
  methods: {
    scrollToTop() {
        window.scrollTo(0,0);
    },
    getToken(){
        return this.token = localStorage.getItem('user_data')
    }
    },
    created(){
      this.getToken()
  }
}
</script>

<style scoped>
li:not(:last-child) {
    margin-right: 10px;
}
.hidden-mobile {
  /* background-image: url(../assets/img/nav.jpg); */
  background-color: #e8effd;
}
</style>
