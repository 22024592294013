<template>
    <Navbar />
	<section class="section mt-100">
        <div class="container" v-if="!isLoading">
            <div class="row" v-if="blogs.length">
                <div class="col-lg-8 col-md-6">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-10 mt-4 pt-2" v-for="blog in blogs" :key="blog.id">
                            <div class="card blog rounded border-0 shadow overflow-hidden">
                                <div class="position-relative">
                                    <img :src="'https://adminora.vipawaworks.com/images/blogs/' + blog.image" class="card-img-top">
                                    <div class="overlay rounded-top bg-dark"></div>
                                </div>
                                <div class="card-body content">
                                    <h5><router-link :to="{ name: 'BlogDetails', params: {id: blog.id}}" class="card-title title text-dark">{{  blog.name && blog.name.length < 40 ? blog.name : blog.name.substring(0,37)+".."  }}</router-link></h5>
                                    <div class="post-meta d-flex justify-content-between mt-3">
                                    
                                        <router-link :to="{ name: 'BlogDetails', params: {id: blog.id}}" class="text-muted readmore">Read More 
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="card border-0 sidebar sticky-bar rounded shadow">
                        <div class="card-body">

                            <div class="widget mb-4 pb-2">
                                <h5 class="widget-title">Recent Post</h5>
                                <div class="mt-4">
                                    <SideBlogs />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="justify-content-center" v-else>
                <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                <h3 class="text-center">No Blogs Post</h3>
            </div>
        </div>
        <div v-else>
            <Loading />
        </div>
    </section>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
import getBlogs from '@/composable/getBlogs'
import SideBlogs from '@/components/SideBlogs.vue'
export default {
	components: { Navbar,Footer,SideBlogs,Loading },
	data(){
      return {
          currentPage: 'Blog',
          previousPage: '',
		  isLoading: true,
      }
  },
      setup(){
      const { blogs, error, loadBlogs } = getBlogs()
      loadBlogs()

      return { blogs, error, loadBlogs }
  },
  methods:{
    isLoad() {
      setTimeout(function () {
        this.isLoading =  false
    }.bind(this), 1500);
   }
  },
  created () {
      document.title = `Blogs - Vipawaworks`
  this.isLoad()
} 

}
</script>

<style>
.card-img-top{
    height: 280px;
}
</style>