<template>
    <div class="row justify-content-center">
        <div class="col-md-4 mt-4">
            <div class="mt-4">
               <Sidebar :user="user_info" :user_info="user_info" :picture="0"/>
            </div>
        </div>
        <div class="col-md-4"  v-if="subscriber == 0">
            <div class="mt-4">
                <h5 class="text-center text-primary">Recently Applications:</h5>
                <div class="col mt-4 pt-2" id="tables">
                    <div class="component-wrapper rounded shadow">
                        <div class="p-4">
                            <div class="table-responsive bg-white" v-if="jobs">
                                <div v-if="jobs.length">
                                    <table class="table mb-0 table-center">
                                        <thead>
                                            <tr>
                                            <th scope="col" class="border-bottom">Job Name</th>
                                            <th scope="col" class="border-bottom">View Progress</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="job in limitedJob" :key="job.id">
                                                <td>
                                                    <router-link :to="{ name: 'JobDetails', params: {id: job.id}}" class="text-dark">
                                                    {{ job.name }}
                                                    </router-link>
                                                </td>
                                                <td>
                                                <router-link :to="{ name: 'CandidateProgress', params: {id: job.id}}" class="text-primary">
                                                    View Application Progress
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                                <div v-else >
                                    <div>
                                        <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                        <h6 class="text-center">You Have not Applied For Any Job Yet</h6>
                                        <div class="text-center">
                                            <router-link :to="{ name : 'Jobs' }"  type="button" class="btn btn-primary" >Hunt for Jobs</router-link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4 pt-2 pt-sm-0" v-if="subscriber == 0">
            <h5 class="text-center text-primary">Set Job Alert:</h5>
            <div class="col mt-4 pt-2" id="tables">
                <div class="component-wrapper rounded shadow">
                    <div class="p-4">
                        <div class="table-responsive bg-white" >
                            <img src="@/assets/img/png/alert.png"  height="200" alt="">
                            <h6 class="text-center">Set Job Alert To be Notified As Soon as Job matches Your Prefernce Is Posted</h6>
                                <div class="text-center">
                                    <router-link :to="{ name : 'SeekerJobAlert' }"  type="button" class="btn btn-primary" >Set Job Alert</router-link>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 mt-4" v-else>
            <div class="mt-4">
                <h5 class="text-center text-primary">Recently Applications:</h5>
                <div class="col mt-4 pt-2" id="tables">
                    <div class="component-wrapper rounded shadow">
                        <div class="p-4">
                            <div class="table-responsive bg-white" v-if="jobs">
                                <div v-if="jobs.length">
                                    <table class="table mb-0 table-center">
                                        <thead>
                                            <tr>
                                            <th scope="col" class="border-bottom">Job Name</th>
                                            <th scope="col" class="border-bottom">View Progress</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="job in limitedJob" :key="job.id">
                                                <td>
                                                    <router-link :to="{ name: 'JobDetails', params: {id: job.id}}" class="text-dark">
                                                    {{ job.name }}
                                                    </router-link>
                                                </td>
                                                <td>
                                                <router-link :to="{ name: 'CandidateProgress', params: {id: job.id}}" class="text-primary">
                                                    View Application Progress
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                                <div v-else >
                                    <div>
                                        <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                        <h6 class="text-center">You Have not Applied For Any Job Yet</h6>
                                        <div class="text-center">
                                            <router-link :to="{ name : 'Jobs' }"  type="button" class="btn btn-primary" >Hunt for Jobs</router-link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/user/Sidebar.vue'
export default {
    props: ['user_info','jobs',],
    components: { 
      Sidebar,
    },
    data(){
        return {
            limit: 3,
            subscriber: {}
        }
    },
    computed:{
  limitedJob(){
    return this.limit ? this.jobs.slice(0,this.limit) : this.jobs
  }
},
methods:{
    checkSubscription(){
        axios.get('/jobAlert/checkJobSubscriber').then(response => {
            this.subscriber = response.data.subscriber
            }).catch(errors => {
                this.errors = 'Something went wrong'
        })
    }
},
created(){
    this.checkSubscription()
}
}
</script>

<style>

</style>