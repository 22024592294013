<template>
    <form ref="clearForm" @submit.prevent="editPersonal">
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">First Name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <input type="text" class="form-control ps-5" v-model="form.first_name"  >
                        <div v-if="errors.first_name" class="text-small text-danger">{{ errors.first_name[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Last Name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        <input type="text" class="form-control ps-5" v-model="form.last_name" >
                        <div v-if="errors.last_name" class="text-small text-danger">{{ errors.last_name[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <input type="email" class="form-control ps-5" name="email" v-model="form.email">
                        <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Choose role <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <select v-model="form.role" class="form-control ps-5">
                            <option value="">Choose Role</option>
                            <option :value="role.id" v-for="role in roles" :key="role.id">
                                <span v-if="role.id == 1">Administrator</span> <span v-else>HRO</span>
                            </option>
                        </select>
                        <div v-if="errors.role" class="text-small text-danger">{{ errors.role[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                        <input type="password" class="form-control ps-5" v-model="form.password" >
                        <div v-if="errors.password" class="text-small text-danger">{{ errors.password[0] }}</div>
                    </div>
                </div> 
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                        <input type="password" class="form-control ps-5" v-model="form.password_confirmation" >
                        <div v-if="errors.password_confirmation" class="text-small text-danger">{{ errors.password_confirmation[0] }}</div>
                    </div>
                </div> 
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12">
                <input type="submit" id="submit" class="btn btn-primary" value="Add User">
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props: ['roles','profile'],
    data(){
        return {
            form: {
            first_name: '', last_name: '', email: '', password: '', role: '', password_confirmation : '',
            },
            token: localStorage.getItem('user_data'),
            errors: false,
        }
    },
    methods: {
        editPersonal(){
            axios.post('/company/user/add/' + this.profile.id, this.form).then(response => {
                this.success = response.data.success
                this.$router.push({ name: 'CompanyUsers'})
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
    }
}
</script>

<style>

</style>