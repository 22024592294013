<template>
  <Navbar />
  <div class="home" v-if="!isLoading">
    <section class="bg-auth-home d-table w-100">
          <div class="container">
            <h2 class="card-title text-center">Great. One last step, {{ user.fname }}</h2> 
              <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6">
                      <div class="card shadow rounded border-0">
                          <div class="card-body">
                            <p class="text-md-start text-center">Step 2 of 2</p>
                              <h5 class="text-md-start text-center">Make it easy for employers to contact you.Tell them a bit about yourself.</h5>
                                <InfoForm :user="user.id"  />
                          </div>
                      </div>
                  </div> 
                  <div class="col-lg-7 col-md-6">
                      <div class="me-lg-5">   
                          <img :src="require('@/assets/img/svg/profile.svg')" class="img-fluid d-block mx-auto" alt="">
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
  <div class="home" v-else>
		<Loading />
	</div>
    	<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
import InfoForm from '@/components/freelancer/steps/InfoForm.vue'
export default {
    components: {
   Navbar, Footer,Loading,InfoForm
  },
  data(){
      return {
        form: {
               sex:'',birth_date: '',nationality: '', city: '', visa: 'No Visa', martial_status: '',mobile_phone:'',
            },
        categories:{},
        countries:{},
        industries:{},
        user: {roles: [0]},
        token: localStorage.getItem('user_data'),
        errors: [],
        isLoading: true,
      }
  },
   methods:{
     getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 ) {
                this.$router.push({ name: 'Dashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
      checkFiled(){
          axios.get('/profile/checkFiled').then(response => {
              this.form = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
        editPersonal(){
            axios.post('/profile/stepProfile', this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'FreelancerDashboard'})
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getIndustries(){
        axios.get('/industries').then(response => {
            this.industries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      switchFresher(){
             axios.post('/role/changeToFresher').then(response => {
              this.success = response.data.success
              this.$router.push({ name: 'StepTarget'})
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
     },
  },
  created(){
      document.title = `Add Profile - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getUser()  
      this.getCountries()
      this.getIndustries()
      this.getCategories()
      this.checkFiled()
    }

}
</script>

<style>

</style>