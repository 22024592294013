<template>
<Navbar />
    <section class="section">
        <br>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h2 class="text-center text-primary">How To Get Started as Employer</h2>
                    <div class="card shadow border-0 rounded">
                        <div class="card-body ">
                            <h5 class="card-title text-primary">Introduction</h5>
                            <p class="text-muted">
                                When you just access <router-link :to="{ name:'Home'}" class="text-primary">Vipawaworks</router-link>,  the home page looks as in picture below. You have two options one on top of website far to the right, clik on get started.

                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/employer1.png" class="rounded work-image" height="300" alt="">
                            </div>
                                <br>
                            <h5 class="card-title text-primary">Get Started Page</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Get Started</strong> button on top right conner. You will be naviagated to <router-link :to="{ name:'GetStarted'}" class="text-primary">Get Started</router-link>, 
                                then you can choose employer option out of all other. Note each has different fate in this website.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/employer2.png" class="rounded work-image" height="400" alt="">
                            </div>
            
                            <h5 class="card-title text-primary">Employer Register Page</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Get Started</strong> in get Started page or Employer Register in Home page. You will be naviagated to <router-link :to="{ name:'EmployerRegister'}" class="text-primary">Employer Register</router-link> page,
                                Here you fill All the Infomation about you (company owner/administator). We encourage you to use company email not personal. Since we will not verify company registed by personal emails.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/employer3.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                               Infomation should be filled as the shown in above picture. All fields in this page are mandatory, if any field is not filled/checked, we will reject it instantly as shown in picture below. You can also hide/unhide password so that to be sure. Remember to keep them safe
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/employer4.png" class="rounded work-image" height="400" alt="">
                            </div>
                                <br>
                            
                            <h5 class="card-title text-primary">Company Register Page</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Registed</strong> personal details for company owner/administator. You will be naviagated to <router-link :to="{ name:'RegisterCompany'}" class="text-primary">Company Register</router-link> page,
                                Here you fill All the Infomation about your Company. We insist that all infomation must be true and valid.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/employer5.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                               Not all feild are mandatory only few are enough to get started, although they are important, We will reject if mandatory fields are not filled.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/getstarted/employer6.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <br>
                            <h5 class="card-title text-primary">Verify Email Page</h5>
                            <p class="text-muted">
                                You are likely to be brought to verify email Page. The Steps are well undestandable. but you donot have to worry we have another <router-link to="" class="text-primary">Guide</router-link> covers that.
                            </p>
                            <br>
                            <h5 class="card-title text-primary">Dashboard Page</h5>
                            <p class="text-muted">
                                Right after successfuly Registered the company and complete all steps. you will be redirected to <router-link :to="{ name:'CompanyDashboard'}" class="text-primary">Company Dashboard</router-link> page,
                                And since it is the first time you are likely to find it like in the picture below
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/dashboard.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                                Go to <router-link :to="{ name:'Contacts'}" class="text-primary">Contact Us</router-link>  page and send us an email with <strong> Verify My Company subject</strong>
                                Note that you can edit company details and so on but you cannot post jobs and other activities in the company
                            </p>
                        </div>
                    </div>
                </div>
            </div>			

        </div>
    </section>
<Footer />
</template>

<script>
import Navbar from '@/components/help/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { Navbar,Footer },

}
</script>

<style>

</style>