<template>
<Navbar />
    <section class="section">
        <br>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-11">
                    <h2 class="text-center text-primary">How To Post, Edit and Delete Jobs</h2>
                    <div class="card shadow border-0 rounded">
                        <div class="card-body ">
                            <h5 class="card-title text-primary">Introduction</h5>
                            <p class="text-muted">
                                It is easy to post jobs through Vipawaworks, you just need to follow these steps.
                                When you just access <router-link :to="{ name:'CompanyDashboard'}" class="text-primary">Company Dashboard</router-link>, after Login/ Register and get verified by us. On your navigation on top there is Company Jobs link as shown in picture below, click on that it will send you to company jobs

                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/dashboard2.png" class="rounded work-image" height="300" alt="">
                            </div>
                                <br>
                            <h5 class="card-title text-primary">Company Jobs Page</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Company Jobs</strong> link on top. You will be naviagated to <router-link :to="{ name:'CompanyJobs'}" class="text-primary">Company Jobs Page</router-link>, 
                                Once you arrive in this page you are likely to see <strong>No Job Found</strong>, click on button at right side <strong>Post a New Job</strong>
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/jobs1.png" class="rounded work-image" height="400" alt="">
                            </div>
            
                            <h5 class="card-title text-primary">In Add Job Page</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Add Job</strong> in get Started page or Employer Register in Home page. You will be naviagated to <strong>Add Job</strong> page,
                                Here you fill All the Infomation about the job, such as job name/title, Job Category(in what catgory jobs lies), job role(summary on nature of work he will be doing).
                                Job Responsiblity what he/she is supposed to do (will be doing), here you can use bold, italic links and lists. but <strong>DO NOT</strong> include files they will not work.
                                Company Industry means nature of company is going to work for there is alot of select options. you also choose type of employement if its temporary, contract or employee.
                                 Choose employment status whether full time or part time. Choose number of Vaccanties means how many people are needed in the position. choose country and city for the job. 
                            </p>
                            <p class="text-muted">
                               
                                In <strong>Requirement and Qualification</strong>, choose range between minimum education and maximum education, it is not limited by default. write in years(number) experience in both maximum and minimum.
                                 write 0 for unlimited we will understand that. For gender balance you can choose mostly prefarable gender, Both (equal preferene) is choosen by default. 
                                 Also skills and Qualifications are more skills that a person should have to just out of education and experience, you can write paragraph bolded text, italic links and  lists but again <strong>DO NOT</strong> include files they will not work.
                                 Other qualifications is optional, you can write other qualification which are not proffessional
                            </p>
                            <p class="text-muted">
                                In <strong>Application Instructions</strong>, Here Application method there 3 option, Apply online, candidate will be able to apply direct in Vipawaworks website. Apply via email, applicant should be able to redirected to email option and he/she will send require docs, it will appear aplication email input just write email there.
                                It is recommended to write in application instruction on what she/he should write Application Email. Another option apply via link, after choosing this option another input will appear and you provide the link for application such as company website.
                                then you can choose target whether its Both Freshers and Job Seekers, or just Freshers or just Job Seeker. Although both will be able to apply for all target. then deadline the job should have deadline, this is the time job will disapper from website so that people will not be able to apply to the dealine job.
                                You can save as Draft or you can direct publish ifn you are ready to receive applicants. You can put as featured they will be displayed in Home page Not feature will be found in all jobs and in respective category and City.
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/addJob1.png" class="rounded work-image" height="1200" alt="">
                            </div>
                            <p class="text-muted">
                               Infomation should be filled as the shown in above picture. All fields in this page are mandatory except for those which are writen as optional, if any field is not filled/checked, we will reject it instantly as shown in picture below. You can also hide/unhide password so that to be sure. Remember to keep them safe
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/addJob2.png" class="rounded work-image" height="400" alt="">
                            </div>
                                <br>
                            
                            <h5 class="card-title text-primary">Back to Company Jobs</h5>
                            <p class="text-muted">
                                Right after clicked <strong>Saved Changes</strong>. You will be navigated to <router-link :to="{ name:'CompanyJobs'}" class="text-primary">Company Jobs</router-link> page,
                                Here you find your Posted Jobs, 
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/jobs2.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <h5 class="card-title text-primary">Filter Company Jobs</h5>
                            <p class="text-muted">
                                In <router-link :to="{ name:'CompanyJobs'}" class="text-primary">Company Jobs</router-link> page, at left side there is
                                 <span class="btn btn-primary">
                                     <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                 </span>
                                Here you find your Posted Jobs, click that it will appear filter inputs. You can filter as much as you want
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/jobs3.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <h5 class="card-title text-primary">Edit Job</h5>
                            <p class="text-muted">
                                In <router-link :to="{ name:'CompanyJobs'}" class="text-primary">Company Jobs</router-link> page, in jobs at right side there is 
                                <span class="text-warning">
                                     <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                                 </span>
                                Click on that and you will be brought to edit page. Edit your Job. This is useful especially when you want to increase/decrease the deadline
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/jobs4.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <h5 class="card-title text-primary">Edit Job Page</h5>
                            <p class="text-muted">
                                In edit job page there is no big difference with add page except most infomation will filled already. You can save changes and you will be returned to company Jobs
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/editJob.png" class="rounded work-image" height="400" alt="">
                            </div>
                             <h5 class="card-title text-primary">Delete Job</h5>
                            <p class="text-muted">
                                In <router-link :to="{ name:'CompanyJobs'}" class="text-primary">Company Jobs</router-link> page, in jobs at right side there is 
                                <span class="text-danger">
                                     <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                 </span>
                                Click on that and you will be asked for confimation to delete
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/jobs5.png" class="rounded work-image" height="400" alt="">
                            </div>
                            <p class="text-muted">
                                A dialog box will popup and ask for confimation. Choose yes Delete it for Delete and Cancel to cancel deletion process
                            </p>
                            <div class="text-center">
                                <img src="@/assets/img/help/company/deleteJob.png" class="rounded work-image" height="400" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>			

        </div>
    </section>
<Footer />
</template>

<script>
import Navbar from '@/components/help/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { Navbar,Footer },

}
</script>

<style>

</style>